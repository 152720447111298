// External
import { ActivatedRouteSnapshot, UrlTree, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

// Internal
import { NavigationService } from '../../navigation/navigation.service';
import { QueryParamsService } from '../core/query.params.service';
import { ValuesService } from '../../values/values.service';

@Injectable({ providedIn: 'root' })

export class ServicesWebmailProtectionGuard {

    constructor(
        private readonly navigationService: NavigationService,
        private readonly queryParamsService: QueryParamsService,
        private readonly valuesService: ValuesService,
        private readonly router: Router,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
        const urlProperties = this.navigationService.processUrl(route, state);
        this.queryParamsService.saveQueryParamsNoPersistance(urlProperties.queryObject);
        this.queryParamsService.getCleanedRouteOfCleanQueryParams(urlProperties)

        const redirected = this._checkRedirectAfterEmailConnection(route, state);
        if (!redirected) {
            return of(true);
        } else {
            return new Observable(subscriber => {
                subscriber.next(redirected);
                subscriber.complete();
            });
        }
    }

     /**
     * Method that verifies if current page has query params and redirect cu referral page
     * @returns {UrlTree | boolean} the new page or true if no referral
     */
    private _checkRedirectAfterEmailConnection(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): UrlTree|boolean {
        const code = this.queryParamsService.get(this.valuesService.queryParams.code);
        const state = this.queryParamsService.get(this.valuesService.queryParams.state);

        if (code && state) {
            const urlProperties = this.navigationService.processUrl(_route, _state);
            //iau url-ul din state
            let referral = '';
            const stateParts: Array<string> = state.split('&');
            for (const index in stateParts) {
                if (stateParts[index].split('=')[0] === this.valuesService.queryParams.referral) {
                    referral = decodeURIComponent(stateParts[index].split('=')[1]);
                    if (referral === urlProperties.entirePath) {
                        //suntem pe acelasi url, nu are sens redirectul
                        return false;
                    } else {
                        //suntem pe alt url, sterg referral-ul din url si las ceilalti param
                        delete stateParts[index];
                        break;
                    }
                }
            }
            if (referral) {
                return this.router.parseUrl(referral);
            }
        }

        return false;
    }
}