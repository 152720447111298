import { BehaviorSubject } from 'rxjs';
import { FilterCategoryEnum, FilterEnum } from '../../../pages/privacy/privacy-footprint-standard/privacy-footprint.component';

export interface IPrivacyFootprintServiceState {
    [key: string]: BehaviorSubject<string>;
}

export interface IMarkToUpdateFootprintServiceState {
    [key: string]: boolean;
}

export enum IPrivacyFootPrintIssuesType {
    BREACH = 'breach',
    EXPOSURE = 'exposure',
}

export enum PrivacyFootprintActionType {
    IN_USE = 'in_use', //inUse
    REMEDIATION = 'remediation', //remediation
    REVIEWED = 'reviewed', //reviewed
    DELETE_DATA = 'delete_data', //delete_data
    KNOW_DATA = 'know_data' //know_data
}

export const PersonalFootprintMaxCategories = 3;

export enum ServiceStatusEnum {
    NEW = 'new',
    NOT_USED = 'notUsed',
    REQUEST_SENT = 'requestSent',
    DELETED = 'deleted',
    MALWARE = 'malware',
    MALWARE_HD = 'malware-hd',
    PHISHING = 'phishing',
    FRAUD = 'fraud',
    UNTRUSTED = 'untrusted',
    SPAM = 'spam',
    PUA = 'pua',
    MINER = 'miner',
    HOMOGRAPH = 'homograph',
    CC = 'c&c'
}

export const HarmfulStatuses = new Set([
    ServiceStatusEnum.MALWARE,
    ServiceStatusEnum.MALWARE_HD,
    ServiceStatusEnum.PHISHING,
    ServiceStatusEnum.FRAUD,
    ServiceStatusEnum.UNTRUSTED,
    ServiceStatusEnum.SPAM,
    ServiceStatusEnum.PUA,
    ServiceStatusEnum.MINER,
    ServiceStatusEnum.HOMOGRAPH,
    ServiceStatusEnum.CC
]);

export interface FootprintServicesCounts {
    request_sent: number;
    delete_data_request_sent: number;
    know_data_request_sent: number;
    deleted_data: number;
    new: number;
}

export interface ServiceFilterConnectInterface {
    query?: string;
    metadata_id?: string;
    discovered_after?: Date| number;
    new?: boolean;
    deleted_data?: boolean;
    request_sent?: boolean;
    delete_data_request_sent?: boolean;
    know_data_request_sent?: boolean;
}

export interface ServiceFilterInterface {
    label?: string;
    count?: number;
    filterType: FilterCategoryEnum;
    action?: () => void,
    backEndField: string;
    inProgress: boolean;
    withError: boolean;
    noResults: boolean;
    emptyTitle?: string;
    emptyDesc?: string;
    foundTitle?: string;
    foundTitleSingle?: string;
}

export type ServiceFilterItemsInterface = {
    [key in FilterEnum]?: ServiceFilterInterface;
}

