// External
import { Injectable } from '@angular/core';
import { Observable, map, catchError, of } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { tv4 } from 'tv4';

// Internal
import { Errors, POST, RequestsService } from '../../global/request-service/requests.service';
import { ValuesService } from '../../../values/values.service';
import { ConfigService } from '../../../config/config.service';
import { CreateGroupResponse, Group, GroupRoles } from '../../../models/subscriptions/Groups.model';
import { UtilsCommonService } from '../../../utils/utils-common.service';
import { CreateGroupParams } from '../../../models/subscriptions/Groups.model';
import { SecurityActivityEventsRequestParams } from '../../process/subscriptions/group-management.service';
import { RequestBody, RequestExtraParams } from '../../../../common/models/Core.model';

export enum RequestStatus {
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED'
}

interface InviteGroupResponse {
    status: RequestStatus;
    memberId?: string;
}

@Injectable({
    providedIn: 'root'
})

export class ConnectGroupMgmtService {

    constructor(
        private readonly cookieService: CookieService,
        private readonly requestsService: RequestsService,
        private readonly valuesService: ValuesService,
        private readonly configService: ConfigService,
        private readonly utilsCommonService: UtilsCommonService
    ) {}

    /**
     * Funciton that calls the Nimbus server with the given method and respectiv payload based on given extra params
     * @private
     * @memberof ConnectGroupMgmtService
     * @param {string} method The method to call the nimbus service with
     * @param {any} extraParams The extra params to add to the payload
     * @returns {Observable<any>}
     */
    private makeRequest(method: string, extraParams?: any): Observable<any> {
        const _json = {
            id: parseInt((Math.random() * 1000).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: method,
            params: {
                connect_source: {
                    device_id: this.valuesService.connectDeviceId,
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    app_id: this.valuesService.appCM
                },
                ...extraParams
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.connectGroupMgmtService,
            _json,
            POST
        ).pipe(
            map(resp => {
                if (resp?.result) {
                    return resp.result;
                }
                throw resp;
            }),
            catchError(error => {
                throw error;
            })
        );
    }

    /**
     * Makes the batch request and returns the response from server
     * @private
     * @memberof ConnectGroupMgmtService
     * @param {array} batchRequestBody The array of objects
     * @returns {Observable} The request response
     */
    private makeBatchRequestWithMembersArray(batchRequestBody: Array<RequestBody>): Observable<any> {
        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.connectGroupMgmtService,
            batchRequestBody,
            POST
        ).pipe(
            map(results => {
                if (!Array.isArray(results)) {
                    throw results;
                }

                const finalResponse = {};
                for (const resp of results) {
                    for (const batch of batchRequestBody) {
                        if (resp.id === batch.id) {
                            finalResponse[batch.params.member_id] = resp?.result?.status === 0 ? RequestStatus.SUCCESS : RequestStatus.FAILED;
                        }
                    }
                }

                return finalResponse;
            }),
            catchError(error => {
                throw error;
            })
        );
    }

    /**
     * Makes the batch request and returns the response from server
     * @private
     * @memberof ConnectGroupMgmtService
     * @param {array} batchRequestBody The array of objects
     * @returns {Observable} The request response
     */
     private makeBatchRequestInviteToGroup(batchRequestBody: Array<RequestBody>): Observable<any> {
        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.connectGroupMgmtService,
            batchRequestBody,
            POST
        ).pipe(
            map(results => {
                if (!Array.isArray(results)) {
                    throw results;
                }

                const finalResponse = {};
                for (const resp of results) {
                    for (const batch of batchRequestBody) {
                        if (resp.id !== batch.id) {
                            continue;
                        }

                        const responseObj: InviteGroupResponse = {
                            status: RequestStatus.FAILED
                        };
                        if (resp?.result?.member_id) {
                            responseObj.status = RequestStatus.SUCCESS;
                            responseObj.memberId = resp.result.member_id;
                        }
                        finalResponse[batch.params.email] = responseObj;
                    }
                }

                return finalResponse;
            }),
            catchError(error => {
                throw error;
            })
        );
    }

    /**
     * Create group
     * @public
     * @memberof ConnectGroupMgmtService
     * @returns {Observable} The request response
     */
    public createGroup(groupParams: CreateGroupParams): Observable<CreateGroupResponse> {
        return this.makeRequest('create_group', groupParams);
    }

   /**
    * List members of a group
    * @param {string} groupId The group id
    * @returns {Observable<any>} The request response
    */
    public listMembers(groupId: string): Observable<any> {
        const params: RequestExtraParams = {
            group_id: groupId
        };
        return this.makeRequest('list_members', params);
    }

    /**
     * Makes the request for the security activity events
     * @param {SecurityActivityEventsRequestParams} requestParams The request params for pagination
     * @returns {Observable<any>} The request response
     */
    public listSecurityActivity(requestParams: SecurityActivityEventsRequestParams): Observable<any> {
        if (this.utilsCommonService.isEmptyObject(requestParams) || !this.utilsCommonService.isObject(requestParams)) {
            console.error(Errors.INVALID_PARAMS, tv4.error);
            return of(Errors.INVALID_PARAMS);
        }

        const json: any = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: 'list_activity',
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                ...requestParams
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.connectGroupMgmtService,
            json,
            'POST'
        ).pipe(
            map(
                resp => {
                    if (Array.isArray(resp?.result)) {
                        for (const event of resp.result) {
                            // ! Temporrairy fix for the event data
                            if (event.event_data) {
                                event.data = { ...event.event_data };
                            }
                        }
                        return resp.result;
                    }
                    throw resp;
                }
            ),
            catchError((err) => {
                throw err;
            })
        );
    }

    /**
     * Invite single group member
     * @public
     * @memberof ConnectGroupMgmtService
     * @returns {Observable} The request response
     */
    public inviteMember(group_id: string, email: string, role: GroupRoles): Observable<any> {
        const params: RequestExtraParams = {
            group_id,
            email,
            role
        };
        return this.makeRequest('invite_member', params);
    }

    /**
     * Invite multiple group member with same role
     * @public
     * @memberof ConnectGroupMgmtService
     * @returns {Observable} The request response
     */
    public inviteMembers(group_id: string, emailsArr: Array<string>, role: GroupRoles, teenagerBirthday?: number): Observable<any> {
        const batch = [];
        let counter = 1;
        for (const email of emailsArr) {
            const json: RequestBody = {
                id: counter,
                jsonrpc: this.valuesService.jsonrpc,
                method: 'invite_member',
                params: {
                    connect_source: {
                        user_token: this.cookieService.get(this.valuesService.cookieToken),
                        device_id: this.valuesService.connectDeviceId,
                        app_id: this.valuesService.connectAppId
                    },
                    group_id,
                    role,
                    email
                }
            };

            if (teenagerBirthday) {
                json.params.metadata = {
                    birthdate: teenagerBirthday
                };
            }

            batch.push(json);
            counter++;
        }
        return this.makeBatchRequestInviteToGroup(batch);
    }

    /**
     * Update group member
     * @public
     * @memberof ConnectGroupMgmtService
     * @param {string} group_id The id of the group where the user is a member of
     * @param {string} member_id The id of the member to be updated
     * @param {GroupRoles} role The new role of the member
     * @returns {Observable} The request response
     */
    public updateMember(group_id: string, member_id: string, role: GroupRoles): Observable<any> {
        const params: RequestExtraParams = {
            group_id,
            member_id,
            role
        };
        return this.makeRequest('update_member', params);
    }

    /**
     * Remove multiple members from a group
     * @public
     * @memberof ConnectGroupMgmtService
     * @param {string} group_id
     * @param {Array<string>} membersIds
     * @returns {Observable} The request response
     */
    public removeMembers(group_id: string, membersIds: Array<string>): Observable<any> {
        const batch = [];
        let counter = 1;
        for (const memberId of membersIds) {
            const json: RequestBody = {
                id: counter,
                jsonrpc: this.valuesService.jsonrpc,
                method: 'remove_member',
                params: {
                    connect_source: {
                        user_token: this.cookieService.get(this.valuesService.cookieToken),
                        device_id: this.valuesService.connectDeviceId,
                        app_id: this.valuesService.connectAppId
                    },
                    group_id,
                    member_id: memberId
                }
            };

            batch.push(json);
            counter++;
        }
        return this.makeBatchRequestWithMembersArray(batch);
    }

    /**
     * Updates group
     * @public
     * @memberof ConnectGroupMgmtService
     * @returns {Observable} The request response
     */
    public updateGroup(group_id: string, group_label?: string): Observable<any> {
        const params: RequestExtraParams = {
            group_id,
            group_label
        };
        return this.makeRequest('update_group', params);
    }

    /**
     * Accepts or decline group invite
     * @public
     * @memberof ConnectGroupMgmtService
     * @param {string} groupId The group id that sent the invite
     * @returns {Observable} The request response
     */
    public acknowledgeInvite(groupId: string): Observable<Group> {
        const params: RequestExtraParams = {
            group_id: groupId,
            accepted: true
        };
        return this.makeRequest('acknowledge_invite', params);
    }

}