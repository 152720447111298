// External
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

// Internal
import { ValuesService } from '../../../values/values.service';
import { ProductsForGroup } from '../../../values/business.values.service';
import { ProductsConfigService } from '../../../config/products.config.service';
import { AppConfigurationStatus } from '../../../models/Services.model';
import { Profile, ProfilesService } from '../../process/profiles/profiles.service';
import { AppsConfigService } from '../../../config/apps.config.service';
import { GroupManagementService } from '../../process/subscriptions/group-management.service';
import { FamilyMembersColors, GroupMember, GroupDevices, GroupRoles } from '../../../models/subscriptions/Groups.model';
import { DevicesService } from '../../process/devices/devices.service';
import {
    FamilyMemberActionsInterface,
    FamilyMember,
    FamilyProductInterface,
    FamilyValuesService,
    FamilyMemberInterface,
    FamilyEventsPlaceholder,
    FamilyEvents
} from '../../../values/family.values.service';

type AllFamilyMembers = (GroupMember|FamilyMember) & Profile;

export interface FamilyMemberProcessed {
    member: FamilyMember;
    color: FamilyMembersColors;
};

interface FamilyMembersProcessed {
    [key: string]: FamilyMemberProcessed;
};

type FamilyEventPlaceholders = {
    [key in FamilyEventsPlaceholder]?: string
};

export interface FamilyEventInterface {
    text: string,
    placeholders: FamilyEventPlaceholders,
    timestamp: number
};

type FamilyEventsComputationFunctions = {
    [key in FamilyEventsPlaceholder]?: Function
};

@Injectable({
    providedIn: 'root'
})

export class IFamilyService {

    private familyMembers: FamilyMember[] = [];
    private familyMembersObject = {} as FamilyMembersProcessed;
    private groupDevices: GroupDevices = {};

    private readonly profileTypeToGroupRoleMapping: {[key: string]: GroupRoles} = {
        [this.valuesService.profileTypes.CHILD]: GroupRoles.CHILD,
        [this.valuesService.profileTypes.TEENAGER]: GroupRoles.TEENAGER
    };

    private eventsInterface: FamilyEventInterface[] = [];
    private readonly familyEventsForDeviceProtection = new Set([
        FamilyEvents.GROUP_MEMBER_DEVICE_UNPROTECTED,
        FamilyEvents.GROUP_MEMBER_DEVICE_PROTECTED,
        FamilyEvents.GROUP_MEMBER_PROTECTED
    ]);

    private readonly computationsFunctions: FamilyEventsComputationFunctions = {
        [FamilyEventsPlaceholder.NO_INVITES]: (event) => {
            return event?.data?.members?.length ?? 0;
        },
        [FamilyEventsPlaceholder.MEMEBR_NAME]: (event) => {
            const member = this.groupManagementService.getMemberById(event?.data?.member_id);
            const memberName =  event?.data?.member_name ?? member?.name;
            return memberName ?? this.translateService.instant('family.security.activity.list.text.generic.placeholder.group_event_member_joined');
        },
        [FamilyEventsPlaceholder.PRODUCT_NAME_PASSWORD_MANAGER]: (_event) => {
            return this.productsConfigService.getProductName(this.valuesService.productNamePasswordManager);
        },
        [FamilyEventsPlaceholder.MEMBER_ROLE]: (event) => {
            const label = this.familyValuesService.familyMembersLabels[event?.data?.member_role];
            return label ? this.translateService.instant(label) : event?.data?.member_role;
        }
    };

    constructor(
        private readonly groupManagementService: GroupManagementService,
        private readonly productsConfigService: ProductsConfigService,
        private readonly familyValuesService: FamilyValuesService,
        private readonly appsConfigService: AppsConfigService,
        private readonly profilesService: ProfilesService,
        private readonly devicesService: DevicesService,
        private readonly profileService: ProfilesService,
        private readonly valuesService: ValuesService,
        private readonly translateService: TranslateService
    ) {}

    /**
     * Computes the family members interface
     * @public
     * @memberof IFamilyService
     */
    public computeFamilyMembersInterface(): void {
        const sortedGroupMembers = this.sortFamilyMembersByName(this.groupManagementService.getGroupMembers());
        const sortedProfiles = this.sortFamilyMembersByName(this.profilesService.getAllProfiles(), true);
        const allFamilyMembers: AllFamilyMembers[] = sortedGroupMembers.concat(sortedProfiles);
        const auxInterface = [];
        const auxFamilyMembersObject = {};
        let colorIndex = 0;

        for (const member of allFamilyMembers) {
            const wrapperId = this.computeWrapperIdForFamilyMember(member);
            const familyMember: FamilyMember = {
                created: member.created,
                context_id: member.context_id,
                email: member?.email,
                wrapper_id: wrapperId,
                member_id: member?.member_id,
                invite_accepted: member?.invite_accepted,
                is_owner: member?.is_owner,
                name: member?.name ?? member?.first_name,
                member_label: member?.member_label,
                role: this.computeFamilyMemberRole(member),
                profile_id: member?.profile_id,
                profileHash: member?.profile_hash
            };
            const hideMember = this.computeHideMember(familyMember);
            familyMember.hideMember = hideMember;

            if (!hideMember) {
                auxFamilyMembersObject[wrapperId] = {
                    member: familyMember,
                    color: this.valuesService.membersColorsArray[colorIndex % this.valuesService.membersColorsArray.length]
                };
                colorIndex++;
            }
            auxInterface.push(familyMember);
        }
        this.familyMembers = auxInterface;
        this.familyMembersObject = auxFamilyMembersObject;
    }

    /**
     * Method creates object of devices grouped by wrapper_id. This wrapper_id is either member_id (for admin/group members) or profile_id(for children/teenagers)
     * @public
     * @memberof IFamilyService
     * @returns {void}
     */
    public createGroupDevices(): void {
        const devicesObj: GroupDevices = {};
        const devices = this.devicesService.getDevicesListArray();
        for (const device of devices) {
            const profileId = device.profile_id;
            const childOrTeenagerProfile = this.profileService.getChidlOrTeenagerProfile(profileId);
            const memberProcessed = this.groupManagementService.getGroupMemberByContextId(device.user_id);
            let wrapperId = profileId;
            if (!childOrTeenagerProfile) {
                wrapperId = this.groupManagementService.getGroupMemberByContextId(device.user_id).member?.member_id;
            }

            if (!devicesObj[wrapperId]) {
                devicesObj[wrapperId] = [];
            }

            if (memberProcessed || childOrTeenagerProfile) {
                const member = memberProcessed.member;
                const color = this.familyMembersObject?.[wrapperId]?.color;
                device.owner_name = childOrTeenagerProfile ? childOrTeenagerProfile.first_name : member.name;
                if (wrapperId !== this.computeOwnerWrapperId()) {
                    device.color = color;
                }
            }
            devicesObj[wrapperId].push(device);
        }

        this.groupDevices = devicesObj;
    }

    /**
     * Method creates the family interface for the family dashboard
     */
    public createFamilyInterfaceForFamilyDashboard(): void {
        for (const member of this.familyMembers) {
            member.interface = this.computeMemberInterface(member);
        }
    }

    /**
     * Method returns array of devices for a given wrapper_id
     * @public
     * @memberof IFamilyService
     * @returns {Array}
     */
    public getUserDevices(wrapper_id): Array<any> {
        return this.groupDevices[wrapper_id] ?? [];
    }

    /**
     * Method returns array of devices of current user
     * @public
     * @memberof IFamilyService
     * @returns {Array}
     */
    public getCurrentUserDevices(): Array<any> {
        return this.groupDevices[this.computeOwnerWrapperId()] ?? [];
    }

    /**
     * Method returns array of devices that are not owned by current user
     * @public
     * @memberof IFamilyService
     * @returns {Array}
     */
    public getOthersDevices(): Array<any> {
        let otherDevices = [];
        for (const contextId in this.groupDevices) {
            if (contextId !== this.computeOwnerWrapperId()) {
                otherDevices = otherDevices.concat(this.groupDevices[contextId] ?? []);
            }
        }
        return otherDevices;
    }

    /**
     * Method computes the owner wrapper_id
     * @public
     * @memberof IFamilyService
     * @returns {string} the owner wrapper_id
     */
    public computeOwnerWrapperId() {
        return this.groupManagementService.getGroupMemberByContextId(this.profileService.getCurrentContextId()).member?.member_id;
    }

    /**
     * Returns object containing group members by wrapper_id
     * @public
     * @memberof IFamilyService
     * @returns {GroupModel} the group members by their wrapper_id
     */
    public getGroupMemberById(wrapperId: string): FamilyMemberProcessed {
        return this.familyMembersObject[wrapperId] ?? null;
    }

    /**
     * Returns all current family group members
     * @returns {Array<FamilyMember>} the group members
     */
    public getGroupMembers(): Array<FamilyMember> {
        return this.familyMembers ?? [];
    }

    /**
     * Compute the group status for each member
     * @public
     * @memberof IFamilyService
     */
    public computeGroupStatus(): void {
        for (const member of this.groupManagementService.getGroupMembers()) {
            for (const status of this.groupManagementService.getGroupStatus()) {
                if (status?.context_id !== member?.context_id) {
                    continue;
                }

                member.products = {};
                for (const app of status.reports) {
                    member.products[app.product] = app.status;
                }

                const wrapperId = this.computeWrapperIdForFamilyMember(member);
                if (this.familyMembersObject?.[wrapperId]) {
                    this.familyMembersObject[wrapperId].member.products = member.products;
                }
            }
        }
    }

    /**
     * Compute the family member wrpapper id
     * @param {Profile|GroupMember} item
     * @returns {string} the wrapper id
     */
    private computeWrapperIdForFamilyMember(item: Profile|GroupMember): string {
        return (item as GroupMember).member_id ?? (item as Profile).profile_id;
    }

    /**
     * Gets the family members
     * @public
     * @memberof IFamilyService
     * @returns {FamilyMember[]} The family members array
     */
    public getFamilyMembers(): FamilyMember[] {
        return this.familyMembers;
    }

    /**
     * Computes the custom interface for each given member
     * @private
     * @memberof IFamilyService
     * @param {FamilyMember} member The member for which to compute the interface
     */
    private computeMemberInterface(member: FamilyMember): FamilyMemberInterface {
        return {
            label: this.groupManagementService.getGroupRoleLabel(member.role, member?.member_label),
            color: this.getFamilyMemberColor(member.role),
            actions: this.computeMemberActions(member.role),
            availableProducts: this.computeProductsInterface(member),
            hasNoDevicesProtected: this.checkProtectedDevicesForMember(member)
        };
    }

    /**
     * Compute the role for the given family member
     * @private
     * @memberof IFamilyService
     * @param {AllFamilyMembers} member The member for which to compute the role
     * @returns {GroupRoles} The computed role
     */
    private computeFamilyMemberRole(member: AllFamilyMembers): GroupRoles {
        if (member?.role) {
            return member.role;
        }
        return this.profileTypeToGroupRoleMapping[this.profilesService.computeProfileTypeForFamily(member?.type)];
    }

    /**
     * Checks if the member should be hidden or not
     * @private
     * @memberof IFamilyService
     * @param {FamilyMember} member The member to be checked
     * @returns {boolean} Returns true if the member should be hidden, false otherwise
     */
    private computeHideMember(member: FamilyMember): boolean {
        return (member?.context_id === this.profilesService.getCurrentGroup()?.context_id && !member?.profile_id)
                || (!member?.invite_accepted && !member?.profile_id)
                || member?.is_owner;
    }

    /**
     * Computes the products interface for a member
     * @private
     * @memberof IFamilyService
     * @param {FamilyMember} member The member for which to compute the products interface
     * @returns {FamilyProductInterface[]} The computed products interface
     */
    private computeProductsInterface(member: FamilyMember): FamilyProductInterface[] {
        const [userDevicesNo, hasParentalInstalled] = this.computeSecurityOnDeviceMember(member);

        const availableFamilyProducts: FamilyProductInterface[] = [
            {
                productName: this.productsConfigService.getProductName(this.valuesService.productNameSecurity),
                appId: this.valuesService.appSecurity,
                icon: this.familyValuesService.familyProductsImages[this.valuesService.productNameSecurity],
                devicesNo: userDevicesNo,
                isConfigured: userDevicesNo > 0,
                show: true
            },
            {
                productName: this.productsConfigService.getProductName(this.valuesService.productNameDIP),
                appId: this.valuesService.appDIP,
                icon: this.familyValuesService.familyProductsImages[this.valuesService.productNameDIP],
                isConfigured: member?.products?.[ProductsForGroup.DATA_PRIVACY]?.init_done,
                show: this.appsConfigService.showDeployFlow(this.valuesService.appDIP)
                        && member?.role !== GroupRoles.CHILD
                        && member?.role !== GroupRoles.FAMILY_TEENAGER
            },
            {
                productName: this.productsConfigService.getProductName(this.valuesService.productNamePasswordManager),
                appId: this.valuesService.appPassManager,
                icon: this.familyValuesService.familyProductsImages[this.valuesService.productNamePasswordManager],
                isConfigured: member?.products?.[ProductsForGroup.PASSWORD_MANAGER]?.user_registered,
                show: this.appsConfigService.showDeployFlow(this.valuesService.appPassManager)
                        && member?.role !== GroupRoles.CHILD
                        && member?.role !== GroupRoles.FAMILY_TEENAGER
            },
            {
                productName: this.productsConfigService.getProductName(this.valuesService.productNamePANCC),
                appId: this.valuesService.appPANCC,
                icon: this.familyValuesService.familyProductsImages[this.valuesService.productNamePANCC],
                isConfigured: hasParentalInstalled,
                show: this.appsConfigService.showApp(this.valuesService.appPANCC)
                        && member?.role === GroupRoles.CHILD
            }
        ];

        for (const product of availableFamilyProducts) {
            product.configurationStatus = this.computeConfiguationStatus(product.appId, product.isConfigured);
            product.configurationLabel = this.familyValuesService.configurationLabelBasedOnProductStatus[product.configurationStatus];
        }

        return availableFamilyProducts;
    }

    /**
     * Compute the configuration status for the given product
     * @private
     * @memberof IFamilyService
     * @param {string} appId The app id
     * @param {boolean} isConfigured The configuration status
     * @returns {AppConfigurationStatus} The computed configuration status
     */
    private computeConfiguationStatus(appId: string, isConfigured: boolean): AppConfigurationStatus {
        if (appId === this.valuesService.appSecurity) {
            return isConfigured ? AppConfigurationStatus.INSTALLED : AppConfigurationStatus.NOT_INSTALLED;
        }
        return isConfigured ? AppConfigurationStatus.CONFIGURED : AppConfigurationStatus.NOT_CONFIGURED;
    }

    /**
     * Checks if the member has devices protected
     * @private
     * @memberof IFamilyService
     * @param {FamilyMember} member The member to be checked
     * @returns {boolean} Returns true if the member has no devices protected, false otherwise
     */
    private checkProtectedDevicesForMember(member: FamilyMember): boolean {
        return !this.getUserDevices(member.wrapper_id).length
                && !member.products?.[ProductsForGroup.DATA_PRIVACY]?.init_done
                && !member.products?.[ProductsForGroup.PASSWORD_MANAGER]?.user_registered;
    }

    /**
     * Compute the member buttons actions
     * @private
     * @memberof IFamilyService
     * @param {GroupMember} member The member for which to compute the actions
     * @returns {FamilyMemberActionsInterface[]} The computed member actions
     */
    private computeMemberActions(memberRole: GroupRoles): FamilyMemberActionsInterface[] {
        const buttons: FamilyMemberActionsInterface[] = [];
        // If the current user is the primary admin, no action should be displayed
        // Because he can't be removed, nor set as secondary admin
        if (memberRole === GroupRoles.FAMILY_PRIMARY_ADMIN) {
            return buttons;
        }

        buttons.push(this.familyValuesService.familyMemberActions.removeMember);
        if (!this.groupManagementService.hasActiveSecondaryAdmin() && memberRole === GroupRoles.FAMILY_INDEPENDENT) {
            buttons.unshift(this.familyValuesService.familyMemberActions.setSecondaryAdmin);
        }
        return buttons;
    }

    /**
     * Gets the color for the given family member
     * @private
     * @memberof IFamilyService
     * @param {GroupRoles} memberRole The role of the family member
     * @returns {FamilyMembersColors} The color for the family member
     */
    private getFamilyMemberColor(memberRole: GroupRoles): FamilyMembersColors {
        return this.familyValuesService.familyMembersColorsByRole?.[memberRole] ?? '' as FamilyMembersColors;
    }

    /**
     * Compute the security on the device for the given member
     * @private
     * @memberof IFamilyService
     * @param {FamilyMember} member The member for which to compute the security
     * @returns {[number, boolean]} The number of devices protected and if parental control is installed
     */
    private computeSecurityOnDeviceMember(member: FamilyMember): [number, boolean] {
        let hasParentalInstalled = false;
        let devicesNo = 0;

        if (member?.profile_id) {
            const devices = this.devicesService.getDevicesBasedOnProfileId(member?.profile_id);
            for (const device of devices) {
                // Check if the device has protection installed/set up
                if (device?.processed?.hasProtection) {
                    devicesNo++;
                }
                if (device?.processed?.hasParentalNCC) {
                    hasParentalInstalled = true;
                }
            }
        } else {
            devicesNo = this.getUserDevices(member.wrapper_id).length;
        }

        return [devicesNo, hasParentalInstalled];
    }

    /**
     * Sorts the family members by name
     * @private
     * @memberof IFamilyService
     * @param {AllFamilyMembers[]} members The members to be sorted
     * @param {boolean} isProfile If the members are profiles - optional param
     * @returns {AllFamilyMembers[]} The sorted members array
     */
    private sortFamilyMembersByName(members: AllFamilyMembers[], isProfile = false): AllFamilyMembers[] {
        return members.sort((firstMember, secondMember) => {
            if (isProfile) {
                return (firstMember?.first_name ?? '').localeCompare(secondMember?.first_name ?? '');
            }

            return (firstMember?.name ?? '').localeCompare(secondMember?.name ?? '');
        });
    }

    /**
     * Generates texts for the security activity list
     * @public
     * @memberof IFamilyService
     * @returns {void}
     */
    public computeSecurityActivityList(): void {
        const securityActivityList = this.groupManagementService.getSecurityActivity();

        const auxEventsInterface: FamilyEventInterface[] = [];
        for (const event of securityActivityList) {
            const eventInterface: FamilyEventInterface = {
                text: '',
                placeholders: {},
                timestamp: event.timestamp
            };
            let placeholders = [];

            if (this.familyEventsForDeviceProtection.has(event?.event_type)) {
                eventInterface.text = this.familyValuesService.familyEventsTexts?.[event?.event_type]?.[event?.data?.device_type]?.text ?? '';
                placeholders = this.familyValuesService.familyEventsTexts?.[event?.event_type]?.[event?.data?.device_type]?.placeholders ?? [];
            } else {
                eventInterface.text = this.familyValuesService.familyEventsTexts?.[event?.event_type]?.text ?? '';
                placeholders = this.familyValuesService.familyEventsTexts?.[event?.event_type]?.placeholders ?? [];
            }

            for (const placeholder of placeholders) {
                if (this.computationsFunctions[placeholder]) {
                    eventInterface.placeholders[placeholder] = this.computationsFunctions[placeholder](event);
                } else {
                    eventInterface.placeholders[placeholder] = event?.data[placeholder] ?? '';
                }
            }
            if (eventInterface.text) {
                auxEventsInterface.push(eventInterface);
            }
        }
        this.eventsInterface = auxEventsInterface;
    }

    /**
     * Gets the security activity events interface
     * @returns {FamilyEventInterface[]} The events interface object
     */
    public getFamilyEventsInterface(): FamilyEventInterface[] {
        return this.eventsInterface;
    }

}
