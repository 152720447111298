// External
import { Injectable } from '@angular/core';
import { NavigationExtras } from '@angular/router';

// Internal
import { ProductsToInstall, ValuesService, OperatingSystems } from '../values/values.service';
import { ConfigService } from '../config/config.service';
import { LanguageService } from '../services/core/language.service';
import { UsefulService } from '../services/global/useful/useful.service';
import { CommercialidsService } from '../services/process/commercialids/commercialIds.service';
import { SubscriptionsValuesService } from '../values/subscriptions.values.service';
import { PrivacyValuesService } from '../values/privacy.values.service';
import { PrivacyActionsService } from '../../pages/privacy/privacyActions.service';
import { CampaignParameters, CampaignMediaIdentifiers, CampaignNames } from '../services/core/AdobeParams.model';
import { AdobeDataLayerService } from '../services/core/adobe.datalayer.service';
import { ValuesSupportService } from '../../pages/support/values/values-support.service';
import { WebmailProtectionInboxProviders } from '../models/security/WebmailProtection.model';

interface AtoLinks {
    accessAccount: {
        canLogin: {
            login: string;
            accountRecovery: string;
        };
        canNotLogin: {
            concat: string;
            reach: string;
        };
    },
    resetPass: string;
    reviewSettings: {
        removeMembers: string;
        removeApp: string;
        removeDevices: string;
    },
    checkInfo: string;
    renewSubscription: string;
}
export interface SupportPathProperties {
    path: string;
    extras?: NavigationExtras;
    external: boolean;
}
export interface AuthProcessParams {
    userToken: string;
    inboxProvider: WebmailProtectionInboxProviders;
    email?: string;
    includeGrantedScopes?: boolean; //default e false
    referral?: string;
}

export const terms_links = {
    ar_SA: 'https://www.bitdefender.com/site/view/legal-terms.html',
    cs_CZ: 'https://www.bitdefender.com/site/view/legal-terms.html',
    de_DE: 'https://www.bitdefender.de/site/view/legal-terms.html',
    ek_GR: 'https://www.bitdefender.gr/legalterms',
    el_GR: 'https://bitdefender.gr/terms-of-use/',
    en_AU: 'https://www.bitdefender.com.au/site/view/legal-terms.html',
    en_UK: 'https://www.bitdefender.co.uk/site/view/legal-terms.html',
    en_US: 'https://www.bitdefender.com/site/view/legal-terms.html',
    en_GB: 'https://www.bitdefender.com/site/view/legal-terms.html',
    es_ES: 'https://www.bitdefender.es/site/view/legal-terms.html',
    es_LA: 'https://www.bitdefender.com/site/view/legal-terms.html',
    es_OR: 'https://www.bitdefender.com/site/view/legal-terms.html',
    fa_IR: 'https://www.bitdefender.ir/site/view/legal-terms.html',
    fr_CA: 'https://www.bitdefender.fr/site/view/legal-terms.html',
    fr_FR: 'https://www.bitdefender.fr/site/view/legal-terms.html',
    fr_FN: 'https://www.bitdefender.fr/site/view/legal-terms.html',
    hu_HU: 'https://www.bitdefender.com/site/view/legal-terms.html',
    it_IT: 'https://www.bitdefender.it/site/view/legal-terms.html',
    ja_JP: 'https://www.bitdefender.com/site/view/legal-terms.html',
    ko_KR: 'https://www.bitdefender.com/site/view/legal-terms.html',
    nl_NL: 'https://www.bitdefender.nl/site/view/legal-terms.html',
    pl_PL: 'https://bitdefender.pl/dokumenty/',
    pt_BR: 'https://www.bitdefender.com.br/site/view/legal-terms.html',
    pt_PT: 'https://www.bitdefender.pt/site/view/legal-terms.html',
    ro_RO: 'https://www.bitdefender.ro/site/view/legal-terms.html',
    ru_RU: 'https://www.bitdefender.com/site/view/legal-terms.html',
    sv_SE: 'https://www.bitdefender.se/site/view/legal-terms.html',
    th_TH: 'https://www.bitdefender.com/site/view/legal-terms.html',
    tr_TR: 'https://www.bitdefender.com/site/view/legal-terms.html',
    vi_VN: 'https://www.bitdefender.com/site/view/legal-terms.html',
    zh_TW: 'https://www.bitdefender.com/site/view/legal-terms.html'
};

export const privacy_policy_links = {
    ar_SA: 'https://www.bitdefender.com/links/ar-sa/privacy-policy.html',
    cs_CZ: 'https://www.bitdefender.com/links/cs-cz/privacy-policy.html',
    de_DE: 'https://www.bitdefender.com/links/de-de/privacy-policy.html',
    ek_GR: 'https://www.bitdefender.com/links/ek-gr/privacy-policy.html',
    el_GR: 'https://www.bitdefender.com/links/el-gr/privacy-policy.html',
    en_AU: 'https://www.bitdefender.com/links/en-au/privacy-policy.html',
    en_UK: 'https://www.bitdefender.com/links/en-uk/privacy-policy.html',
    en_US: 'https://www.bitdefender.com/links/en-us/privacy-policy.html',
    en_GB: 'https://www.bitdefender.com/links/en-gb/privacy-policy.html',
    es_ES: 'https://www.bitdefender.com/links/es-es/privacy-policy.html',
    es_LA: 'https://www.bitdefender.com/links/es-la/privacy-policy.html',
    es_OR: 'https://www.bitdefender.com/links/es-or/privacy-policy.html',
    fa_IR: 'https://www.bitdefender.com/links/fa-ir/privacy-policy.html',
    fr_CA: 'https://www.bitdefender.com/links/fr-ca/privacy-policy.html',
    fr_FR: 'https://www.bitdefender.com/links/fr-fr/privacy-policy.html',
    fr_FN: 'https://www.bitdefender.com/links/fr-fn/privacy-policy.html',
    it_IT: 'https://www.bitdefender.com/links/it-it/privacy-policy.html',
    ja_JP: "https://www.bitdefender.com/links/ja-jp/privacy-policy.html",
    ko_KR: "https://www.bitdefender.com/links/ko-kr/privacy-policy.html",
    nl_NL: 'https://www.bitdefender.com/links/nl-nl/privacy-policy.html',
    pl_PL: 'https://www.bitdefender.com/links/pl-pl/privacy-policy.html',
    pt_BR: 'https://www.bitdefender.com/links/pt-br/privacy-policy.html',
    pt_PT: 'https://www.bitdefender.com/links/pt-pt/privacy-policy.html',
    ro_RO: 'https://www.bitdefender.com/links/ro-ro/privacy-policy.html',
    ru_RU: 'https://www.bitdefender.com/links/ru-ru/privacy-policy.html',
    tr_TR: 'https://www.bitdefender.com/links/tr-tr/privacy-policy.html',
    th_TH: 'https://www.bitdefender.com/links/en-us/privacy-policy.html',
    vi_VN: 'https://www.bitdefender.com/links/vi-vn/privacy-policy.html',
    zh_TW: 'https://www.bitdefender.com/links/zh-tw/privacy-policy.html',
    sv_SE: 'https://www.bitdefender.com/links/sv-se/privacy-policy.html',
    hu_HU: 'https://www.bitdefender.com/links/en-us/privacy-policy.html'
};

export const support_links = {
    en_AU: 'https://www.bitdefender.com.au/consumer/support/get-help-netgear-au/',
    en_UK: 'https://www.bitdefender.co.uk/consumer/support/get-help-netgear-uk/',
    en_US: 'https://www.bitdefender.com/consumer/support/get-help-netgear/',
    fr_CA: 'https://www.bitdefender.fr/consumer/support/obtenir-aide-netgear/',
    fr_FR: 'https://www.bitdefender.fr/consumer/support/obtenir-aide-netgear/'
};

export const community_links = {
    fr_FR: 'https://community.bitdefender.com/fr',
    fr_CA: 'https://community.bitdefender.com/fr',
    de_DE: 'https://community.bitdefender.com/de',
    ro_RO: 'https://community.bitdefender.com/ro',
    es_ES: 'https://community.bitdefender.com/es',
    en_US: 'https://community.bitdefender.com/'
};

export const passwordManagerHowToImportLinks = {
    it_IT: 'https://www.bitdefender.it/consumer/support/answer/53235/',
    pt_PT: 'https://www.bitdefender.pt/consumer/support/answer/27996/',
    fr_CA: 'https://www.bitdefender.fr/consumer/support/answer/13458/',
    fr_FR: 'https://www.bitdefender.fr/consumer/support/answer/13458/',
    de_DE: 'https://www.bitdefender.de/consumer/support/answer/13486/',
    ro_RO: 'https://www.bitdefender.ro/consumer/support/answer/21594/',
    es_ES: 'https://www.bitdefender.es/consumer/support/answer/14179/',
    nl_NL: 'https://www.bitdefender.nl/consumer/support/answer/14471/',
    sv_SE: 'https://www.bitdefender.se/consumer/support/answer/17301/',
    pt_BR: 'https://www.bitdefender.com.br/consumer/support/answer/53236/',
    zh_TW: 'https://www.bitdefender.com.tw/consumer/support/answer/94967/',
    default: 'https://www.bitdefender.com/consumer/support/answer/13355/'
};

export const passwordManagerHowToSwitchLinks = {
    it_IT: 'https://www.bitdefender.it/consumer/support/answer/30267/',
    pt_PT: 'https://www.bitdefender.pt/consumer/support/answer/28204/',
    fr_CA: 'https://www.bitdefender.fr/consumer/support/answer/11075/',
    fr_FR: 'https://www.bitdefender.fr/consumer/support/answer/11075/',
    de_DE: 'https://www.bitdefender.de/consumer/support/answer/12278/',
    ro_RO: 'https://www.bitdefender.ro/consumer/support/answer/21815/',
    es_ES: 'https://www.bitdefender.es/consumer/support/answer/14362/',
    nl_NL: 'https://www.bitdefender.nl/consumer/support/answer/14654/',
    sv_SE: 'https://www.bitdefender.se/consumer/support/answer/38423/',
    pt_BR: 'https://www.bitdefender.com.br/consumer/support/answer/27806/',
    zh_TW: 'https://www.bitdefender.com.tw/consumer/support/answer/94969/',
    default: 'https://www.bitdefender.com/consumer/support/answer/2108/'
};

export const passwordManagerLearnMoreLinks = {
    it_IT: 'https://www.bitdefender.it/consumer/support/answer/22174/',
    pt_BR: 'https://www.bitdefender.com.br/consumer/support/answer/27766/',
    pt_PT: 'https://www.bitdefender.pt/consumer/support/answer/28161/',
    fr_FR: 'https://www.bitdefender.fr/consumer/support/answer/10991/',
    de_DE: 'https://www.bitdefender.de/consumer/support/answer/12206/',
    ro_RO: 'https://www.bitdefender.ro/consumer/support/answer/21772/',
    es_ES: 'https://www.bitdefender.es/consumer/support/answer/14283/',
    nl_NL: 'https://www.bitdefender.nl/consumer/support/answer/14575/',
    sv_SE: 'https://www.bitdefender.se/consumer/support/answer/54610/',
    zh_TW: 'https://www.bitdefender.com.tw/consumer/support/answer/94971/',
    default: 'https://www.bitdefender.com/consumer/support/answer/2700/'
};

export const passwordManagerFAQ = {
    fr_FR: 'https://www.bitdefender.fr/consumer/support/product/101108/',
    fr_CA: 'https://www.bitdefender.fr/consumer/support/product/101108/',
    de_DE: 'https://www.bitdefender.de/consumer/support/product/101110/',
    it_IT: 'https://www.bitdefender.it/consumer/support/product/101116/',
    ro_RO: 'https://www.bitdefender.ro/consumer/support/product/101112/',
    nl_NL: 'https://www.bitdefender.nl/consumer/support/product/101114/',
    pt_PT: 'https://www.bitdefender.pt/consumer/support/product/101118/',
    pt_BR: 'https://www.bitdefender.com.br/consumer/support/product/101124/',
    es_ES: 'https://www.bitdefender.es/consumer/support/product/101122/',
    sv_SE: 'https://www.bitdefender.se/consumer/support/product/101120/',
    zh_TW: 'https://www.bitdefender.com.tw/consumer/support/get-help-pass-manager/',
    default: 'https://www.bitdefender.com/consumer/support/product/101106/'
};

export const legal_terms_links = {
    en_AU: 'https://www.bitdefender.com.au/legal/',
    en_UK: 'https://www.bitdefender.co.uk/legal/',
    en_US: 'https://www.bitdefender.com/legal/',
    es_ES: 'https://www.bitdefender.es/legal/',
    fr_FR: 'https://www.bitdefender.fr/legal/',
    fr_CA: 'https://www.bitdefender.fr/legal/',
    it_IT: 'https://www.bitdefender.it/legal/',
    nl_NL: 'https://www.bitdefender.nl/site/view/legal-eula.html',
    pt_BR: 'https://www.bitdefender.com.br/legal/',
    pt_PT: 'https://www.bitdefender.pt/legal/',
    ro_RO: 'https://www.bitdefender.ro/legal/',
    sv_SE: 'https://www.bitdefender.se/legal/'
};

export const newParentalLearnMoreLinks = {
    de_DE: 'https://www.bitdefender.de/consumer/support/answer/99277/',
    en_AU: 'https://www.bitdefender.com.au/consumer/support/answer/99270/',
    en_GB: 'https://www.bitdefender.co.uk/consumer/support/answer/99269/',
    en_US: 'https://www.bitdefender.com/consumer/support/answer/99259/',
    es_ES: 'https://www.bitdefender.es/consumer/support/answer/99279/',
    fr_FR: 'https://www.bitdefender.fr/consumer/support/answer/99276/',
    fr_CA: 'https://www.bitdefender.fr/consumer/support/answer/99276/',
    it_IT: 'https://www.bitdefender.it/consumer/support/answer/99261/',
    nl_NL: 'https://www.bitdefender.nl/consumer/support/answer/99280/',
    pt_BR: 'https://www.bitdefender.com.br/consumer/support/answer/99263/',
    pt_PT: 'https://www.bitdefender.pt/consumer/support/answer/99273/',
    ro_RO: 'https://www.bitdefender.ro/consumer/support/answer/99278/',
    sv_SE: 'https://www.bitdefender.se/consumer/support/answer/99281/',
    zh_TW: 'https://www.bitdefender.com.tw/consumer/support/answer/99275/',
    default: 'https://www.bitdefender.com/consumer/support/answer/99259/'
};

export const securityNewsLinks = {
    en_US: 'https://www.bitdefender.com/en-us/fragments/central/news.json'
};

export enum PasswordManagementMobileStores {
    APPSTORE = 'appStore',
    GOOGLEPLAY = 'googlePlay'
}

export type PasswordManagementMobileLinks = {
    [key in PasswordManagementMobileStores]: string;
}

export enum SecurePassHelpLinks {
    IMPORT_PASSWORDS = 'importPasswords',
    SWITCH_FROM = 'switchFrom',
    FAQ = 'securePassFAQ',
    ASK_COMMUNITY = 'askCommunity'
}

export type SecurePassHelpLinksType = {
    [key in SecurePassHelpLinks]?: {
        default: string;
    };
};

export type SecurePassInstallationSupportLinksType = {
    [key in OperatingSystems]?: {
        [key: string]: string;
    };
};

@Injectable({
    providedIn: 'root'
})
export class LinksService {

    // ! Update this objects when KBs are ready
    private readonly securePassHelpLinksObject: SecurePassHelpLinksType = {
        [SecurePassHelpLinks.IMPORT_PASSWORDS]: {
            default: 'https://www.bitdefender.com/consumer/support/answer/108399/'
        },
        [SecurePassHelpLinks.SWITCH_FROM]: {
            default: 'https://www.bitdefender.com/consumer/support/answer/108885/'
        },
        [SecurePassHelpLinks.FAQ]: {
            default: 'https://www.bitdefender.com/consumer/support/answer/107798/'
        }
    };

    public readonly securePassInstallationSupportLinks: SecurePassInstallationSupportLinksType = {
        [OperatingSystems.WINDOWS]: {
            en_US: 'https://www.bitdefender.com/consumer/support/answer/108139/',
            en_UK: 'https://www.bitdefender.co.uk/consumer/support/answer/108141/',
            en_AU: 'https://www.bitdefender.com.au/consumer/support/answer/108142/',
            it_IT: 'https://www.bitdefender.it/consumer/support/answer/108146/',
            pt_BR: 'https://www.bitdefender.com.br/consumer/support/answer/108147/',
            pt_PT: 'https://www.bitdefender.pt/consumer/support/answer/108148/',
            ch_HK: 'https://www.bitdefender.com.hk/consumer/support/answer/108149/',
            ch_TW: 'https://www.bitdefender.com.tw/consumer/support/answer/108150/',
            fr_FR: 'https://www.bitdefender.fr/consumer/support/answer/108151/',
            de_DE: 'https://www.bitdefender.de/consumer/support/answer/108152/',
            ro_RO: 'https://www.bitdefender.ro/consumer/support/answer/108153/',
            es_ES: 'https://www.bitdefender.es/consumer/support/answer/108154/',
            nl_NL: 'https://www.bitdefender.nl/consumer/support/answer/108155/',
            sv_SE: 'https://www.bitdefender.se/consumer/support/answer/108156/',
            default: 'https://www.bitdefender.com/consumer/support/answer/108139/',
        },
        [OperatingSystems.IOS]: {
            en_US: 'https://www.bitdefender.com/consumer/support/answer/108174/',
            en_UK: 'https://www.bitdefender.co.uk/consumer/support/answer/108176/',
            en_AU: 'https://www.bitdefender.com.au/consumer/support/answer/108177/',
            it_IT: 'https://www.bitdefender.it/consumer/support/answer/108181/',
            pt_BR: 'https://www.bitdefender.com.br/consumer/support/answer/108182/',
            pt_PT: 'https://www.bitdefender.pt/consumer/support/answer/108183/',
            ch_HK: 'https://www.bitdefender.com.hk/consumer/support/answer/108184/',
            ch_TW: 'https://www.bitdefender.com.tw/consumer/support/answer/108185/',
            fr_FR: 'https://www.bitdefender.fr/consumer/support/answer/108186/',
            de_DE: 'https://www.bitdefender.de/consumer/support/answer/108187/',
            ro_RO: 'https://www.bitdefender.ro/consumer/support/answer/108188/',
            es_ES: 'https://www.bitdefender.es/consumer/support/answer/108189/',
            nl_NL: 'https://www.bitdefender.nl/consumer/support/answer/108190/',
            sv_SE: 'https://www.bitdefender.se/consumer/support/answer/108191/',
            default:  'https://www.bitdefender.com/consumer/support/answer/108174/'
        },
        [OperatingSystems.ANDROID]: {
            en_US: 'https://www.bitdefender.com/consumer/support/answer/108157/',
            en_UK: 'https://www.bitdefender.co.uk/consumer/support/answer/108159/',
            en_AU: 'https://www.bitdefender.com.au/consumer/support/answer/108160/',
            it_IT: 'https://www.bitdefender.it/consumer/support/answer/108161/',
            pt_BR: 'https://www.bitdefender.com.br/consumer/support/answer/108162/',
            pt_PT: 'https://www.bitdefender.pt/consumer/support/answer/108163/',
            ch_HK: 'https://www.bitdefender.com.hk/consumer/support/answer/108164/',
            ch_TW: 'https://www.bitdefender.com.tw/consumer/support/answer/108165/',
            fr_FR: 'https://www.bitdefender.fr/consumer/support/answer/108166/',
            de_DE: 'https://www.bitdefender.de/consumer/support/answer/108167/',
            ro_RO: 'https://www.bitdefender.ro/consumer/support/answer/108169/',
            es_ES: 'https://www.bitdefender.es/consumer/support/answer/108170/',
            nl_NL: 'https://www.bitdefender.nl/consumer/support/answer/108171/',
            sv_SE: 'https://www.bitdefender.se/consumer/support/answer/108172/',
            default: 'https://www.bitdefender.com/consumer/support/answer/108157/'
        }
    };

    public readonly securePassHowToExportLinks = {
        default: 'https://www.bitdefender.com/consumer/support/answer/108400/'
    };


    private readonly atoComplianceLink = 'https://www.bitdefender.com/consumer/support/answer/101490/';
    public readonly atoAccountTakeoverLinks: AtoLinks = {
        accessAccount: {
            canLogin: {
                login: 'https://www.youtube.com/signin',
                accountRecovery: 'https://accounts.google.com/signin/recovery'
            },
            canNotLogin: {
                concat: 'https://twitter.com/TeamYouTube',
                reach: 'https://studio.youtube.com/'
            }
        },
        resetPass: 'https://myaccount.google.com/signinoptions/password',
        reviewSettings: {
            removeMembers: 'https://studio.youtube.com/',
            removeApp: 'https://myaccount.google.com/connections',
            removeDevices: 'https://myaccount.google.com/device-activity'
        },
        checkInfo: 'https://myaccount.google.com/security',
        renewSubscription: 'https://www.bitdefender.com/consumer/security-for-creators'
    };

    constructor(
        private readonly configService: ConfigService,
        private readonly languageService: LanguageService,
        private readonly valuesService: ValuesService,
        private readonly usefulService: UsefulService,
        private readonly commercialIdsService: CommercialidsService,
        private readonly subscriptionsValuesService: SubscriptionsValuesService,
        private readonly adobeDataLayerService: AdobeDataLayerService,
        private readonly privacyValuesService: PrivacyValuesService,
        private readonly privacyActionsService: PrivacyActionsService,
        private readonly valuesSupportService: ValuesSupportService
    ) {}

    termsAndConditionsDirectSales = {
        [this.valuesService.languages.en_US.id]: 'https://www.bitdefender.com/site/view/terms-and-conditions-direct-sales-bitdefender-solutions.html',
        [this.valuesService.languages.nl_NL.id]: 'https://www.bitdefender.nl/site/view/terms-and-conditions-direct-sales-bitdefender-solutions.html',
        default: 'https://www.bitdefender.com/site/view/terms-and-conditions-direct-sales-bitdefender-solutions.html',
    };

    enduserLicenseAgreement = {
        [this.valuesService.languages.en_US.id]: 'https://www.bitdefender.com/site/view/legal-eula.html',
        [this.valuesService.languages.nl_NL.id]: 'https://www.bitdefender.nl/site/view/legal-eula.html',
        default: 'https://www.bitdefender.com/site/view/legal-eula.html',
    };

    private readonly hotForSecurityLink = 'https://www.bitdefender.com/blog/hotforsecurity/';
    private readonly getGmailAccountInfoUrl = 'https://www.googleapis.com/oauth2/v1/tokeninfo';
    private readonly getOutlookAccountInfoUrl = 'https://graph.microsoft.com/v1.0/me';
    public readonly appStoreLink = 'https://apps.apple.com/us/app/netgear-nighthawk-wifi-app/id1124666597';
    public readonly appStoreLinkOrbi = 'https://apps.apple.com/us/app/netgear-orbi-wifi-system-app/id1182184397';
    public readonly googlePlayLink = 'https://play.google.com/store/apps/details?id=com.netgear.netgearup';
    public readonly googlePlayLinkOrbi = 'https://play.google.com/store/search?q=netgear+orbi+application&c=apps';
    public readonly sharedSubscriptionsKb = 'https://www.bitdefender.com/consumer/support/answer/19655/';
    public readonly usUsLearnMoreLink = 'https://www.bitdefender.com/media/html/consumer/renew/id-ult-plus-2022-opt/?force_country=us&pid=CENTRAL_NA_RENEWAL_USEC';

    private readonly webmailProtectionGmailOauth2Scopes = [
        'https://www.googleapis.com/auth/userinfo.email',
        'https://www.googleapis.com/auth/gmail.modify',
        'https://www.googleapis.com/auth/gmail.labels'
    ];
    private readonly webmailProtectionOutlookOauth2Scopes = [
        'email',
        'Mail.Read',
        'Mail.ReadBasic',
        'openid',
        'Mail.ReadWrite',
        'MailboxSettings.ReadWrite',
        'offline_access',
        'profile',
        'User.Read'
    ];
    private readonly gmailWebmailProtectionOauth2Url = 'https://accounts.google.com/o/oauth2/v2/auth';
    private readonly outlookWebmailProtectionOauth2Url = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize';

    /**
     * Gets the link used to verify the gmail outh2 token
     * @returns {string} the link used to verify the outh2 token
     */
    public getGmailTokenInfoUrl(): string {
        return this.getGmailAccountInfoUrl;
    }

    /**
     * Gets the link used to verify the outlook outh2 token
     * @returns {string} the link used to verify the outh2 token
     */
    public getOutlookTokenInfoUrl(): string {
        return this.getOutlookAccountInfoUrl;
    }

    /**
     * Returns security news url for the current language.
     * @returns {string | null} url or null if security news url does not exist
     */
    public getSecurityNewsUrl(): string|null {
        const lang = this.languageService.getLang();
        if (lang && securityNewsLinks.hasOwnProperty(lang)) {
            return securityNewsLinks[lang];
        } else if (this.valuesService.englishLanguages.has(lang)) {
            return securityNewsLinks[this.valuesService.languages.en_US.id];
        }
        return null;
    }

    /**
     * Gets home url based on commercialIds Object. If the Object does not contain info about the home url,
     * it returns a defualt value computed based on the country of the account
     * @returns {String} home url or default value
     */
    getHomePageUrl() {
        const country            = this.languageService.getCountry();
        const finalCountry       = this.usefulService.getNested(this.valuesService.countryLanguage, this.valuesService.defaultCountry, country);
        const homePageUrl        = this.commercialIdsService.getHomePageUrl();
        const defaultHomePageUrl = this.valuesService.commercialIds_webpage.replace("{country}", finalCountry);
        return homePageUrl ? this.adobeDataLayerService.addRefAndVisitor(homePageUrl) : this.adobeDataLayerService.addRefAndVisitor(defaultHomePageUrl);
    }

    terms() {
        const lang = this.languageService.getLang();
        if (lang) {
            return this.adobeDataLayerService.addRefAndVisitor(terms_links[lang]);
        } else {
            return this.adobeDataLayerService.addRefAndVisitor(terms_links.en_US);
        }
    }

    support() {
        const lang = this.languageService.getLang();
        if (lang && support_links.hasOwnProperty(lang)) {
            return this.adobeDataLayerService.addRefAndVisitor(support_links[lang]);
        } else {
            return this.adobeDataLayerService.addRefAndVisitor(support_links.en_US);
        }
    }

    /**
     * Function that computes the url for the gmail webmail protection oauth2 page
     * @param {string} userToken The user token
     * @param {string} email User email
     * @param {boolean} includeGrantedScopes Param for gmail url
     * @param {string} referral the url of the redirected page
     * @returns {string} The url of the gmail webmail protection oauth2 page
     */
    private getGmailWebmailProtectionOauth2Url(userToken: string, email: string, includeGrantedScopes: boolean, referral?: string): string {
        const webmailProtectionStaticPagePath = this.valuesService.centralPaths.services.path.concat(this.valuesService.centralPaths.services.webmailprotection.path);
        const state = `${this.valuesService.queryParams.userToken}=${userToken}&${this.valuesService.queryParams.inboxProvider}=${WebmailProtectionInboxProviders.GMAIL}`;
        const queryParams: any = {
            client_id: this.configService.getGmailAuthClientId(),
            response_type: 'code',
            state: (email ? `${state}&email=${email}` : state).concat(referral ? `&referral=${referral}` : ''),
            scope: this.webmailProtectionGmailOauth2Scopes.join(' '),
            redirect_uri: `${location.protocol}//${location.host}${webmailProtectionStaticPagePath}`,
            access_type: 'offline',
            prompt: 'consent'
        };

        if (includeGrantedScopes) {
            queryParams.include_granted_scopes = true;
        }

        if (email) {
            queryParams.login_hint = this.usefulService.decodeBase64Url(email);
        }
        const queryString = this.usefulService.jsonToQueryString(queryParams);
        return `${this.gmailWebmailProtectionOauth2Url}?${queryString}`;
    }

    /**
     * Function that computes the url for the outlook webmail protection oauth2 page
     * @param {string} userToken The user token
     * @param {string} email User email
     * @param {string} referral the url of the redirected page
     * @returns {string} The url of the outlook webmail protection oauth2 page
     */
    private getOutlookWebmailProtectionOauth2Url(userToken: string, email: string, referral?: string): string {
        const webmailProtectionStaticPagePath = this.valuesService.centralPaths.services.path.concat(this.valuesService.centralPaths.services.webmailprotection.path);
        const state = `${this.valuesService.queryParams.userToken}=${userToken}&${this.valuesService.queryParams.inboxProvider}=${WebmailProtectionInboxProviders.OUTLOOK}`;
        const queryParams: any = {
            client_id: this.configService.getOutlookAuthClientId(),
            response_type: 'code',
            state: (email ? `${state}&email=${email}` : state).concat(referral ? `&referral=${referral}` : ''),
            scope: this.webmailProtectionOutlookOauth2Scopes.join(' '),
            redirect_uri: `${location.protocol}//${location.host}${webmailProtectionStaticPagePath}`,
            response_mode: 'query',
            prompt: 'consent'
        };

        if (email) {
            queryParams.login_hint = this.usefulService.decodeBase64Url(email);
        }

        const queryString = this.usefulService.jsonToQueryString(queryParams);
        return `${this.outlookWebmailProtectionOauth2Url}?${queryString}`;
    }

    /**
     * Function that computes the url for the webmail protection oauth2 page
     * @param {AuthProcessParams} authParams the parameters to be sent to the oauth2 page
     * @returns {string} The url of the webmail protection oauth2 page
     */
    public getWebmailProtectionOauth2Url(authParams: AuthProcessParams): string {
        const { userToken, inboxProvider, email, includeGrantedScopes, referral } = authParams;
        switch (inboxProvider) {
            case WebmailProtectionInboxProviders.GMAIL:
                return this.getGmailWebmailProtectionOauth2Url(userToken, email, includeGrantedScopes ?? false, referral);
            case WebmailProtectionInboxProviders.OUTLOOK:
                return this.getOutlookWebmailProtectionOauth2Url(userToken, email, referral);
            default:
                return '';
        };
    }

    /**
     * Function that returns the webmail protection oauth2 scopes
     * @returns {string[]} The webmail protection oauth2 scopes
     */
    public getWebmailProtectionGmailOauth2Scopes(): string[] {
        return this.webmailProtectionGmailOauth2Scopes;
    }

    /**
     * Function that returns the webmail protection oauth2 scopes
     * @returns {string[]} The webmail protection oauth2 scopes
     */
    public getWebmailProtectionOutlookOauth2Scopes(): string[] {
        return this.webmailProtectionOutlookOauth2Scopes;
    }

    /**
     * Function that computes the url for the webmail protection list keys.
     * @returns {string} The url of the webmail protection list keys
     */
    public getWebmailProtectionListKeysUrl(): string {
        return `${this.configService.config.nimbusServer}${this.valuesService.webmailProtectionMgmtService}/.well-known/jwks.json`;
    }

    /**
     * Gets the support path properties object based on whether the support is external or not
     * @returns {Object} The support path properties object
     */
    public getFinalSupportLink(): SupportPathProperties {
        return this.configService.getExternalSupport()
                ? { path: this.support(), external: true }
                : { path: this.valuesService.centralPaths.support.path, external: false };
    }

    /**
     * Gets the contact support path properties object based on whether the support is external or not
     * @returns {Object} The support path properties object
     */
    public getFinalContactSupportLink(): SupportPathProperties {
        return this.configService.getExternalSupport()
                ? { path: this.support(), external: true }
                : { path: this.valuesService.centralPaths.support.path.concat(this.valuesService.centralPaths.support.form.path), external: false };
    }

    /**
     * Gets the "how to install" article support path properties object based on whether the support is external or not
     * @returns {Object} The support path properties object
     */
    public getHowToInstallFinalSupportLink(): SupportPathProperties {
        const language = this.languageService.getLang();
        let articlePath = this.valuesSupportService.linksFAQ[0][language] ?? this.valuesSupportService.linksFAQ[0].default;
        return this.configService.getExternalSupport()
                ? { path: this.adobeDataLayerService.addRefAndVisitor(articlePath), external: true }
                : { path: this.valuesService.centralPaths.support.path,
                    extras: {queryParams: {[this.valuesService.openSupportFaqQueryParam]: this.valuesService.howToInstallSupportFaqIndex}},
                    external: false };
    }

    community() {
        const lang = this.languageService.getLang();
        if (lang && community_links.hasOwnProperty(lang)) {
            return this.adobeDataLayerService.addRefAndVisitor(community_links[lang]);
        } else {
            return this.adobeDataLayerService.addRefAndVisitor(community_links.en_US);
        }
    }

    passwordManagerHowToImportLinks() {
        const lang = this.languageService.getLang();
        if (lang && passwordManagerHowToImportLinks.hasOwnProperty(lang)) {
            return this.adobeDataLayerService.addRefAndVisitor(passwordManagerHowToImportLinks[lang]);
        } else {
            return this.adobeDataLayerService.addRefAndVisitor(passwordManagerHowToImportLinks.default);
        }
    }

    /**
     * Gets switch url based on identified language
     * If the corresponding links object contains a custom value for language it returns it;
     * otherwise, it returns the defualt value
     * @returns {String} url or default value
     */
    public passwordManagerHowToSwitchLinks(): string {
        const lang = this.languageService.getLang();
        if (lang && passwordManagerHowToSwitchLinks.hasOwnProperty(lang)) {
            return this.adobeDataLayerService.addRefAndVisitor(passwordManagerHowToSwitchLinks[lang]);
        } else {
            return this.adobeDataLayerService.addRefAndVisitor(passwordManagerHowToSwitchLinks.default);
        }
    }

     /**
     * Gets learn more url based on identified language
     * If the corresponding links object contains a custom value for language it returns it;
     * otherwise, it returns the defualt value
     * @returns {String} url or default value
     */
    public passwordManagerLearnMoreLinks(): string {
        const lang = this.languageService.getLang();
        if (lang && passwordManagerLearnMoreLinks.hasOwnProperty(lang)) {
            return this.adobeDataLayerService.addRefAndVisitor(passwordManagerLearnMoreLinks[lang]);
        } else {
            return this.adobeDataLayerService.addRefAndVisitor(passwordManagerLearnMoreLinks.default);
        }
    }

    passwordManagerFAQ() {
        const lang = this.languageService.getLang();
        if (lang && passwordManagerFAQ.hasOwnProperty(lang)) {
            return this.adobeDataLayerService.addRefAndVisitor(passwordManagerFAQ[lang]);
        } else {
            return this.adobeDataLayerService.addRefAndVisitor(passwordManagerFAQ.default);
        }
    }

    /**
     * Gets new parental learn more url based on identified language
     * @returns {string} url
     */
    public newParentalLearnMoreLinks(): string {
        const lang = this.languageService.getLang();
        if (lang && newParentalLearnMoreLinks.hasOwnProperty(lang)) {
            return this.adobeDataLayerService.addRefAndVisitor(newParentalLearnMoreLinks[lang]);
        } else {
            return this.adobeDataLayerService.addRefAndVisitor(newParentalLearnMoreLinks.default);
        }
    }

    // only for nl for the moment (zuora is testing only in nl)
    getEnduserLicenseAgreement() {
        return this.adobeDataLayerService.addRefAndVisitor(this.enduserLicenseAgreement[this.valuesService.languages.nl_NL.id]);
    }

    // only for nl for the moment (zuora is testing only in nl)
    getTermsAndConditionsDirectSales() {
        return this.adobeDataLayerService.addRefAndVisitor(this.termsAndConditionsDirectSales[this.valuesService.languages.nl_NL.id]);
    }

    getPrivacyPolicyLinkZuora() {
        return this.adobeDataLayerService.addRefAndVisitor(privacy_policy_links.nl_NL);
    }

    getPrivacyPolicyLink() {
        const lang = this.languageService.getLang();
        if (lang && privacy_policy_links.hasOwnProperty(lang)) {
            return this.adobeDataLayerService.addRefAndVisitor(privacy_policy_links[lang]);
        } else {
            return this.adobeDataLayerService.addRefAndVisitor(privacy_policy_links.en_US);
        }
    }

    getLegalTermsLink () {
        const lang = this.languageService.getLang();
        if (lang && legal_terms_links.hasOwnProperty(lang)) {
            return this.adobeDataLayerService.addRefAndVisitor(legal_terms_links[lang]);
        } else {
            return this.adobeDataLayerService.addRefAndVisitor(legal_terms_links[this.valuesService.languages.en_US.id]);
        }
    }

    getDipLandingPageLink() {
        const langCategory = this.privacyActionsService.getLanguageCategory();
        const specificLang = this.languageService.getLang();
        const defaultLangCategory = this.privacyValuesService.languageGroups.en;
        let link = '';

        if (this.privacyValuesService?.specificStaticLinks[specificLang]?.landingpage) {
            link = this.privacyValuesService.specificStaticLinks[specificLang].landingpage;
        } else if (this.privacyValuesService?.staticLinks[langCategory]?.landingpage) {
            link = this.privacyValuesService.staticLinks[langCategory].landingpage;
        } else {
            link = this.privacyValuesService?.staticLinks[defaultLangCategory]?.landingpage;
        }

        const adobeParameters: CampaignParameters = {
            internal: true,
            mediaId: CampaignMediaIdentifiers.LINK,
            campaignName: CampaignNames.DIP_PAGE
        };
        return this.adobeDataLayerService.addCidOrIcidRefAndVisitor(link, adobeParameters);
    }

    getTermsAndContitionsLink() {
        const lang = this.languageService.getLang();
        let termsAndConditionsTLD = '';
        if (lang && this.subscriptionsValuesService.termsAndConditionsTLD.hasOwnProperty(lang)) {
            termsAndConditionsTLD = this.subscriptionsValuesService.termsAndConditionsTLD[lang];
        } else {
            termsAndConditionsTLD = this.subscriptionsValuesService.termsAndConditionsTLD[this.valuesService.languages.en_US.id];
        }
        const link = "https://www.bitdefender." + termsAndConditionsTLD + "/site/view/subscription-agreement-and-terms-of-services-for-home-user-solutions.html";
        return this.adobeDataLayerService.addRefAndVisitor(link);
    }

    lotteryLinks = {
        'bitdefender': 'https://www.bitdefender.com',
        'community': 'https://community.bitdefender.com/en',
        'trustedPilot': 'https://www.trustpilot.com/review/www.bitdefender.com',
        'googlePlay': 'https://play.google.com/store/apps/dev?id=8976382606253195480&hl=en',
        'termsConds': 'https://www.bitdefender.com/site/view/contest-general-terms-and-conditions.html',
        'reviewUrl': 'https://community.bitdefender.com/en/discussion/82624/how-to-find-the-link-to-your-amazon-review',
        'setupTutorials': 'https://community.bitdefender.com/en/categories/new-user-tutorials',
        'newsCybersecWorld': this.hotForSecurityLink,
        'facebook': 'https://www.facebook.com/bitdefender/'
    };

    socialLinks = {
        'facebook': 'https://www.facebook.com/bitdefender/',
        'instagram': 'https://www.instagram.com/bitdefender',
        'linkedin': 'https://www.linkedin.com/company/bitdefender',
        'twitter': 'https://x.com/bitdefender'
    };

    // de completat cu link-urile corecte (CWEBII-3655)
    public readonly passwordManagerSfrMobileLinks: PasswordManagementMobileLinks = {
        [PasswordManagementMobileStores.APPSTORE]   : '',
        [PasswordManagementMobileStores.GOOGLEPLAY] : ''
    };

    public readonly passwordManagerMobileLinks: PasswordManagementMobileLinks = {
        [PasswordManagementMobileStores.APPSTORE]   : 'https://apps.apple.com/app/apple-store/id1590167161?pt=356885&ct=central_web&mt=8',
        [PasswordManagementMobileStores.GOOGLEPLAY] : 'https://play.google.com/store/apps/details?id=com.bitdefender.passmanager&referrer=utm_source%3Dcentral_web'
    };

    public readonly securePassMobileLinks: PasswordManagementMobileLinks = {
        [PasswordManagementMobileStores.APPSTORE]   : 'https://apps.apple.com/app/id6689517658',
        [PasswordManagementMobileStores.GOOGLEPLAY] : 'https://play.google.com/store/apps/details?id=com.bitdefender.securepass'
    };

    passManagerSfrDownloadLink = `${this.configService.getSiteUrl()}${this.valuesService.centralPaths.download.path}?product=${ProductsToInstall.PASSWORDMANAGERSFR}`;
    passwordManagerDownloadLink = `${this.configService.getSiteUrl()}${this.valuesService.centralPaths.download.path}?product=${ProductsToInstall.PASSWORDMANAGER}`;

    /**
     * Gets the extension link for pass manager in Chrome browser
     * @public
     * @memberof LinksService
     * @returns {string} The extension link
     */
    public getExtensionLinkForPassManagerOnChrome(): string {
        const id = 'ibkoenhablealnikeefmjineccmgegmh';
        // resursa publica luata din web_accessible_resources din manifest.json al extensiei
        // chrome-extension://${id}/manifest.json
        const resource = 'src/images/icons/blank.png';
        return `chrome-extension://${id}/${resource}`;
    }

    /**
     * Gets the extension link for pass manager in Edge browser
     * @public
     * @memberof LinksService
     * @returns {string} The extension link
     */
    public getExtensionLinkForPassManagerOnEdge(): string {
        const id = 'hjiaiapigbnlahicmcacnmhfckgjdgbl';
        // resursa publica luata din web_accessible_resources din manifest.json al extensiei
        // chrome-extension://${id}/manifest.json
        const resource = 'src/images/icons/blank.png';
        return `chrome-extension://${id}/${resource}`;
    }

    /**
     * Gets the extension link for Secure Pass in Chrome browser
     * @public
     * @memberof LinksService
     * @returns {string} The extension link
     */
    public getExtensionLinkForSecurePassOnChrome(): string {
        const id = 'gcmahhkjkpigcpfpmdjnbiakmbdegfeh';
        // resursa publica luata din web_accessible_resources din manifest.json al extensiei
        // chrome-extension://${id}/manifest.json
        const resource = 'data/img/psono-encrypt.png';
        return `chrome-extension://${id}/${resource}`;
    }

    /**
     * Gets the extension link for Secure Pass in Edge browser
     * @public
     * @memberof LinksService
     * @returns {string} The extension link
     */
    public getExtensionLinkForSecurePassOnEdge(): string {
        const id = 'lnfflhmanffipgdmceonijidkpiacanc';
        // resursa publica luata din web_accessible_resources din manifest.json al extensiei
        // chrome-extension://${id}/manifest.json
        const resource = 'data/img/psono-encrypt.png';
        return `chrome-extension://${id}/${resource}`;
    }

    vulnerabilityLinks = {
        "directoryTraversal" : "https://www.bitdefender.com/box/blog/vulnerabilities/understanding-iot-vulnerabilities-directory-traversal/",
        "sqlInjection"       : "https://www.bitdefender.com/box/blog/smart-home/hackers-can-hit-connected-things-tricky-requests/",
        "fileInclusion"      : "https://www.bitdefender.com/box/blog/vulnerabilities/understanding-iot-vulnerabilities-file-inclusion/",
        "executeCode"        : "https://www.bitdefender.com/box/blog/smart-home/hackers-can-wreak-havoc-code-execution-flaws/",
        "bypassRestriction"  : "https://www.bitdefender.com/box/blog/vulnerabilities/understanding-iot-vulnerabilities-restriction-bypass/",
        "gainPrivileges"     : "https://www.bitdefender.com/box/blog/smart-home/climbing-privilege-ladder-comes-serious-risks/",
        "obtainInformation"  : "https://www.bitdefender.com/box/blog/vulnerabilities/understanding-iot-vulnerabilities-information-gathering/",
        "crossSiteScripting" : "https://www.bitdefender.com/box/blog/smart-home/code-injection-attack-can-steal-web-life/",
        "denialOfService"    : "https://www.bitdefender.com/box/blog/smart-home/understanding-iot-vulnerabilities-dos-or-how-hackers-can-cut-your-access-to-devices-or-services/",
        "memoryCorruption"   : "https://www.bitdefender.com/box/blog/vulnerabilities/understanding-iot-vulnerabilities-memory-corruption-leads-surprise-dont-want-see/",
        "overflow"           : "https://www.bitdefender.com/box/blog/vulnerabilities/understanding-iot-vulnerabilities-overflow/",
        "httpResponseSplitting" : "https://www.bitdefender.com/box/blog/vulnerabilities/understanding-iot-vulnerabilities-http-response-splitting/",
        "csrf"               : "https://www.bitdefender.com/box/blog/smart-home/ill-intended-neighboring-browser-tab/",
        "cve"                : "https://www.cve.org/CVERecord?id=" //{cve_id}
    };

    termsAndConditionsReferralLink = 'https://www.bitdefender.com/site/view/the-gift-of-beeing-safe-online.html';

    /**
     * Retrun blog link for bitdefender products.
     * @return {string} blog link
     * @memberof LinksService
     */
    public getBlogLink(): string {
        return this.adobeDataLayerService.addOmnitureVisitor(this.hotForSecurityLink);
    }

    /**
     * Function that returns the link to the ATO account takeover page
     * @returns {string} the link to the ATO compliance page
     */
    public getAtoComplianceLink(): string {
        return this.adobeDataLayerService.addRefAndVisitor(this.atoComplianceLink);
    }

    /**
     * Opens App Store link in a new tab
     * @public
     * @memberof LinksService
     * @param {Event} event The click event to pe prevented for links href
     * @param {string} omniture The omniture event to be triggered
     * @param {string} customLink The custom link to be opened instead of default appStoreLink
     */
    public goToAppStore(event: any, omniture?: string, customLink?: string): void {
        event.preventDefault();
        if (omniture) {
            this.adobeDataLayerService.triggerClickEvent(omniture);
        }
        window.open(customLink ?? this.appStoreLink, "_blank" );
    }

    /**
     * Opens Google Play link in a new tab
     * @public
     * @memberof LinksService
     * @param {Event} event The click event to pe prevented for links href
     * @param {string} omniture The omniture event to be triggered
     * @param {string} customLink The custom link to be opened instead of default googlePlayLink
     */
    public goToGooglePlay(event: any, omniture?: string, customLink?: string): void {
        event.preventDefault();
        if (omniture) {
            this.adobeDataLayerService.triggerClickEvent(omniture);
        }
        window.open(this.adobeDataLayerService.addUtmSource(customLink ?? this.googlePlayLink, this.valuesService.googlePlayUtmSourceParam), '_blank');
    }

    /**
     * Gets Help link for Secure Pass
     * @public
     * @memberof LinksService
     * @param {SecurePassHelpLinks} link
     * @returns {string} The computed link
     */
    public computeSecurePassHelpLink(link: SecurePassHelpLinks): string {
        const lang = this.languageService.getLang();
        if (lang && this.securePassHelpLinksObject[link].hasOwnProperty(lang)) {
            return this.adobeDataLayerService.addRefAndVisitor(this.securePassHelpLinksObject[link][lang]);
        } else {
            return this.adobeDataLayerService.addRefAndVisitor(this.securePassHelpLinksObject[link].default);
        }
    }

    /**
     * Gets export passwords url based on identified language
     * If the corresponding links object contains a custom value for language it returns it;
     * otherwise, it returns the defualt value
     * @returns {String} url or default value
     */
    public getSecurePassHowToExportLink() {
        const lang = this.languageService.getLang();
        if (lang && this.securePassHowToExportLinks.hasOwnProperty(lang)) {
            return this.adobeDataLayerService.addRefAndVisitor(this.securePassHowToExportLinks[lang]);
        } else {
            return this.adobeDataLayerService.addRefAndVisitor(this.securePassHowToExportLinks.default);
        }
    }

    /**
     * Gets secure pass installation support link based on identified language
     * If the corresponding links object contains a custom value for language it returns it;
     * otherwise, it returns the defualt value
     * @param {OperatingSystems} key
     * @returns {String} url or default value
     */
    public getSecurePassInstallationSupportLink(key: OperatingSystems) {
        const lang = this.languageService.getLang();
        if (lang && this.securePassInstallationSupportLinks[key]?.hasOwnProperty(lang)) {
            return this.adobeDataLayerService.addRefAndVisitor(this.securePassInstallationSupportLinks[key][lang]);
        } else {
            return this.adobeDataLayerService.addRefAndVisitor(this.securePassInstallationSupportLinks[key].default);
        }
    }

}
