export class PrivacyEducationArticle {
    article_id: string;
    topic: PrivacyArticleTopic;
    tags?: PrivacyEducationArticlesTags[];
    title: string;
    short_description: string;
    content: string;
    publish_date: Date;
    author: ArticleAuthor;
    isNew: boolean;
}

export class ArticleAuthor {
    name: string;
    description: string;
}

export enum PrivacyArticleTopic {
    BREACHES_TOPIC = 'breach',
    FOOTPRINT_TOPIC = 'exposure',
    IMPERSONATIONS_TOPIC = 'impersonation',
    REMEDIATION_TOPIC = 'remediation',
    TOTAL = 'total',
    ALL = 'all'
}

export enum PrivacyEducationArticlesTags {
    FOOTPRINT_EXPOSED_INFO = 'footprint_exposed_info',
    FOOTPRINT_COMMUNITY_INFO = 'footprint_community_info',
    FOOTPRINT_ACCURACY_INFO = 'footprint_accuracy_info',
    FOOTPRINT_EVOLUTION_INFO = 'footprint_evolution_info',
    FOOTPRINT_EDUCATION_STATIC = 'footprint_education_static',
    BREACHES_EXPOSED_INFO = 'breaches_exposed_info',
    BREACHES_COMMUNITY_INFO = 'breaches_community_info',
    BREACHES_ACTIONS_INFO = 'breaches_actions_info',
    BREACHES_EVOLUTION_INFO = 'breaches_evolution_info',
    BREACHES_EDUCATION_STATIC = 'breaches_education_static',
    IMPERSONATIONS_PROFILES_INFO = 'impersonations_profiles_info',
    IMPERSONATIONS_ACTIONS_INFO = 'impersonations_actions_info',
    IMPERSONATIONS_EDUCATION_STATIC = 'impersonations_education_static'
}

export enum FeatureRegion {
    EXPOSURE = 'exposure',
    COMMUNITY = 'community',
    ACTIONS = 'actions',
    EVOLUTION = 'evolution',
    VALIDATION = 'validation',
    PROFILE = 'profile',
    PROGRESS = 'progress'
}

export interface PrivacyTopicsCountInterface {
    topics: ArticlesTopicsCountInterface;
    total: number;
}

export type ArticlesTopicsCountInterface = {
    [key in PrivacyArticleTopic]?: number;
}

export interface PrivacyEducationArticleInfo {
    topic: PrivacyArticleTopic;
    staticTag?: PrivacyEducationArticlesTags;
    infoTags?: {
        [key in FeatureRegion]?: PrivacyEducationArticlesTags;
    };
}

export type AllArticlesObj = {
    [key in PrivacyEducationArticlesTags]?: PrivacyEducationArticle[];
}