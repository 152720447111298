import { Injectable } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';
import { ValuesService } from '../../../values/values.service';
import { CookieService } from 'ngx-cookie-service';
import { POST, RequestsService } from '../../global/request-service/requests.service';
import { ConfigService } from '../../../config/config.service';
import { MarkAsUsefulRemediationParams, RemediationLanguage } from '../../../../common/models/privacy/Remediation.model';

@Injectable({
  providedIn: 'root'
})
export class LidDataPrivacyService {

    constructor(
        private readonly valuesService: ValuesService,
        private readonly cookieService: CookieService,
        private readonly requestsService: RequestsService,
        private readonly configService: ConfigService
    ) {}

    /**
     * Method that lists the remediations for a specific language
     * @param {RemediationLanguage} data The language data
     * @returns {Observable<any>} The remediations
     */
    public listRemediations(data: any): Observable<any> {
        const json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: 'get_remediations',
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                country: data.country,
                locale: data.language
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.privacyLidService,
            json,
            POST
        ).pipe(
            map((resp: any) => {
                if (resp?.result?.status === this.valuesService.requestStatuses.SUCCESS_STATUS) {
                    return resp.result;
                }
                throw resp;
            }),
            catchError((error) => {
                throw error;
            }),
        );
    }

    /**
     * Method that marks a step as useful or not useful
     * @param {MarkAsUsefulRemediationParams} data - the params to the request
     */
    public markRemediationStepUseful(data: MarkAsUsefulRemediationParams): Observable<any> {
        const json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: 'mark_remediation_step_useful',
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                ...data
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.privacyLidService,
            json,
            POST
        ).pipe(
            map((resp: any) => {
                if (resp?.result?.status === this.valuesService.requestStatuses.SUCCESS_STATUS) {
                    return resp.result;
                }
                throw resp;
            }),
            catchError((error) => {
                throw error;
            }),
        );
    }

     /**
     * Method that lists the services summary
     * @returns {Observable<any>} The services summary
     */
    public listServicesSummary(): Observable<any> {
        const json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: 'get_services_summary',
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                }
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.privacyLidService,
            json,
            POST
        ).pipe(
            map((resp: any) => {
                if (resp?.result?.status === this.valuesService.requestStatuses.SUCCESS_STATUS) {
                    return resp.result;
                }
                throw resp;
            }),
            catchError((error) => {
                throw error;
            }),
        );
    }
}
