import { Group, GroupMember } from './subscriptions/Groups.model';

export interface Placeholders {
    manufacturerName?: string;
    siteName?: string;
    applicationNameWebmailProtection?: string;
    applicationNameSecurity?: string;
    productNameWebmailProtection?: string;
    productNameProtection?: string;
}

interface Tooltip {
    textKey: string;
    placeholders: Placeholders;
}

export interface Placeholders {
    value?: string;
    manufacturerName?: string;
}

export interface VersionTag {
    label: string;
    color: string;
    tooltip: Tooltip;
}

export enum Drawers {
    SIDENAV = 'sidenav',
    NOTIFICATIONS = 'notifications',
    USER_DETAILS = 'userDetails',
    ALL = 'all'
}

export enum DrawerModes {
    OVER = 'over',
    SIDE = 'side'
}

export interface VersionTags {
    [key: string]: VersionTag;
}

export interface Icon {
    icon: string;
    type: string;
    color?: string;
}

export interface RequestConnectSource {
    device_id: string,
    user_token: string,
    app_id: string
}

export interface RequestExtraParams extends Group, GroupMember {
    accepted?: boolean
}

export interface TeenagerRoleMetadata {
    birthdate: number
};

export interface RequestParams extends RequestExtraParams {
    connect_source: RequestConnectSource
}

export interface RequestBody {
    id: number,
    jsonrpc: string,
    method: string,
    params: RequestParams
}

export enum BrowserBooleanValues {
    TRUE = 'true',
    FALSE = 'false'
}

export interface DefaultError {
    code: number;
    message: string;
}