// External
import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { catchError, map, skipWhile } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { tv4 } from 'tv4';

// Internal
import { ConnectUserInfoService } from '../../requests/connect-user-info-service/connect-user-info-service';
import { UtilsCommonService } from '../../../../common/utils/utils-common.service';
import { UsefulService } from '../../global/useful/useful.service';
import { ValuesService } from '../../../../common/values/values.service';
import { MessageService } from '../../core/message.service';
import { LanguageService } from '../../core/language.service';
import { AdobeDataLayerService } from '../../core/adobe.datalayer.service';
import { ParentalFilterType } from '../../../../common/models/parental-ncc/ParentalNcc.model';
import { ParentalNccValuesService } from '../../../../common/values/parental-ncc.values.service';
import { OrderValuesByNamePipe } from '../../../../common/pipes/pipes';
import { AccountOwnerInfo } from '../../guards/models/AccountInfo.model';
import {
    CreateGroupResponse,
    DefaultGroupLabelForFamily,
    Group,
    GroupRoles,
    GroupTypes
} from '../../../../common/models/subscriptions/Groups.model';
import { adminRoles, employeeRoles } from '../../../../common/values/business.values.service';
import { ConfigService } from '../../../../common/config/config.service';
import { QueryParamsService } from '../../core/query.params.service';

export interface Owner {
    firstname: string,
    email: string,
    profile_pic?: string,
    groups?: Array<Group>,
    current_context_id?: string
};
export interface Profile {
    profileType?: string;
    profile_id?: string,
    context_id?: string,
    first_name?: string,
    type?: string,
    birthday?: number,
    created?: number,
    profile_hash?: number,
    email?: string,
    is_owner?: boolean,
    profile_pic?: string
};

export const FamilyPlanAdminRoles = new Set([GroupRoles.FAMILY_PRIMARY_ADMIN, GroupRoles.FAMILY_SECONDARY_ADMIN]);
export const FamilyMemberRoles = new Set([GroupRoles.FAMILY_INDEPENDENT, GroupRoles.FAMILY_TEENAGER]);

@Injectable({
    providedIn: 'root'
})

export class ProfilesService {
    private currentGroup: Group;
    private thereAreValidGroups = false;
    private profiles: any = {};
    private owner: any = {
        groups: []
    };
    private markToUpdateProfiles = true;
    private markToUpdateOwner = true;
    private childrenAndTeenagers = {} as { [key: string]: Profile };
    private hasFamilyGroupFlag = false;

    private readonly onlistOwner$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private readonly onlistProfiles$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);

    constructor(
        private readonly connectUserInfoService: ConnectUserInfoService,
        private readonly utilsCommonService: UtilsCommonService,
        private readonly usefulService: UsefulService,
        private readonly cookieService: CookieService,
        private readonly messageService: MessageService,
        private readonly valuesService: ValuesService,
        private readonly languageService: LanguageService,
        private readonly adobeDataLayerService: AdobeDataLayerService,
        private readonly parentalNccValues: ParentalNccValuesService,
        private readonly orderValuesByName: OrderValuesByNamePipe,
        private readonly configService: ConfigService,
        private readonly queryParamsService: QueryParamsService
    ) {
        /* Used for updating the request in order to write them into th db by the service worker */
        this.messageService.getDynamicSubject(this.valuesService.events.resetRequestsForServiceWorker)
        .subscribe({
            next: () => {
                this.updateProfiles();
                this.updateOwner();
            }
        });
    }

    // add more filters if needed
    filters = {
        isChild: item => item.type === this.valuesService.profileTypes.CHILD,
        isTeenager: item => item.type === this.valuesService.profileTypes.TEENAGER,
        isOwner: item => this.usefulService.getNested(item, false, 'is_owner')
    };

    filterProfiles() {
        const parentalProfiles = {};
        const allProfiles = this.getAllProfiles();
        const auxChildrenAndTeenagers = {};

        if (!this.profiles.allObject) {
            this.profiles.allObject = {};
        }
        for (const profile of allProfiles) {

            // All profiles are under the current context for family groups
            // If the current group is a family group, we set the context_id to the current context_id
            if (this.currentGroupIsFamily()) {
                profile.context_id = this.getCurrentContextId();
            }

            if (this.filters.isChild(profile)) {
                auxChildrenAndTeenagers[profile.profile_id] = profile;
                profile.profileType = this.getProfileFilterType(profile);
                profile.first_name = this.usefulService.decodeHTMLEntities(this.usefulService.sanitizeValues(profile.first_name));
                const newParentalProfile = profile;
                const profileId = newParentalProfile?.profile_id;
                const idHash = this.usefulService.crc32Convert(profileId);
                newParentalProfile.profile_hash = idHash;
                parentalProfiles[idHash] = newParentalProfile;
            }
            if (this.filters.isOwner(profile)) {
                delete profile.email;
                this.updateOwnerInfo(profile);
            }

            if (this.filters.isTeenager(profile)) { // TODO de vazut daca se sterge de aici
                auxChildrenAndTeenagers[profile.profile_id] = profile;
            }
            this.profiles.allObject[profile.profile_id] = profile;
        }
        this.childrenAndTeenagers = auxChildrenAndTeenagers;
        this.setParentalProfiles(parentalProfiles);
    }

    /**
     * Returns the child or teenager profile by profile id
     * @param {string} profileId The profile id
     * @returns {Profile} The child or teenager profile
     */
    public getChidlOrTeenagerProfile(profileId: string): Profile {
        return this.childrenAndTeenagers[profileId];
    }

    list(): Observable<any> {
        if (!this.markToUpdateProfiles) {
            return of(this.profiles);
        }

        if (this.onlistProfiles$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onlistProfiles$.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );

        } else {
            this.onlistProfiles$.next(this.valuesService.processServiceState.INPROGRESS);
            return this.connectUserInfoService.profiles_list()
                .pipe(
                    map((resp: any) => {
                        if (resp) {
                            this.profiles.all = resp?.profiles ?? [];
                            this.filterProfiles();

                            this.messageService.sendMessage(this.valuesService.events.ownerChange, { id: 'val' });
                            this.messageService.sendDynamicSubjectMessage(this.valuesService.events.ownerChange, { id: 'val' });

                            this.markToUpdateProfiles = false;
                            this.onlistProfiles$.next(this.valuesService.processServiceState.DONE);
                        } else {
                            this.markToUpdateProfiles = true;
                            this.onlistProfiles$.next(this.valuesService.processServiceState.DONE);
                            return of('malformed_request');
                        }
                        return true;
                    }),
                    catchError(err => {
                        this.markToUpdateProfiles = true;
                        this.onlistProfiles$.next(this.valuesService.processServiceState.DONE);
                        throw err;
                    }
                ));
        }
    }

    /**
     * Adds owner info
     * @public
     * @memberof ProfilesService
     * @param {AccountOwnerInfo} ownerInfo Info from "profiles_list" owner profile or "getInfo" request
     */
    public addOwnerInfo(ownerInfo: AccountOwnerInfo): void {
        for (const prop in ownerInfo) {
            this.owner[prop] = ownerInfo[prop];
        }

        if (this.owner?.firstname) {
            this.owner.firstname = this.usefulService.sanitizeValues(this.usefulService.decodeHTMLEntities(this.owner.firstname));
        }

        this.adobeDataLayerService.setUser(this.owner);
    }

    /**
     * Updates owner info
     * @private
     * @memberof ProfilesService
     * @param {AccountOwnerInfo} ownerInfo The owner info to be updated
     */
    private updateOwnerInfo(ownerInfo: AccountOwnerInfo): void {
        // Check if the firstname was changed and sanitize it before updating the owner property
        if (ownerInfo?.firstname && ownerInfo.firstname !== this.owner.firstname) {
            ownerInfo.firstname = this.usefulService.sanitizeValues(this.usefulService.decodeHTMLEntities(ownerInfo.firstname));
        }

        for (const prop in ownerInfo) {
            this.owner[prop] = ownerInfo[prop];
        }
    }

    changeOwnerRelatedSettings(ownerInfo) {
        this.cookieService.set(this.valuesService.cookieEmail, ownerInfo.email, null, '/', "", true);
        this.cookieService.set(this.valuesService.cookieUser, ownerInfo.firstname ? ownerInfo.firstname : '', null, '/', "", true);

        //* If we do not support the language -> fallback to default
        if (this.valuesService.languages[ownerInfo.lang]) {
            this.languageService.set(ownerInfo.lang);
        } else {
            this.languageService.set(this.valuesService.defaultLanguage);
        }
    }

    listOwner(): Observable<any> {
        if (!this.markToUpdateOwner) {
            return of(this.profiles);
        }

        if (this.onlistOwner$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onlistOwner$.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );

        } else {
            this.onlistOwner$.next(this.valuesService.processServiceState.INPROGRESS);
            return this.connectUserInfoService.getInfoUser()
                .pipe(
                    map(resp => {
                        if (resp) {
                            this.addOwnerInfo(resp);
                            this.owner.groups = resp?.groups ?? [];
                            this.processGroups();
                            this.changeOwnerRelatedSettings(resp);
                            this.adobeDataLayerService.setUserContext(this.getCurrentGroupType());
                            this.configService.setBuyRights(this.configService.getOriginalBuyRights()
                                                            && !this.isOwnerVSBEmployee()
                                                            && !this.isOwnerTeenager());
                            this.configService.setBuyMoreSlotsRights(this.configService.getOriginalBuyMoreSlotsRights()
                                                                        && !this.currentGroupIsVSB()
                                                                        && !this.isOwnerTeenager());
                            this.configService.setActivateRedeemRights(this.configService.getOriginalActivateRedeemRights()
                                                                        && !this.isOwnerTeenager());
                            this.messageService.sendMessage(this.valuesService.events.ownerChange, { id: 'val' });
                            this.messageService.sendDynamicSubjectMessage(this.valuesService.events.ownerChange, { id: 'val' });

                            this.markToUpdateOwner = false;
                            this.onlistOwner$.next(this.valuesService.processServiceState.DONE);
                            return true;
                        } else {
                            this.adobeDataLayerService.setUserContext(this.getCurrentGroupType());
                            this.markToUpdateOwner = true;
                            this.onlistOwner$.next(this.valuesService.processServiceState.DONE);
                            return of('malformed_request');
                        }
                    }),
                    catchError(err => {
                        this.adobeDataLayerService.setUserContext(this.getCurrentGroupType());
                        this.markToUpdateOwner = true;
                        this.onlistOwner$.next(this.valuesService.processServiceState.DONE);
                        throw err;
                    }
                ));
        }
    }

    getOwner() {
        return this.owner;
    }

    /**
     * Gets the owner's first name after string processing
     * @public
     * @memberof ProfilesService
     * @returns {string} The owner's first name
     */
    public getOwnerFirstName(): string {
        return this.owner?.firstname;
    }

    getOwnerEmail() {
        return this.owner?.email;
    }

    /**
     * Gets the owner's profile id
     * @returns {string} The owner's profile id
     */
    public getOwnerProfileId(): string {
        return this.owner?.profile_id;
    }

    getOwnerFingerprint() {
        return this.owner?.fingerprint;
    }

    getOwnerType() {
        return this.owner?.type;
    }

    getOwnerProvider() {
        return this.owner?.provider_id;
    }

    getOwnerOrganizationLevel() {
        return this.owner?.organization_level;
    }

    getOwnerSubsVersion() {
        return this.owner?.subs_version;
    }

    hasMspOrXspLevelOne() {
        if (this.hasMspOrXspLevelTwo()) {
            return false;
        }
        if (this.getOwnerProvider()) {
            return true;
        }
        return false;
    }

    hasMspOrXspLevelTwo() {
        if (this.getOwnerProvider() && this.getOwnerOrganizationLevel() === this.valuesService.mspLevelTwo) {
            return true;
        }
        return false;
    }

    /**
     * Checks if the owner has MSP or XSP at any level
     * @returns {boolean} True if the owner has MSP or XSP at any level
     */
    public hasMspOrXspAnyLevel(): boolean {
        return !!(this.hasMspOrXspLevelOne() || this.hasMspOrXspLevelTwo());
    }

    ownerHasSubsV4() {
        const subsVersion = this.owner?.subs_version;
        return subsVersion === 4;
    }

    /**
     * Returns the status for owner's two_fa_enabled field
     * @returns {boolean} True if the owner has 2FA enabled, false in any other case
     */
    public hasTwoFaEnabled(): boolean {
        return !!this.owner?.two_fa_enabled;
    }

    /**
     * Computes the final path for all subscriptions v3 or subscriptions v4 subpaths. Example: '/subscriptions/services' or '/subscriptionsv3/services'
     * @param args Subscriptions path ids. Example:
     * getSubscriptionsPath(this.valuesService.centralPath.subscriptionsv3.paymentsid, this.valuesService.centralPath.subscriptionsv3.payments.details.id)
     * @returns Path to subscriptions
     */
    getSubscriptionsPath(...args: string[]) {
        const subscriptionsPaths = this.ownerHasSubsV4() ? this.valuesService.centralPaths.subscriptions : this.valuesService.centralPaths.subscriptionsv3;
        let finalPathObject = subscriptionsPaths;
        let finalPath = subscriptionsPaths.path;

        if (!args) {
            return subscriptionsPaths.path;
        }

        for (const path of args) {
            if (finalPathObject?.[path]?.path) {
                finalPath = finalPath.concat(finalPathObject[path].path);
                finalPathObject = finalPathObject[path];
            } else {
                finalPath = subscriptionsPaths.path;
                break;
            }
        }
        return finalPath ? finalPath : subscriptionsPaths.path;
    }

    /**
     * Return the path id for subs v3 or subs v4
     * @returns 'this.valuesService.centralPath.subscriptionsv3.id' or 'this.valuesService.centralPath.subscriptions.id'
     */
    getSubscriptionsId() {
        return this.ownerHasSubsV4() ? this.valuesService.centralPaths.subscriptions.id : this.valuesService.centralPaths.subscriptionsv3.id;
    }

    updateProfiles() {
        if (this.onlistProfiles$.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.markToUpdateProfiles = true;
        }
    }

    updateOwner() {
        if (this.onlistProfiles$.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.markToUpdateOwner = true;
        }
    }

    set(data) {
        if (this.utilsCommonService.checkArray(data)) {
            this.profiles = JSON.parse(JSON.stringify(data));
        } else {
            console.error('Invalid params', tv4.error);
        }
    }

    setOwnerEmail(email) {
        this.owner['email'] = email;
        this.cookieService.set(this.valuesService.cookieEmail, email, null, '/', "", true);
    }

    /**
     * Returns all existing profiles
     * @public
     * @memberof ProfilesService
     * @returns {Profile[]} The existing profiles array
     */
    public getAllProfiles(): Profile[] {
        return (this.profiles && this.profiles.all) ? this.profiles.all : [];
    }

    getParentalProfiles() {
        return (this.profiles && this.profiles.parental) ? this.profiles.parental : {};
    }

    public hasParentalProfiles(): boolean {
        const parentalProfiles = this.profiles?.parental ?? {};
        return !!Object.keys(parentalProfiles).length;
    }

    getProfileByProfileHash(profileHash) {
        return this.profiles?.parental?.[profileHash];
    }

    getAllObjectProfiles() {
        return (this.profiles && this.profiles.allObject) ? this.profiles.allObject : {};
    }

    getProfileById(profileId) {
        if (!profileId || !this.profiles.allObject) {
            return null;
        }

        try {
            profileId = profileId.toString();
            if (this.profiles.allObject[profileId]) {
                return this.profiles.allObject[profileId];
            }
            return null;
        } catch {
            return null;
        }
    }

    setParentalProfiles(profilesParental) {
        this.profiles.parental = profilesParental;
    }

    remove() {
        this.profiles = JSON.parse(JSON.stringify([]));
    }

    createProfile(profile) {
        return this.connectUserInfoService.profile_create(profile)
        .pipe(
            map(
                resp => {
                    if (!this.profiles?.all || !this.profiles?.all?.length) {
                        this.profiles.all = [];
                    }
                    profile.user_id = this.getCurrentContextId();
                    profile.profile_id = resp.profile_id;
                    this.profiles.all.push(profile);
                    this.filterProfiles();
                    return resp;
                }
            ),
            catchError(err => {
                throw err;
            })
        );
    }

    editProfile(profile) {
        return this.connectUserInfoService.profile_edit(profile)
        .pipe(
            map(resp => resp),
            catchError(err => {
                throw err;
            })
        );
    }

    editProfileParental(profile) {
        return this.connectUserInfoService.editProfile(profile)
        .pipe(
            map(
                resp => {
                    if (resp.status === 0) {
                        const profiles = this.profiles?.all ?? [];
                        for (let i = 0; i < profiles.length; i++) {
                            if (profiles[i].profile_id === profile.profile_id) {
                                profiles[i] = {...profiles[i], ...profile};
                            }
                        }
                        this.filterProfiles();
                        return resp;
                    } else {
                        throw resp;
                    }
                }
            ),
            catchError(err => {
                throw err;
            })
        );
    }

    removeProfile(profileId) {
        return this.connectUserInfoService.removeProfile(profileId)
        .pipe(
            map(
                resp => {
                    if (resp.status === 0) {
                        const newProfiles = [];
                        const profiles = this.profiles?.all ?? [];
                        for (const profile of profiles) {
                            if (profile.profile_id !== profileId) {
                                newProfiles.push(profile);
                            }
                        }
                        this.profiles.all = newProfiles;
                        this.filterProfiles();
                        return resp;
                    } else {
                        throw resp;
                    }
                }
            ),
            catchError(err => {
                throw err;
            })
        );
    }

    private getProfileFilterType(profile) {
        const MIN_YEAR_JS = 1970;
        const today = new Date();
        const difTimeStamp = +today.getTime() - +(profile.birthday * 1000);
        const age = new Date(difTimeStamp).getFullYear() - MIN_YEAR_JS;

        if (age < this.parentalNccValues.MAX_AGE_TODDLER) {
            profile.profileType = ParentalFilterType.toddler;
        } else if ( age < this.parentalNccValues.MAX_AGE_KID) {
            profile.profileType = ParentalFilterType.kid;
        } else {
            profile.profileType = ParentalFilterType.teen;
        }
        return profile.profileType;
    }

    /**
     * Function that replaces the child hash with the string 'child-<index>' where index is the child index
     * in parental header or dropdown from parental dashboard
     * @param url A url from parental. Ex.: '/parental/<id>/websites
     * @returns A url where child hash is replaced with 'child-<index>', index >= 1
     */
    public sanitizeUrlForParental(url) {
        let _pathSegments = url.split('/');
        for (let indexSection in _pathSegments) {
            if (!isNaN(parseInt(_pathSegments[indexSection], 10)) && _pathSegments[1] === this.valuesService.centralPaths.parental.id) {
                const profileIndex = this.getProfileIndex(_pathSegments[indexSection]);
                if (profileIndex) {
                    url = url.replace(_pathSegments[indexSection], 'child-'.concat(profileIndex.toString()));
                }
            }
        }
        return url;
    }

    /**
     * Computes he index a child profile has in parental header/dropdown
     * @param profileHash String
     * @returns The index, starting with 1, or 0 if the profile hash is not valid or there are no profiles or other errors
     */
    public getProfileIndex(profileHash) {
        const profiles = this.getParentalProfiles();
        if (!profiles) {
            return 0;
        }
        const orderedProfiles = Object.values(this.orderValuesByName.transform(profiles));
        if (!orderedProfiles?.length) {
            return 0;
        }
        for (let indexProfile = 0; indexProfile < orderedProfiles.length; indexProfile++) {
            if (profileHash === orderedProfiles[indexProfile]['key']) {
                return indexProfile + 1;
            }
        }
        return 0;
    }

    /**
     * Returns all groups
     * @public
     * @returns {Array}
     */
    public getGroups(): Array<Group> {
        return this.owner?.groups ?? [];
    }

    /**
     * Returns all groups that are not of type 'FAMILY'
     * @public
     * @memberof ProfilesService
     * @returns {Group[]} The existing non-family groups array
     */
    public getNonFamilyGroups(): Group[] {
        const nonFamilyGroups: Group[] = [];
        for (const group of this.getGroups()) {
            if (group.group_type !== GroupTypes.FAMILY) {
                nonFamilyGroups.push(group);
            }
        }
        return nonFamilyGroups;
    }

    /**
     * Get group label by context id
     * @param {string} contextId The context if of the group
     * @returns {string} The group label for given context id
     */
    public getGroupLabelByContextId(contextId: string): string {
        let groupLabel = '';
        for (const group of this.getGroups()) {
            if (group.context_id === contextId) {
                groupLabel = group?.group_label ?? '';
            }
        }
        return groupLabel;
    }

    /**
     * Returns the current context id.
     * @returns {string} The current context id
     */
    public getCurrentContextId(): string {
        return this.owner?.current_context_id ?? '';
    }

    /**
     * Saves the current context id.
     * @returns {string} The current context id
     */
    public setCurrentContextId(newContextId: string): void {
        this.owner.current_context_id = newContextId;
    }

    /**
     * Returns the primary context id.
     * @returns {string} The primary context id
     */
    public getPrimaryContextId(): string {
        return this.owner?.primary_context_id ?? '';
    }

    /**
     * Processes the groups of the owner
     */
    private processGroups() {
        let auxCurrentGroup = null;
        let auxThereAreValidGroups = false;
        const groups = this.getGroups();
        for (const group of groups) {
            if (group?.group_type === GroupTypes.FAMILY) {
                this.hasFamilyGroupFlag = true;
            }

            if (group.context_id) {
                auxThereAreValidGroups = true;
            }

            if (group.context_id === this.getCurrentContextId()) {
                auxCurrentGroup = group;
            }
        }
        this.thereAreValidGroups = auxThereAreValidGroups;
        this.currentGroup = auxCurrentGroup;
    }

    /**
     * Checks if the account has a family group
     * @returns {boolean} True if the owner has a family group
     */
    public hasFamilyGroup(): boolean {
        return this.hasFamilyGroupFlag;
    }


    /**
     * Adds the new group as a current group
     * @param {CreateGroupResponse} createGroupResponse The response from the create group request
     */
    public addFamilyGroup(createGroupResponse: CreateGroupResponse): void {
        this.hasFamilyGroupFlag = true;
        const familyGroup: Group = {
            context_id: this.getPrimaryContextId(),
            group_id: createGroupResponse.group_id,
            group_type: GroupTypes.FAMILY,
            role: GroupRoles.FAMILY_PRIMARY_ADMIN,
            invite_accepted: true,
            created: Date.now(),
            group_label: DefaultGroupLabelForFamily
        };
        this.owner.groups.push(familyGroup);

        if (this.owner?.primary_context_id === this.owner?.current_context_id) {
            this.currentGroup = familyGroup;
        }
    }

    /**
     * Checks if the owner has valid groups (the owner accepted an invite or bought a vsb subscription and created a business)
     * @returns {boolean} True if the owner has valid groups
     */
    public hasValidGroups(): boolean {
        return this.thereAreValidGroups;
    }

    /**
     * Returns the current group of the owner.
     * @returns {Group} The current group
     */
    public getCurrentGroup(): Group {
        return this.currentGroup;
    }

    /**
     * Returns the current group of the owner.
     * @returns {Group} The current group
     */
    public getCurrentGroupType(): GroupTypes|null {
        return this.currentGroup?.group_type;
    }

    /**
     * Checks if the current group is a VSB group
     * @returns {boolean} True if the current group is a VSB group
     */
    public currentGroupIsVSB(): boolean {
        return this.currentGroup?.group_type === GroupTypes.VSB;
    }

    /**
     * Checks if the current group is a ATO group
     * @returns {boolean} Returns true if the current group is a ATO group, false otherwise
     */
    public currentGroupIsATO(): boolean {
        return this.currentGroup?.group_type === GroupTypes.ATO;
    }

    /**
     * Checks if the current group is a Family group
     * @public
     * @memberof ProfilesService
     * @returns {boolean} Returns true if the current group is a Family group, false otherwise
     */
    public currentGroupIsFamily(): boolean {
        return this.currentGroup?.group_type === GroupTypes.FAMILY;
    }

    /**
     * Returns the current group of the owner.
     * @returns {Group} The current group
     */
    public getCurrentGroupLabel(): string {
        return this.currentGroup?.group_label ?? '';
    }

    /**
     * Returns the current group of the owner.
     * @returns {Group} The current group
     */
    public getCurrentGroupId(): string|null {
        return this.currentGroup?.group_id;
    }

    /**
     * Returns the current group of the owner.
     * @returns {boolean} True if the owner is a VSB admin
     */
    public isOwnerVSBAdmin() {
        return adminRoles.has(this.currentGroup?.role);
    }

    /**
     * Checks if the current group is ATO and the owner is a primary admin
     * @returns {boolean} Returns true if the owner is a ATO admin, false otherwise
     */
    public isOwnerATOAdmin(): boolean {
        return this.currentGroup?.role === GroupRoles.CCP_PRIMARY_ADMIN;
    }

    /**
     * Checks if the owner is a VSB employee
     * @returns {boolean} True if the owner is a VSB employee
     */
    public isOwnerVSBEmployee() {
        return employeeRoles.has(this.currentGroup?.role);
    }

    /**
     * Checks if the accout owner is a family member and not a family admin
     * @returns {boolean} Returns true if he is a only family member, false otherwise
     */
    public isOnlyFamilyMember(): boolean {
        return FamilyMemberRoles.has(this.currentGroup?.role);
    }

    /**
     * Checks if the owner is a teenager family member.
     * @returns {boolean} Returns true if the owner is a teenager family member, false otherwise
     */
    public isOwnerTeenager(): boolean {
        return this.currentGroup?.role === GroupRoles.FAMILY_TEENAGER;
    }

    /**
     * Checks if the owner is a VSB basic employee (no security admin)
     * @returns {boolean} True if the owner is a VSB basic employee
     */
    public isOwnerVSBBasicEmployee(): boolean {
        return this.currentGroup?.role === GroupRoles.VSB_EMPLOYEE;
    }

    /**
     * Checks if the owner is a VSB primary admin
     * @returns {boolean} True if the owner is a VSB primary admin
     */
    public isOwnerVSBPrimaryAdmin() {
        return this.currentGroup?.role === GroupRoles.VSB_PRIMARY_ADMIN;
    }

    /**
     * Checks if the current group role is for the Family Plan Admin
     * @public
     * @memberof ProfilesService
     * @returns {boolean} Returns true if it is a family plan admin, false otherwise
     */
    public isFamilyPlanAdmin(): boolean {
        return FamilyPlanAdminRoles.has(this.currentGroup?.role);
    }

    /**
     * Checks if the current group role is for the Family Secondary Admin
     * @public
     * @memberof ProfilesService
     * @returns {boolean} Returns true if it is a family secondary admin, false otherwise
     */
    public isFamilySecondaryAdmin(): boolean {
        return this.currentGroup?.role === GroupRoles.FAMILY_SECONDARY_ADMIN;
    }

    /**
     * Method that checks if the contextId is on the user profile
     * @param {string} contextId the context id to be checked
     * @returns {boolean} True if the context is on the user profile
     */
    public checkIfContextIsOnUserProfile(contextId: string): boolean {
        if (contextId === '') {
            return false;
        }

        let contextIsInGroups = false;
        for (const group of this.getGroups()) {
            if (group.context_id === contextId) {
                contextIsInGroups = true;
                break;
            }
        }
        return contextIsInGroups || this.getPrimaryContextId() === contextId;
    }

    /**
     * Method that checks if the context can be switched
     * (no service, modal, activation, paymentStatus, referral or referralCode in query params)
     * @returns {boolean} if the context can be switched
     */
    public canSwitchContext(): boolean {
        return !this.queryParamsService.get(this.valuesService.queryParams.service)
            && !this.queryParamsService.get(this.valuesService.queryParams.modal)
            && !this.queryParamsService.get(this.valuesService.queryParams.activation)
            && !this.queryParamsService.get(this.valuesService.queryParams.paymentStatus)
            && !this.queryParamsService.get(this.valuesService.queryParams.referral)
            && !this.queryParamsService.get(this.valuesService.queryParams.referralCode);
    }

     /**
     * Computes the profile type for family profiles
     * @public
     * @memberof ProfilesService
     * @param {string} type The type of the profile
     * @returns {string} The computed profile type
     */
     public computeProfileTypeForFamily(type: string): string {
        if (this.valuesService.profileTypesForFamily.has(type)) {
            return type;
        }
        return this.valuesService.profileTypes.TEENAGER;
    }

}
