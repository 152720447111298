export enum WhatIsNewModalActions {
    INSTALL_ANOTHER_DEVICE = 'installOnAnotherDevice',
    PASSWORD_MANAGER = 'configurePasswordManager',
    CLOSE = 'close'
}

export enum WhatIsNewModalDataKeys {
    LOCAL_PROTECTION = 'localProtection',
    PASSWORD_MANAGER = 'passwordManager',
    PROTECT_MORE_DEVICES = 'protectMoreDevices',
    ROLES = 'roles',
    MULTIPLATFORM = 'multiplatform',
    CRYPTOMINING_PROTECTION = 'cryptominingProtection',
    DEVICE_OPTIMIZER = 'deviceOptimizer',
    ADDITIONAL_FEATURES_INDIVIDUAL = 'additionalFeaturesIndividual',
    ADDITIONAL_FEATURES_FAMILY = 'additionalFeaturesFamily',
    EXTRA_BENEFITS = 'extraBenefits',
    EXTRA_BENEFIT = 'extraBenefit',
    PORTOFOLIO = 'portofolio',
    ANTIVIRUS = 'antivirus',
}

export type Icon = {
    type: string;
    value: string;
}

export type WhatIsNewModalIcons = {
    [key: string]: Icon;
}

export type WhatIsNewModalDataList = {
    [key: string]: string;
}

export type WhatIsNewBenefit = {
    icon: Icon;
    title: string;
    description: string;
    show?: (params: any) => boolean;
}

export type WhatIsNewModalButtonLabels = {
    [key in WhatIsNewModalActions]: string;
}

export interface WhatIsNewModalActionDetails {
    action: Function;
}

export type WhatIsNewModalActionsDetails = {
    [key in WhatIsNewModalActions]: Function;
}

export type WhatIsNewButton = {
    label: string;
    action: string;
    iconLeft?: Icon;
    iconRight?: Icon;
}

export type WhatIsNewButtons = {
    [key in WhatIsNewModalActions]: WhatIsNewButton
}

export type WhatIsNewScenarioDetails = {
    mandatoryParams?: Array<string>;
    optionalParams?: Array<string>;
    newBundleName?: string;
    oldBundleName?: string;
    benefits: Array<WhatIsNewBenefit>;
    description: (param?: any) => string;
    description2?: string;
    button: (params?: any) => WhatIsNewButton;
}

export type WhatIsNewScenariosDetails = {
    [key: string]: {
        [key: string]: WhatIsNewScenarioDetails;
    };
}