import { IRemediationAction, IUserRemediationsTypes, RemediationMethodDetails, RemediationServiceDetails } from './Remediation.model';
import { IExposureIssue } from './PrivacyIssue.model';
import { ServiceStatusEnum } from './PrivacyFootprint.model';
import { MetadataPhoneType, MetadataType, Metadata } from './Identity.model';


export class PrivacyExposure {
    //name
    first: string;
    middle: string;
    last: string;

    //addresses
    country: string;
    state: string;
    city: string;
    street: string;
    house: string;
    apartment: string;
    zip_code: string;
    po_box: string;

    //phones
    country_code: number;
    number: number;
    display_international: string;
    subtype: MetadataPhoneType;

    //emails
    _type: string; //! poate trebuie ExposurePhoneType??
    address: string;

    //usernames
    content: string;

    //urls
    _category: string; //! poate trebuie enum dar nu stim valorile
    _domain: string;
    _name: string;
    url: string;
    url_status: ExposureSafety;

    //jobs
    title: string;
    organization: string;
    industry: string;
    date_range: ExposureDateRange;

    //educations
    degree: string;
    school: string;
    //date_range

    //images
    //url
    thumbnail_token: string;

    //gender
    //content

    //dob = date of birth

    //common
    issue_id: string;
    display: string;
    info_id: string;
    info_type: MetadataType;
    info_subtype: MetadataPhoneType;
    confirmation_status: ConfirmationStatusLid;
    sources: ExposureSource[];
    updated: string;
    created: string;
    new_until;

    //extra
    specialDisplay: string;
    new_label = false;
    interface = {};
    skipped = false;
    isValidated = false;
    hasError: boolean;
    alreadyValidated: boolean;
    validationInProgress: boolean;
    undoInProgress: boolean;

    //info
    info: IExposureInfo[];
}

export interface IExposureInfo {
    source: string;
    service_info: IExposureServiceInfo;
}
export class PrivacyExposureCouter {
    names: number;
    emails: number;
    usernames: number;
    addresses: number;
    phones: number;
    user_ids: number;
    gender: number;
    dob: number;
    jobs: number;
    images: number;
    educations: number;
    urls: number;

    constructor() {
        this.names = 0;
        this.emails = 0;
        this.usernames = 0;
        this.addresses = 0;
        this.phones = 0;
        this.user_ids = 0;
        this.gender = 0;
        this.dob = 0;
        this.jobs = 0;
        this.images = 0;
        this.educations = 0;
        this.urls = 0;
    }
}

export enum PaginationStatus {
    DIGITAL_FOOTPRINT = 'category',
    DASHBOARD = 'global'
}

export enum ConfirmationStatusLid {
    CONFIRMED = "confirmed",
    REJECTED = "rejected",
    NEW = "new"
}

export enum ExposurePhoneType {
    PERSONAL = "personal",
    WORK = "work"
}

export enum GenderType {
    MALE = "male",
    FEMALE = "female"
}

export enum ExposureSafety {
    SAFE = "safe",
    UNSAFE = "unsafe"
}
export class ExposureSource {
    name: string;
    url: string;
    category: string;
    updated: string;
    created: string;
    new_until;

    //extra
    remediation: RemediationServiceDetails;
    reportedService = false;
    new_label = false;
}

export class ExposureDateRange {
    start: string;
    end: string;
}

export class PrivacyExposureIdentity {
    [key: string]: PrivacyExposure[];
}

export interface IExposureGrouppedByService {
    service_info: IExposureServiceInfo;
    issues: Array<IExposureIssue>;
}

export interface IExposureServiceInfo {
    service_id: string;
    domain?: string;
    logo?: boolean | string;
    name?: string;
    hasFootprint?: boolean;
    footPrintText?: string;
    isNew?: boolean;
}

export interface IDomainServiceDetails {
    actions: IRemediationAction;
    categories?: Array<string>,
    category: string;
    created_at: Date;
    description: string;
    domain: string;
    homepage_url: string;
    is_deprecated: boolean;
    links: Array<IDomainServiceLinks>;
    name: string;
    provider: string;
    service_id: string;
    service_crc: number;
    updated_at: Date;
    logo?: string;
    hasFootprint?: boolean;
    lastExposureDate?: string;
    lastBreachDate?: string;
    hasMultipleCategories?: boolean;
    categoriesTranslations?: Array<string>;
    domains?: Array<string>;
    extraCategoriesCount?: number;
    potentialCategories?: Array<IPotentialCategory>;
    in_use: boolean;
    last_used?: Date;
    new_until?: Date;
    new?: boolean;
    parked?: boolean;
    remediations?: Array<RemediationMethodDetails>;
    user_remediations?: IUserRemediationsTypes;
    breaches: Array<IDomainServiceBreaches>;
    metadatas?: Array<IDomainServiceMedatada|string|string>;
    requestSent?: boolean;
    deleted?: boolean;
    discoveredEmail?: Metadata;
    status: Array<ServiceStatusEnum>;
    mainStatus?: ServiceStatusEnum;
}

export interface IDomainServiceMedatada {
    discovered_at: string;
    last_seen: string;
    metadata_id: string;
    value?: string;
    status: Array<ServiceStatusEnum>;
    mainStatus: ServiceStatusEnum;
}

export interface IPotentialCategory {
    category: string;
    icon: string;
    name: string;
    description: string;
}

export interface IDomainServiceBreaches {
    breach_id: string;
    occurred_at: string;
    title: string;
    isExposed: boolean;
    data_classes: Array<string>;
    total_count: number;
    breach_details: any;
}

interface IDomainServiceLinks {
    text: string;
    type: string;
    url: string;
}

export interface IServiceBreachesImpacting {
    breach_id:string;
    issue_id:string;
    type: string;
    breach_details: IServiceBreachesImpactingDetails;
}

interface IServiceBreachesImpactingDetails {
    occurred_at: string;
    title: string;
    total_count: number;
}