// External
import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { catchError, map, skipWhile } from 'rxjs/operators';

// Internal
import { ValuesService } from '../../../values/values.service';
import { ConnectMgmtService } from '../../requests/connect-mgmt-service/connect-mgmt.service';
import { AppConfigurationStatus } from '../../../models/Services.model';
import { MessageService } from '../../core/message.service';

@Injectable({
    providedIn: 'root'
})

export class PasswordManagerService {

    private readonly onListSaferpassStatus$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private markToUpdateSaferpassStatus = true;

    private readonly onListLokiMasterPasswordStatus$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private markToUpdateLokiMasterPasswordStatus = true;

    private saferpassUserRegistered: boolean = false;
    private appConfigurationStatus: AppConfigurationStatus = AppConfigurationStatus.PENDING;
    private lokiMasterPassworsStatus: boolean = false;
    private securePassAppConfigurationStatus: AppConfigurationStatus = AppConfigurationStatus.PENDING;

    constructor(
        private readonly valuesService: ValuesService,
        private readonly connectMgmtService: ConnectMgmtService,
        private readonly messageService: MessageService
    ) {
        /* Used for updating the request in order to write them into th db by the service worker */
        this.messageService.getDynamicSubject(this.valuesService.events.resetRequestsForServiceWorker)
        .subscribe({
            next: () => {
                this.updateSaferpassStatus();
            }
        });
    }

    /**
     * List Saferpass Status for Password Manager
     *
     * @public
     * @return {*}  {Observable<any>}
     * @memberof PasswordManagerService
     */
    public listSaferpassStatus(): Observable<any> {
        if (!this.markToUpdateSaferpassStatus){
            return of(this.saferpassUserRegistered);
        }

        if (this.onListSaferpassStatus$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onListSaferpassStatus$.asObservable()
            .pipe(
                skipWhile(res => res !== this.valuesService.processServiceState.DONE)
            );
        } else {
            this.onListSaferpassStatus$.next(this.valuesService.processServiceState.INPROGRESS);
            return this.connectMgmtService.listSaferpassStatus()
            .pipe(
                map(resp => {
                    this.saferpassUserRegistered = resp.user_registered;
                    this.appConfigurationStatus = resp.user_registered ? AppConfigurationStatus.CONFIGURED : AppConfigurationStatus.NOT_CONFIGURED;
                    this.messageService.sendDynamicSubjectMessage(this.valuesService.events.saferpassStatusChanged, {});
                    this.markToUpdateSaferpassStatus = false;
                    this.onListSaferpassStatus$.next(this.valuesService.processServiceState.DONE);
                    return true;
                }),
                catchError(_err => {
                    this.saferpassUserRegistered = false;
                    this.appConfigurationStatus = AppConfigurationStatus.NOT_CONFIGURED
                    this.markToUpdateSaferpassStatus = true;
                    this.onListSaferpassStatus$.next(this.valuesService.processServiceState.DONE);
                    return of(false);
                }),
            );
        }
    }

    /**
     * List Loki Master Password Status for Secure Pass
     *
     * @public
     * @return {*}  {Observable<any>}
     * @memberof PasswordManagerService
     */
    public listLokiMasterPasswordStatus(): Observable<any> {
        if (!this.markToUpdateLokiMasterPasswordStatus){
            return of(this.lokiMasterPassworsStatus);
        }

        if (this.onListLokiMasterPasswordStatus$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onListLokiMasterPasswordStatus$.asObservable()
            .pipe(
                skipWhile(res => res !== this.valuesService.processServiceState.DONE)
            );
        } else {
            this.onListLokiMasterPasswordStatus$.next(this.valuesService.processServiceState.INPROGRESS);
            return this.connectMgmtService.listLokiMasterPasswordStatus()
            .pipe(
                map(resp => {
                    this.lokiMasterPassworsStatus = resp.securepass_mp_active;
                    this.securePassAppConfigurationStatus = resp.securepass_mp_active ? AppConfigurationStatus.CONFIGURED : AppConfigurationStatus.NOT_CONFIGURED;
                    this.messageService.sendDynamicSubjectMessage(this.valuesService.events.lokiMasterPasswordStatusChanged, {});
                    this.markToUpdateLokiMasterPasswordStatus = false;
                    this.onListLokiMasterPasswordStatus$.next(this.valuesService.processServiceState.DONE);
                    return true;
                }),
                catchError(err => {
                    this.markToUpdateLokiMasterPasswordStatus = true;
                    this.securePassAppConfigurationStatus = AppConfigurationStatus.NOT_CONFIGURED;
                    this.onListLokiMasterPasswordStatus$.next(this.valuesService.processServiceState.DONE);
                    return of(err);
                })
            );
        }
    }

    /**
     * Method used for updating the saferpass status request.
     */
    public updateSaferpassStatus(): void {
        if (this.onListSaferpassStatus$.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.markToUpdateSaferpassStatus = true;
        }
    }

    /**
     * Get Saferpass Status for Password Manager*
     * @public
     * @memberof PasswordManagerService
     * @return {boolean} the status of user_registered, true if user is registered (has master passowrd set) false otherwise
     */
    public getSaferpassUserRegistered(): boolean {
        return this.saferpassUserRegistered;
    }

    /**
     * Get Configuration status for Password Manager App
     * @public
     * @memberof PasswordManagerService
     * @return {AppConfigurationStatus} CONFIGURED if user is registered (has master passowrd set) NOT_CONFIGURED otherwise
     */
    public getConfigurationStatus(): AppConfigurationStatus {
        return this.appConfigurationStatus;
    }

    /**
     * Get Loki Master Password Status for Secure Pass *
     * @public
     * @memberof PasswordManagerService
     * @return {boolean} the status of securepass_mp_active, true if user is registered (has master passowrd set) false otherwise
     */
    public getSecurePassUserRegistered(): boolean {
        return this.lokiMasterPassworsStatus;
    }

    /**
     * Get Configuration status for Password Manager App
     * @public
     * @memberof PasswordManagerService
     * @return {AppConfigurationStatus} CONFIGURED if user is registered (has master passowrd set) NOT_CONFIGURED otherwise
     */
    public getSecurePassConfigurationStatus(): AppConfigurationStatus {
        return this.securePassAppConfigurationStatus;
    }
}
