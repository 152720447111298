import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable, map, catchError, mergeMap, of } from 'rxjs';

import { ConnectLoginService } from '../../requests/connect-login-service/connect-login.service';
import { LogoutType } from '../../../../common/config/config.service';
import { AuthService } from '../auth/auth-service.service';
import { RequestsService } from '../request-service/requests.service';
import { ValuesService } from '../../../values/values.service';

export interface SwitchContextResponse {
    switched?: boolean;
};

export interface AckInviteResponse {
    context_id?: string;
};

export interface AckInviteResponseWrapper extends AckInviteResponse, SwitchContextResponse {};

@Injectable({
    providedIn: 'root'
})
export class ContextService {
    private reloadAfterContextSwitchFlag = false;
    //avem nevoie de o a 2a variabila pt a nu intrerupe flowurile de activare/onboarding
    private reloadAfterContextSwitchWithSavedContextFlag = false;
    private activeContextId: string = null;

    constructor(
        private readonly connectLoginService: ConnectLoginService,
        private readonly requestsService: RequestsService,
        private readonly authService: AuthService,
        private readonly cookieService: CookieService,
        private readonly valuesService: ValuesService
    ) {}

    /**
     * This method is used to change constext.
     * @param {string} newContextId - The new context id.
     * @returns {Observable<any>} The request response.
     */
    public switchContext(newContextId: string, switchToLastAccessedContext: boolean = false): Observable<SwitchContextResponse> {
        const switchContextResponse: SwitchContextResponse = {
            switched: false
        };

        return this.connectLoginService.switchContext(newContextId)
        .pipe(
            mergeMap(userToken => {
                switchContextResponse.switched = true;
                this.reloadAfterContextSwitchFlag = true;
                this.reloadAfterContextSwitchWithSavedContextFlag = switchToLastAccessedContext;
                this.setActiveContextId(newContextId);
                return this.requestsService.logout(LogoutType.NO_REDIRECT)
                .pipe(
                    map(() => {
                        this.authService.writeUserTokenCookie(userToken);
                        return switchContextResponse;
                    }),
                    catchError(() => {
                        this.authService.writeUserTokenCookie(userToken);
                        return of(switchContextResponse);
                    })
                );
            }),
            catchError(() => {
                return of(switchContextResponse);
            })
        );
    }

    /**
     * This method is used to see if the window has a pending reload after a context switch
     * @returns {boolean} true if window was not reloaded yet and needs to be
     */
    public getReloadAfterContextSwitchFlag(): boolean {
        return this.reloadAfterContextSwitchFlag;
    }

    /**
     * This method is used to see if the window has a pending reload after a context switch with saved context
     * @returns {boolean} true if window was not reloaded yet and needs to be
     */
    public getReloadAfterContextSwitchWithSavedContextFlag(): boolean {
        return this.reloadAfterContextSwitchWithSavedContextFlag;
    }

    /**
     * Method that sets active context id
     * @param {string} contextId the context id to set as active
     * @returns {void}
     */
    public setActiveContextId(contextId: string): void {
        this.activeContextId = contextId;
        this.cookieService.set(this.valuesService.cookieContext, contextId, null, '/', '', true);
    }

    /**
     * Method that gets the active context id
     * @returns {string} the active context id
     */
    public getActiveContextId(): string {
        return this.activeContextId;
    }

}