// External
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, ReplaySubject, Subject, takeUntil } from 'rxjs';

// Internl
import { ValuesService } from '../../values/values.service';
import { LanguageService } from './language.service';
import { SubscriptionsSdkErrors } from '../../values/subscriptions.values.service';
import { CheckoutSdkBuildSubjectTypes, CheckoutSdkConfig, LoadSdkScriptStatus, ProductDataNeededBySdk } from '../../models/CheckoutSdk.model';
import { DeployType, Hosts } from '../../config/config.service';
import { Errors } from '../global/request-service/requests.service';
import { BillingDetails } from '../../models/subscriptions/BillingDetails.model';
import { ScriptService } from './scripts.service';
import { Scripts } from './models/Scripts.model';

declare const BitCheckoutSDK: any;

@Injectable({
    providedIn: 'root'
})

export class CheckoutSdkService {
    private readonly onDestroy$: Subject<void> = new Subject<void>();

    private readonly availablePaymentMethods = new ReplaySubject<any>();
    private readonly accountBillingDetails = new ReplaySubject<any>();

    private readonly alreadyBuilt$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly alreadyBuiltAR$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    private readonly checkoutSdkBuildSubjects = {
        [CheckoutSdkBuildSubjectTypes.AUTO_RENEWAL]: this.alreadyBuiltAR$,
        [CheckoutSdkBuildSubjectTypes.DEFAULT]: this.alreadyBuilt$
    };

    private config: CheckoutSdkConfig;
    private sdkTriedToBuild = false;
    private sdkBuilt = false;
    private billingDetails: BillingDetails;

    constructor(
        private readonly scriptService: ScriptService,
        private readonly valuesService: ValuesService,
        private readonly languageService: LanguageService
    ) {}

    /**
     * Gets the public key ysed in the default config object
     * @private
     * @memberof CheckoutSdkService
     * @returns {string} The key to be used
     */
    private sdkPublicKey(): string {
        let publicKey = '';
        const hostname = window?.location?.hostname;

        if (window?.['_glCnfg']?.type === 311) {
            if (this.valuesService.checkoutSDKMapping?.[hostname]?.[DeployType.BETA]?.public_key) {
                publicKey = this.valuesService.checkoutSDKMapping?.[hostname]?.[DeployType.BETA]?.public_key;
            } else {
                publicKey = this.valuesService.checkoutSDKMapping?.[Hosts.DEFAULT]?.[DeployType.BETA]?.public_key;
            }
        } else {
            if (this.valuesService.checkoutSDKMapping?.[hostname]?.[DeployType.PROD]?.public_key) {
                publicKey = this.valuesService.checkoutSDKMapping?.[hostname]?.[DeployType.PROD]?.public_key;
            } else {
                publicKey = this.valuesService.checkoutSDKMapping?.[Hosts.DEFAULT]?.[DeployType.PROD]?.public_key;
            }
        }
        return publicKey;
    }

    /**
     * Gets the public key used in the AR config object
     * @private
     * @memberof CheckoutSdkService
     * @returns {string} The key to be used
     */
    private sdkPublicKeyAR(): string {
        let publicKeyAR = '';
        const hostname = window?.location?.hostname;

        if (window?.['_glCnfg']?.type === 311) {
            if (this.valuesService.checkoutSDKMappingAR?.[hostname]?.[DeployType.BETA]?.public_key) {
                publicKeyAR = this.valuesService.checkoutSDKMappingAR?.[hostname]?.[DeployType.BETA]?.public_key;
            } else {
                publicKeyAR = this.valuesService.checkoutSDKMappingAR?.[Hosts.DEFAULT]?.[DeployType.BETA]?.public_key;
            }
        } else {
            if (this.valuesService.checkoutSDKMappingAR?.[hostname]?.[DeployType.PROD]?.public_key) {
                publicKeyAR = this.valuesService.checkoutSDKMappingAR?.[hostname]?.[DeployType.PROD]?.public_key;
            } else {
                publicKeyAR = this.valuesService.checkoutSDKMappingAR?.[Hosts.DEFAULT]?.[DeployType.PROD]?.public_key;
            }
        }
        return publicKeyAR;
    }

    /**
    * Initialize the config object used for building the SDK
    * @private
    * @memberof CheckoutSdkService
    * @param {boolean} isDefaultConfig Flag that indicates if the configuration is for Zuora
    * @param {boolean} disableNewSession - optional; Flag that indicates if new session is required for SDK config
    */
   private initializeConfig(isDefaultConfig: boolean, disableNewSession = false): void {
       this.config = {
           key: isDefaultConfig ? this.sdkPublicKey() : this.sdkPublicKeyAR(),
           language: this.languageService.getLang(),
           debug: false,
           request_timeout: 10000,
           element: 'checkout_sdk',
           default_scenario: 'central.checkout.v1',
           update_analytics_enabled: false,
           disable_auto_generated_new_session: disableNewSession,
           return_url: 'https://v2.central.bitdefender.com'
       };

        if (isDefaultConfig) {
            this.config.country = 'NL';
            return;
        }

        const country = this.languageService.getCountry();
        if (country) {
            this.config.country = country.toUpperCase();
        }
   }

    /**
     * Reinitializes the config object and re-builds the SDK based on the new configuration
     * @private
     * @memberof CheckoutSdkService
     * @param {string} behaviorSubject The flow used for building the SDK script: autoRenewal/default
     * @param {boolean} isDefaultConfig The flag that indicates if the configuration is for Zuora
     * @param {boolean} disableNewSession - optional; Flag that indicates if new session is required for SDK config
     * @param {boolean} skipCleanningStorage - optional; Flag that indicates if the CHECKOUT_SESSION_KEY should be cleanned or not
     * @returns {Observable} The response from SDK
     */
    private build(behaviorSubject: string, isDefaultConfig: boolean, disableNewSession = false, skipCleanningStorage = false): Observable<any> {
        const checkoutSession = window['CHECKOUT_SESSION_KEY_NAME'];
        if (!skipCleanningStorage && checkoutSession) {
            localStorage.removeItem(checkoutSession);
        }

        this.initializeConfig(isDefaultConfig, disableNewSession);

        return new Observable(subscriber => {
            BitCheckoutSDK.build(
                () => {
                    this.sdkBuilt = true;
                    this.sdkTriedToBuild = true;
                    this.checkoutSdkBuildSubjects[behaviorSubject].next(true);
                    subscriber.next();
                    subscriber.complete();
                },
                this.config,
                () => {
                    this.sdkBuilt = false;
                    this.sdkTriedToBuild = true;
                    this.checkoutSdkBuildSubjects[behaviorSubject].next(false);
                    subscriber.error();
                    subscriber.complete();
                }
            );
        });
    }

    /**
     * Initializes the config object and builds the SDK
     * @public
     * @memberof CheckoutSdkService
     * @returns {Observable} The response from SDK
     */
    public newBuild(): Observable<any> {
        this.initializeConfig(true);
        return new Observable(subscriber => {
            BitCheckoutSDK.build(
                () => {
                    this.sdkTriedToBuild = true;
                    this.alreadyBuilt$.next(true);
                    this.sdkBuilt = true;
                    subscriber.next();
                    subscriber.complete();
                },
                this.config,
                () => {
                    this.sdkTriedToBuild = true;
                    this.alreadyBuilt$.next(false);
                    this.sdkBuilt = false;
                    subscriber.next();
                    subscriber.complete();
                }
            );
        });
    }

    /**
     * Resets all behavior subjects used for sdk
     * Must be used when several builts are made with different configs and behavior subjects
     * @public
     * @memberof CheckoutSdkService
     */
    public resetBuild(): void {
        this.onDestroy$.next();
        this.alreadyBuilt$.next(false);
        this.alreadyBuiltAR$.next(false);
    }

    /**
     * Gets the flag that tells if the sdk at least tried to build, not if the sdk actually did build
     * @public
     * @memberof CheckoutSdkService
     * @returns {boolean} The value of the flag
     */
    public getSdkTriedToBuild(): boolean {
        return this.sdkTriedToBuild;
    }

    /**
     * Renders the SDK flow
     * @public
     * @memberof CheckoutSdkService
     * @param {string} flow The SDK flow
     * @param {object} params The data sent to SDK in order to pre-fill info
     * @param {boolean} forceRefreshDomWrapper If set to true it will refresh the wrapper
     * @returns {Observable} Default error if SDK script is not loaded, void otherwise
     */
    public render(flow: string, params: object, forceRefreshDomWrapper: boolean): Observable<any> {
        return new Observable(subscriber => {
            if (this.sdkBuilt) {
                BitCheckoutSDK.render(flow, params, forceRefreshDomWrapper);
                subscriber.next();
                subscriber.complete();
            } else {
                subscriber.next(SubscriptionsSdkErrors.DEFAULT_ERROR);
            }
        });
    }

    /**
     * Adds a new product to SDK
     * @public
     * @memberof CheckoutSdkService
     * @param {string} behaviorSubject The flow used for building the SDK script: autoRenewal/default
     * @param {ProductDataNeededBySdk} data The product details
     * @returns {Observable} The response from sdk
    */
    public addNewProduct(behaviorSubject: string, data: ProductDataNeededBySdk): Observable<any> {
        const newProduct = new Subject<any>();

        this.checkoutSdkBuildSubjects[behaviorSubject]
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(resp => {
            if (resp) {
                BitCheckoutSDK.addNewProduct(
                    data,
                    _success => {
                        newProduct.next(resp);
                    },
                    _error => {
                        newProduct.next(Errors.ERROR);
                    }
                );
            }
        });
        return newProduct.asObservable();
    }

    /**
     * Gets stored payment method from SDK
     * @public
     * @memberof CheckoutSdkService
     * @returns {Observable} Default error if SDK script is not loaded, the request response otherwise
     */
    public getStoredPaymentMethod(): Observable<any> {
        return new Observable(subscriber => {
            if (this.sdkBuilt) {
                BitCheckoutSDK.getStoredPaymentMethod(
                    data => {
                        subscriber.next(data);
                    },
                    error => {
                        subscriber.next(error);
                    }
                );
            } else {
                subscriber.next(SubscriptionsSdkErrors.DEFAULT_ERROR);
            }
        });
    }

    /**
     * Gets available payment methods from SDK
     * @public
     * @memberof CheckoutSdkService
     * @returns {Observable} The response from SDK
     */
    public getAvailablePaymentMethods(): Observable<any> {
        this.alreadyBuilt$
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(resp => {
            if (resp) {
                BitCheckoutSDK.getAvailablePaymentMethods(
                    data => {
                        this.availablePaymentMethods.next(data);
                    },
                    error => {
                        this.availablePaymentMethods.next(error);
                    }
                );
            }
        });
        return this.availablePaymentMethods.asObservable();
    }

    /**
     * Sets the payment status for auto-renewal 3D secured flow
     * @public
     * @memberof CheckoutSdkService
     * @param {string} behaviorSubject The flow used for building the SDK script: autoRenewal/default
     * @param {string} paymentStatus The status of the payment: success/failed
     * @returns {Observable} The response from SDK
     */
    public setPaymentStatus(behaviorSubject: string, paymentStatus: string): Observable<any> {
        const setPaymentStatus = new Subject<any>();

        this.checkoutSdkBuildSubjects[behaviorSubject]
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(resp => {
            if (resp) {
                BitCheckoutSDK.setPaymentStatus(
                    paymentStatus,
                    data => {
                        setPaymentStatus.next(data);
                    },
                    error => {
                        setPaymentStatus.next(error);
                    }
                );
            }
        });
        return setPaymentStatus.asObservable();
    }

    /**
     * Gets account billing details from SDK
     * @public
     * @memberof CheckoutSdkService
     * @returns {Observable} The billing details if success, default error message otherwise
     */
    public getAccountBillingDetails(): Observable<any> {
        this.alreadyBuilt$
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(resp => {
            if (resp) {
                BitCheckoutSDK.getAccountBillingDetails(
                    data => {
                        this.billingDetails = data;
                        this.accountBillingDetails.next(data);
                    },
                    error => {
                        this.accountBillingDetails.next(error);
                    }
                );
            }
        });
        return this.accountBillingDetails.asObservable();
    }

    /**
     * Sets billing details
     * @public
     * @memberof CheckoutSdkService
     * @param {BillingDetails} newBillingDetails The updated billing details
     */
    public setBillingDetails(newBillingDetails: BillingDetails): void {
        this.billingDetails = {...this.billingDetails, ...newBillingDetails};
    }

    /**
     * Gets all billing details
     * @public
     * @memberof CheckoutSdkService
     * @returns {BillingDetails} The billing details
     */
    public getBillingDetails(): BillingDetails {
        return this.billingDetails;
    }

    /**
     * Gets the first name saved in billing details
     * @public
     * @memberof CheckoutSdkService
     * @returns {string} The first name saved in billing details, or empty string
     */
    public getBillingDetailsFirstName(): string {
        return this.billingDetails?.first_name ?? '';
    }

    /**
     * Gets the last name saved in billing details
     * @public
     * @memberof CheckoutSdkService
     * @returns {string} The last name saved in billing details, or empty string
     */
    public getBillingDetailsLastName(): string {
        return this.billingDetails?.last_name ??'';
    }

    /**
     * Gets the city saved in billing details
     * @public
     * @memberof CheckoutSdkService
     * @returns {string} The city saved in billing details, or empty string
     */
    public getBillingDetailsCity(): string {
        return this.billingDetails?.city ?? '';
    }

    /**
     * Gets the zip code saved in billing details
     * @public
     * @memberof CheckoutSdkService
     * @returns {string} The zip code saved in billing details, or empty string
     */
    public getBillingDetailsZipCode(): string {
        return this.billingDetails?.zip_code ?? '';
    }

    /**
     * Gets the state saved in billing details
     * @public
     * @memberof CheckoutSdkService
     * @returns {string} The state saved in billing details, or empty string
     */
    public getBillingDetailsState(): string {
        return this.billingDetails?.state ?? '';
    }

    /**
     * Gets the address saved in billing details
     * @public
     * @memberof CheckoutSdkService
     * @returns {string} The address saved in billing details, or empty string
     */
    public getBillingDetailsAddress(): string {
        const addressLine1 = this.billingDetails?.address_line_1;
        const addressLine2 = this.billingDetails?.address_line_2;
        let billingAddress = '';

        if (addressLine1) {
            billingAddress = billingAddress.concat(addressLine1);
        }
        if (addressLine2) {
            if (billingAddress) {
                billingAddress = billingAddress.concat(' ');
            }
            billingAddress = billingAddress.concat(addressLine2);
        }
        return billingAddress;
    }

    /**
     * Gets the country code saved in billing details
     * @public
     * @memberof CheckoutSdkService
     * @returns {string} The country code saved in billing details, or empty string
     */
    public getBillingDetailsCountryCode(): string {
        return this.billingDetails?.country_code ?? '';
    }

    /**
     * Gets the company name saved in billing details
     * @public
     * @memberof CheckoutSdkService
     * @returns {string} The company name saved in billing details, or empty string
     */
    public getBillingDetailsCompanyName(): string {
        return this.billingDetails?.company_name ?? '';
    }

    /**
     * Gets the company vat number saved in billing details
     * @public
     * @memberof CheckoutSdkService
     * @returns {string} The company vat number saved in billing details, or empty string
     */
    public getBillingDetailsVatNo(): string {
        return this.billingDetails?.vat_no ?? '';
    }

    /**
     * Gets the value of the 'is_company' flag saved in billing details
     * @returns {boolean} The value of the flag
     */
    public getIsCompany(): boolean {
        return this.billingDetails?.is_company ?? false;
    }

    /**
     * Updates billing details in SDK
     * @param {BillingDetails} data The billing details info object
     * @param {Function} cbs The callback function in case of success
     * @param {Function} cbe The callback function in case of error
     * @returns {Observable} Default error if SDK script is not loaded, void otherwise
     */
    public updateAccountBillingDetails(data: BillingDetails, cbs: any, cbe: any): Observable<any> {
        return new Observable(subscriber => {
            if (this.sdkBuilt) {
                BitCheckoutSDK.updateAccountBillingDetails(data, cbs, cbe);
                subscriber.next();
                subscriber.complete();
            } else {
                subscriber.next(SubscriptionsSdkErrors.DEFAULT_ERROR);
            }
        });
    }

    /**
     * Links given campaign to user's session
     * @public
     * @memberof CheckoutSdkService
     * @param {string} behaviorSubject The flow used for building the SDK script: autoRenewal/default
     * @param {string} campaign The campaign name
     * @returns {Observable} The response from SDK
     */
    public linkCampaignToSession(behaviorSubject: string, campaign: string): Observable<any> {
        const linkCampaign = new Subject<any>();

        this.checkoutSdkBuildSubjects[behaviorSubject]
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(resp => {
            if (this.sdkBuilt) {
                BitCheckoutSDK.linkCampaignToSession(
                    campaign,
                    _success => {
                        linkCampaign.next(resp);
                    },
                    _error => {
                        linkCampaign.next(SubscriptionsSdkErrors.DEFAULT_ERROR);
                    }
                );
            } else {
                linkCampaign.next(SubscriptionsSdkErrors.DEFAULT_ERROR);
            }
        });
        return linkCampaign.asObservable();
    }

    /**
     * Gets the product price from SDK
     * @public
     * @memberof CheckoutSdkService
     * @param {string} behaviorSubject The flow used for building the SDK script: autoRenewal/default
     * @param {ProductDataNeededBySdk} data The product details
     * @returns {Observable} The response from SDK
     */
    public getProductPrice(behaviorSubject: string, data: ProductDataNeededBySdk): Observable<any> {
        const priceProduct = new Subject<any>();

        this.checkoutSdkBuildSubjects[behaviorSubject]
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() => {
            if (this.sdkBuilt) {
                BitCheckoutSDK.getProductPrice(
                    data,
                    _success => {
                        priceProduct.next(_success);
                    },
                    _error => {
                        priceProduct.next(SubscriptionsSdkErrors.DEFAULT_ERROR);
                    }
                );
            } else {
                priceProduct.next(SubscriptionsSdkErrors.DEFAULT_ERROR);
            }
        });
        return priceProduct.asObservable();
    }

    /**
     * Cancels the auto renewal in SDK
     * @public
     * @memberof CheckoutSdkService
     * @param {string} id The platform subscription id 
     * @param {Function} cbs The callback function in case of success
     * @param {Function} cbe The callback function in case of error
     */
    public cancelAutoRenewal(id: string, cbs: any, cbe: any): void {
        this.alreadyBuilt$.pipe(takeUntil(this.onDestroy$))
        .subscribe(resp => {
            if (resp) {
                BitCheckoutSDK.subscriptionAction(id, 'cancel-auto-renewal', cbs, cbe);
            }
        });
    }

    /**
     * Activates the auto renewal in SDK
     * @public
     * @memberof CheckoutSdkService
     * @param {string} id The platform subscription id 
     * @param {Function} cbs The callback function in case of success
     * @param {Function} cbe The callback function in case of error
     */
    public activateAutoRenewal(id: string, cbs: any, cbe: any): void {
        this.alreadyBuilt$.pipe(takeUntil(this.onDestroy$))
        .subscribe(resp => {
            if (resp) {
                BitCheckoutSDK.subscriptionAction(id, 'auto-renew', cbs, cbe);
            }
        });
    }

    /**
     * Cancels the auto renewal in SDK
     * @public
     * @memberof CheckoutSdkService
     * @param {string} id The platform subscription id 
     * @param {Function} cbs The callback function in case of success
     * @param {Function} cbe The callback function in case of error
     */
    public resume(id, cbs, cbe): void {
        this.alreadyBuilt$.pipe(takeUntil(this.onDestroy$))
        .subscribe(resp => {
            if (resp) {
                BitCheckoutSDK.subscriptionAction(id, 'resume', cbs, cbe);
            }
        });
    }

    /**
     * Cancels and refunds a subscription in SDK
     * @public
     * @memberof CheckoutSdkService
     * @param {string} id The platform subscription id 
     * @param {Function} cbs The callback function in case of success
     * @param {Function} cbe The callback function in case of error
     */
    public cancelAndRefund(id: string, cbs: any, cbe: any): void {
        this.alreadyBuilt$.pipe(takeUntil(this.onDestroy$))
        .subscribe(resp => {
            if (resp) {
                BitCheckoutSDK.subscriptionAction(id, 'cancel-and-refund', cbs, cbe);
            }
        });
    }

    /**
     * Applies a discount on the next billing
     * @public
     * @memberof CheckoutSdkService
     * @param {string} id The platform subscription id 
     * @param {Function} cbs The callback function in case of success
     * @param {Function} cbe The callback function in case of error
     * @param {string} campaign The campaign name
     */
    public discountNextBilling(id: string, cbs: any, cbe: any, campaign: string): void {
        this.alreadyBuilt$.pipe(takeUntil(this.onDestroy$))
        .subscribe(resp => {
            if (resp) {
                BitCheckoutSDK.subscriptionAction(id, 'discount-next-billing', cbs, cbe, {campaign});
            }
        });
    }

     /**
     * Gets the error state for SDK
     * @public
     * @memberof CheckoutSdkService
     * @returns {boolean} Returns true if error was returned from SDK
     */
    public checkSDKErrorState(): boolean {
        let sdkErrorState = false;
        if (typeof(BitCheckoutSDK) === 'undefined') {
            sdkErrorState = true;
        }
        return sdkErrorState;
    }

    /**
     * Upgrades the product in SDK
     * @public
     * @memberof CheckoutSdkService
     * @param {string} serviceId The service id
     * @param {string} productId The product id
     * @param {number} devicesNo The number of slots
     * @param {string} paymentPeriod The payment period
     * @param {string} billingPeriod The billing period
     * @param {Function} cbs The callback function in case of success
     * @param {Function} cbe The callback function in case of error
     */
    public licenceUpgradeProduct(serviceId: string, productId: string, devicesNo: number, paymentPeriod: string, billingPeriod: string, cbs: any, cbe: any): void {
        this.alreadyBuilt$.pipe(takeUntil(this.onDestroy$)).subscribe(resp => {
            if (resp) {
                BitCheckoutSDK.licenceUpgradeProduct(serviceId,  productId, devicesNo, paymentPeriod, billingPeriod, cbs, cbe);
            }
        });
    }

    /**
     * Sets a web-hook to be listened to
     * @public
     * @memberof CheckoutSdkService
     * @param {string} webHookName The name of the web-hook
     * @param {Function} cbs The callback function in case of success
     * @param {Function} cbe The callback function in case of error
     */
    public setWebHook(webHookName: string, cbs: any, cbe?: any): void {
        this.alreadyBuilt$.pipe(takeUntil(this.onDestroy$))
        .subscribe(resp => {
            if (resp) {
                BitCheckoutSDK.setWebHook(webHookName, cbs, cbe);
            }
        });
    }

     /**
     * Loads the SDK script with given config from CheckoutSdkBuildSubjectTypes
     * @public
     * @memberof CheckoutSdkService
     * @param {string} behaviorSubject The config to be loaded
     * @param {boolean} isDefaultConfig Flag that indicates what config object to be used
     * @param {boolean} disableNewSession Flag that indicates if new session is required for SDK config
     * @param {boolean} skipCleanningStorage Flag that indicates if the CHECKOUT_SESSION_KEY should be cleanned or not
     * @returns {Observable} The response from SDK
     */
    public loadSdkScript(behaviorSubject: string, isDefaultConfig: boolean, disableNewSession: boolean, skipCleanningStorage: boolean): Observable<any> {
        return new Observable(subscriber => {
            this.scriptService.load(Scripts.UpdateBillingSdk)
            .then(() => {
                this.build(behaviorSubject, isDefaultConfig, disableNewSession, skipCleanningStorage)
                .pipe(takeUntil(this.onDestroy$))
                .subscribe({
                    next: () => {
                        subscriber.next(LoadSdkScriptStatus.SUCCESS);
                        subscriber.complete();
                    },
                    error: () => {
                        subscriber.next(LoadSdkScriptStatus.ERROR);
                        subscriber.complete();
                    }
                });
            });
        });
    }
}
