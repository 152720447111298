import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, concatMap, map, mergeMap, of, skipWhile } from 'rxjs';
import { Router } from '@angular/router';

import { ONE_DAY_IN_MILISECONDS, OperatingSystems, ValuesService } from '../../../values/values.service';
import { AppsConfigService } from '../../../config/apps.config.service';
import { AccountStatus, AtoIdentityStatusModel } from '../../../models/ato/AtoIdentity.model';
import { AtoErrorCodes, AtoProviders, AtoServiceModel, AtoServiceStatus } from '../../../models/ato/AtoService.model';
import { SmtpAtoService } from '../../requests/smtp-ato/smtp-ato.service';
import { AtoEventModel, EventTypes, ExplainableThreatInterface } from '../../../models/ato/AtoEvents.model';
import { LiveReportDataModel, SetupStep } from '../../../../common/models/ato/AtoGeneral.model';
import { RcaApiDetectionService } from '../rca-api-detection/rca-api-detection.service';
import { ModalRoutelessService } from '../../../components/ui/ui-modal-routeless/modal.routeless.service';
import { ContextBubbleLocalStorage, CreateGroupParams, CreateGroupResponse, DefaultGroupLabelForATO, GroupTypes } from '../../../models/subscriptions/Groups.model';
import { SwitchContextResponse } from '../../global/context/context.service';
import { BrowserBooleanValues } from '../../../models/Core.model';
import { ContextService } from '../../global/context/context.service';
import { ConnectGroupMgmtService } from '../../requests/connect-group-mgmt/connect-group-mgmg.service';

export interface IPaginationObject {
    limit: number;
    scrollLimit?: number;
    offset: number;
}

export interface ItemsObjectInfo {
    gotAllItemsInList: boolean;
    noOfItems: number;
    totalNoOfItems?: number;
    listOfItems: any[];
    zeroState: any;
}

export interface IExplainableThreatsObj {
    [id: string]: ExplainableThreatInterface[];
};

@Injectable({
    providedIn: 'root'
})
export class ATOService {

    constructor(
        private readonly smtpAtoService: SmtpAtoService,
        private readonly valuesService: ValuesService,
        private readonly appsConfigService: AppsConfigService,
        private readonly connectGroupMgmtService: ConnectGroupMgmtService,
        private readonly contextSwitchService: ContextService,
        private readonly rcaApiDetectionService: RcaApiDetectionService,
        private readonly modalRoutelessService: ModalRoutelessService,
        private readonly router: Router
    ) {}

    private identityWasCreated = false;
    private createdIdentityStatus: AtoIdentityStatusModel;
    private servicesList: AtoServiceModel[] = [];
    private pinnedEventsList: AtoEventModel[] = [];
    private eventsObj = {} as ItemsObjectInfo;
    private explainableThreatsObj: IExplainableThreatsObj = {};
    private checkIdentityStatusReqHasError = false;
    private liveReportdata: LiveReportDataModel;
    private expiredSubsAfterCalls = false;
    private areNewAndOldEvents = false;
    private newEvents: AtoEventModel[] = [];

    private readonly onCheckIdentity$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private readonly onListServices$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private readonly onListInboxes$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private readonly onListPinnedEvents$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private readonly onListEvents$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private readonly onListLiveReportData$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);

    private markToUpdateCheckIdentity = true;
    private markToUpdateListServices = true;
    private markToUpdateListInboxes = true;
    private markToUpdateListPinnedEvents = true;
    private markToUpdateListEvents = true;
    private markToUpdateListLiveReportData = true;

    private readonly paginationObjectEvents: IPaginationObject = {
        limit: 10,
        scrollLimit: 10,
        offset: 0
    };

    private pinnedEventsError;
    private eventsError;

//#region create identity

    /**
     * Method that creates identity
     */
    public createIdentity(): Observable<any> {
        return this.smtpAtoService.createIdentity()
        .pipe(
            map(resp => {
                this.identityWasCreated = resp;
                return of(true);
            }),
            catchError(err => {
                throw err;
            })
        );
    }

    /**
     * Method that gets the identity creation status
     * @returns {boolean} if identity was created or not
     */
    public getIdentityWasCreated(): boolean {
        return this.identityWasCreated;
    }

//#endregion

//#region check identity status

    /**
     * Method that checks identity status
     */
    public checkIdentityStatus(): Observable<any> {
        if (!this.markToUpdateCheckIdentity || !this.appsConfigService.showApp(this.valuesService.appATO)) {
            return of(false);
        }

        if (this.onCheckIdentity$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onCheckIdentity$.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        }

        this.onCheckIdentity$.next(this.valuesService.processServiceState.INPROGRESS);
        return this.smtpAtoService.checkIdentity()
        .pipe(
            map(resp => {
                this.checkIdentityStatusReqHasError = false;
                this.createdIdentityStatus = resp;
                this.onCheckIdentity$.next(this.valuesService.processServiceState.DONE);
                this.markToUpdateCheckIdentity = false;
                return of(true);
            }),
            catchError(err => {
                this.checkSubscriptionExpired(err, false);
                this.checkIdentityStatusReqHasError = true;
                this.markToUpdateCheckIdentity = true;
                this.onCheckIdentity$.next(this.valuesService.processServiceState.DONE);
                throw err;
            })
        );
    }

    /**
     * Method that gets the created identity status
     * @returns {AtoIdentityStatusModel} the created identity status
     */
    public getCreatedIdentityStatus(): AtoIdentityStatusModel {
        return this.createdIdentityStatus;
    }

    public getCreatedIdentityOnboardingSteps() {
        return this.createdIdentityStatus?.onboarding_steps ?? {};
    }

    public getCreatedIdentityCreatedStatus(): boolean {
        return this.createdIdentityStatus?.created ?? false;
    }

    public getCreatedIdentityCreationDate(): Date {
        return this.createdIdentityStatus?.created_at ?? null;
    }

    public getDaysFromCreationDate(): number|null {
        if (this.atoIdentityOnboardingInProgress()) {
            return null;
        }

        const createIdentityDate = new Date(this.createdIdentityStatus?.created_at);
        const currentDate = new Date();
        return Math.floor((currentDate.getTime() - createIdentityDate.getTime()) / ONE_DAY_IN_MILISECONDS);
    }

    /**
     * Method that gets the creation date of identity
     * @returns {Date} the creation date of identity creation
     */
    public getIdentityCreationDate(): Date {
        return new Date(this.createdIdentityStatus?.created_at);
    }

    /**
     * Method that checks if onboarding is in progress.
     * @returns {boolean} if onboarding is in progress or not
     */
    public atoIdentityOnboardingInProgress(): boolean {
        return !this.createdIdentityStatus?.created || !this.createdIdentityStatus?.onboarding_steps?.[SetupStep.OnboardingFinished];
    }

    /**
     * Method that updates check identity status flag
     * @returns {void}
     */
    public updateCheckIdentityStatus(): void {
        if (this.onCheckIdentity$.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.markToUpdateCheckIdentity = true;
        }
    }

    /**
     * Method that gets account status based on creation date and critical recomendations found
     * @returns AccountStatus
     */
    public getAccountStatus(): AccountStatus {
        return this.createdIdentityStatus._computedAccountStatus;
    }

    /**
     * Method that checks if main request has error
     * @returns {boolean} if main request has error
     */
    public getMainReqHasError(): boolean {
        return this.checkIdentityStatusReqHasError;
    }

    public getSubsIsExpired(): boolean {
        return this.createdIdentityStatus?.subs_info?.active === false;
    }
//#endregion

//#region list services

    /**
     * Method that calls get_service method
     * @param {boolean} reloadInCaseOfSubsExpired flag that marks if the reload is necessary or not
     * @returns {Observable} the request response
     */
    public listServices(reloadInCaseOfSubsExpired = false): Observable<any> {
        if (!this.markToUpdateListServices || !this.appsConfigService.showApp(this.valuesService.appATO)) {
            return of(this.servicesList);
        }

        if (this.onListServices$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onListServices$.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        }

        this.onListServices$.next(this.valuesService.processServiceState.INPROGRESS);
        return this.smtpAtoService.getServices()
        .pipe(
            map(resp => {
                this.servicesList = resp;
                this.onListServices$.next(this.valuesService.processServiceState.DONE);
                this.markToUpdateListServices = false;
                return of(true);
            }),
            catchError(err => {
                this.checkSubscriptionExpired(err, false, reloadInCaseOfSubsExpired);
                this.markToUpdateListServices = true;
                this.onListServices$.next(this.valuesService.processServiceState.DONE);
                throw err;
            })
        );
    }

    /**
     * Method that updates list services flag
     * @returns {void}
     */
    public updateListServices(): void {
        if (this.onListServices$.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.markToUpdateListServices = true;
        }
    }

    /**
     * Method that returns an array of services found
     * @returns {AtoServiceModel[]} the list of services found
     */
    public getServices(): AtoServiceModel[] {
        return this.servicesList ?? [];
    }

    /**
     * Method that searched a service based on id
     * @param {string} id the id of the searched service
     * @returns {AtoEventModel} the service that has specified id
     */
    public getServiceById(id: string): AtoServiceModel {
        for (const service of this.servicesList) {
            if (service.service_id === id) {
                return service;
            }
        }
    }

    /**
     * Method that gets linked service for current account
     * @returns {AtoEventModel} the service linked
     */
    public getLinkedService(): AtoServiceModel {
        for (const service of this.servicesList) {
            if (service.provider === AtoProviders.YOUTUBE &&
                (service.linked || (!service.linked && service.status === AtoServiceStatus.REVOKED))) {
                return service;
            }
        }
    }

//#endregion

//#region pinned events

    /**
     * Method that calls get_pinned_events method
     * @param {boolean} reloadInCaseOfSubsExpired flag that marks if the reload is necessary or not
     * @returns {Observable} the request response
     */
    public listPinnedEvents(reloadInCaseOfSubsExpired = true): Observable<any> {
        this.pinnedEventsError = false;

        if (!this.markToUpdateListPinnedEvents || !this.appsConfigService.showApp(this.valuesService.appATO)) {
            return of(this.pinnedEventsList);
        }

        if (this.onListPinnedEvents$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onListPinnedEvents$.asObservable()
            .pipe(
                skipWhile(res => res !== this.valuesService.processServiceState.DONE)
            );
        }

        this.onListPinnedEvents$.next(this.valuesService.processServiceState.INPROGRESS);
        return this.smtpAtoService.getPinnedEvents()
        .pipe(
            map(resp => {
                this.pinnedEventsList = resp;
                this.onListPinnedEvents$.next(this.valuesService.processServiceState.DONE);
                this.markToUpdateListPinnedEvents = false;
                return of(true);
            }),
            catchError((err) => {
                this.checkSubscriptionExpired(err, false, reloadInCaseOfSubsExpired);
                this.pinnedEventsError = err;
                this.markToUpdateListPinnedEvents = true;
                this.onListPinnedEvents$.next(this.valuesService.processServiceState.DONE);
                return of(true);
            })
        );
    }

    /**
     * Method that updates list pinned services flag
     * @returns {void}
     */
    public updateListPinnedEvents(): void {
        if (this.onListPinnedEvents$.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.markToUpdateListPinnedEvents = true;
        }
    }

    /**
     * Method that returns an array of pinned (important) events
     * @returns {AtoEventModel[]} the list of pinned events
     */
    public getPinnedEvents(): AtoEventModel[] {
        return this.pinnedEventsList ?? [];
    }

    /**
     * Method that return true or false if list pinned events had error or not
     * @returns {boolean} if list pinned events was successful or not
     */
    public getListPinnedEventsHasError(): boolean {
        return !!this.pinnedEventsError;
    }

    /**
     * Method that returns the account take over event if exists in pinned events list
     * @returns {AtoEventModel} the account take over event
     */
    public getAtoEvent(): AtoEventModel {
        for (const event of this.pinnedEventsList) {
            if (event.event_type === EventTypes.ACCOUNT_TAKEOVER) {
                return event;
            }
        }

        return null;
    }

//#endregion

//#region events

    /**
     * Method that calls get_events method
     * @param {boolean} showMore flag that marks if the request should be made
     * @param {boolean} reloadInCaseOfSubsExpired flag that marks if the reload is necessary or not
     * @param {string} serviceId id of the current service
     * @returns {Observable} The request response
     */
    public listEvents(showMore = false, reloadInCaseOfSubsExpired = false, serviceId?: string): Observable<any> {
        this.eventsError = false;

        if ((!this.markToUpdateListEvents || !this.appsConfigService.showApp(this.valuesService.appATO)) && !showMore) {
            return of(this.eventsObj);
        }

        if (this.onListEvents$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onListEvents$.asObservable()
            .pipe(
                skipWhile(res => res !== this.valuesService.processServiceState.DONE)
            );
        }

        const paginationObject = this.paginationObjectEvents;

        this.onListEvents$.next(this.valuesService.processServiceState.INPROGRESS);
        return this.smtpAtoService.getEvents(serviceId, paginationObject.offset, paginationObject.scrollLimit)
        .pipe(
            map(resp => {
                this.onListEvents$.next(this.valuesService.processServiceState.DONE);
                this.markToUpdateListEvents = false;
                this._updateEventsPaginationObj(resp.events, resp.total, paginationObject);
                this.computeShowUpToDateRegion(resp.events);
                const newThreats = this.getNewThreats(resp.events);
                return newThreats;
            }),
            concatMap((newThreats) => this.listExplainableThreats(newThreats)),
            catchError((err) => {
                this.checkSubscriptionExpired(err, false, reloadInCaseOfSubsExpired);
                this.eventsError = err;
                this.markToUpdateListEvents = true;
                this.onListEvents$.next(this.valuesService.processServiceState.DONE);
                return of(true);
            })
        );
    }

    /**
     * Method that updates events pagination object
     * @param {AtoEventModel[]} list the list of events
     * @param {IPaginationObject} paginationObj the pagination object
     * @returns {void}
     */
    private _updateEventsPaginationObj(list: AtoEventModel[], total: number, paginationObj: IPaginationObject): void {
        this.eventsObj.noOfItems = list.length;
        this.eventsObj.totalNoOfItems = total;

        if (!this.eventsObj.listOfItems || this.eventsObj.listOfItems.length === 0 || this.markToUpdateListEvents) {
            this.eventsObj.listOfItems = list;
        } else {
            this.eventsObj.listOfItems = this.eventsObj.listOfItems.concat(list);
        }

        if (list && (!list.length || this.eventsObj.listOfItems.length === total)) {
            this.eventsObj.gotAllItemsInList = true;
        } else {
            this.eventsObj.gotAllItemsInList = false;
        }

        paginationObj.offset += paginationObj.scrollLimit / paginationObj.limit;
        paginationObj.scrollLimit = paginationObj.limit;
    }

    /**
     * Method that decides if are new events and old events, in order to display the up to date region and only new events
     * @param {AtoEventModel[]} eventsList the list of events to be analyzed
     * @returns {void}
     */
    private computeShowUpToDateRegion(eventsList: AtoEventModel[]): void {
        this.areNewAndOldEvents = false;
        this.newEvents = [];
        let hasNewEvents = false;
        let hasOldEvents = false;
        for (const [index, event] of eventsList.entries()) {
            if (event.new === true) {
                hasNewEvents = true;
                this.newEvents.push(event);
            } else {
                hasOldEvents = true;
            }
        }
        if (hasNewEvents && hasOldEvents) {
            this.areNewAndOldEvents = true;
        }
    }

    /**
     * Method that gets show up to date region
     * @returns {boolean} if show up to date region
     */
    public getIfAreNewAndOldEvents(): boolean {
        return this.areNewAndOldEvents;
    }

    /**
     * Methodthat returns only the new events
     * @returns {AtoEventModel[]} the new events
     */
    public getNewEvents(): AtoEventModel[] {
        return this.newEvents;
    }

    /**
     * Gets explainable threats description
     */
    public listExplainableThreats(newThreats?: string[]): Observable<any> {
        const theatsList = newThreats ?? Object.keys(this.explainableThreatsObj);
        if (!theatsList.length) {
            return of(true);
        }

        return this.rcaApiDetectionService.getExplainableThreats(theatsList)
        .pipe(
            map(resp => {
                for (const threat of resp) {
                    if (threat.events.length) {
                        this.explainableThreatsObj[threat._id] = threat.events;
                    }
                }
                return true;
            }),
            catchError((err) => {
                if (!this.checkSubscriptionExpired(err, false) && newThreats) {
                    for (const threat of newThreats) {
                        this.explainableThreatsObj[threat] = [];
                    }
                }
                return of(true);
            })
        );
    }

    /**
     * Method that gets new threats from events
     * @param {AtoEventModel[]} events events list
     * @returns {string[]} new threats list
     */
    private getNewThreats(events: AtoEventModel[]): string[] {
        const newThreats = [];
        for (const event of events) {
            const threat = event?.event_metadata?.threat?.threat;
            if (event.event_type === EventTypes.SECURITY_CENTER_THREAT_EVENT
                && event?.event_metadata.device_os === OperatingSystems.ANDROID
                && (!this.explainableThreatsObj[threat] || !this.explainableThreatsObj[threat]?.length)) {
                newThreats.push(threat);
            }
        }
        return newThreats;
    }

    /**
     * Method that resets pagination object for events
     * @returns {void}
     */
    private _resetEventsPaginationObj(): void {
        this.eventsObj.noOfItems = 0;
        this.eventsObj.listOfItems = [];
        this.eventsObj.gotAllItemsInList = false;

        this.paginationObjectEvents.scrollLimit = this.paginationObjectEvents.offset === 0
                                                ? this.paginationObjectEvents.limit
                                                : this.paginationObjectEvents.offset * this.paginationObjectEvents.limit;
        this.paginationObjectEvents.offset = 0;
    }

    /**
     * Method that updates list events flag
     * @returns {void}
     */
    public updateListEvents(): void {
        if (this.onListEvents$.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.markToUpdateListEvents = true;
            this._resetEventsPaginationObj();
        }
    }

    /**
     * Method that returns events list
     * @returns {Array<AtoEventModel>} list of events
     */
    public getEventsList(): Array<AtoEventModel> {
        return this.eventsObj.listOfItems as Array<AtoEventModel>;
    }

    /**
     * Method that returns events count
     * @returns {number} the number of events
     */
    public getEventsCount(): number {
        return this.eventsObj.noOfItems;
    }

    /**
     * Method that returns total number of events
     * @returns {number} the total number of events
     */
    public getTotalNoOfEvents(): number {
        return this.eventsObj.totalNoOfItems;
    }

    /**
     * Method that returns true if we listed all events
     * @returns {boolean} true if all events
     */
    public getEventsIsListFinal(): boolean {
        return this.eventsObj.gotAllItemsInList;
    }

    /**
     * Method that return true or false if list events had error or not
     * @returns {boolean} if list events was successful or not
     */
    public getListEventsHasError(): boolean {
        return !!this.eventsError;
    }

    /**
     * Method that return explainable threat description
     * @returns {ExplainableThreatInterface[]}
     */
    public getExplainableThreatDescription(threat: string): ExplainableThreatInterface[] {
        return this.explainableThreatsObj[threat] ?? [];
    }

    /**
     * Method that undo's an event
     * @param {string} eventId the id of the event
     * @returns {Observable} the request response
     */
    public undoEvent(eventId: string): Observable<any> {
        return this.smtpAtoService.undoEvent(eventId)
        .pipe(
            map(resp => {
                this.updateListPinnedEvents();
                this.updateListEvents();
                return of(true);
            }),
            catchError(err => {
                this.checkSubscriptionExpired(err, false);
                throw err;
            })
        );
    }

//#endregion

//#region live report

    /**
     * Method that calls get_report method
     */
    public listLiveReportData(): Observable<any> {
        if (!this.markToUpdateListLiveReportData || !this.appsConfigService.showApp(this.valuesService.appATO)) {
            return of(this.liveReportdata);
        }

        if (this.onListLiveReportData$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onListLiveReportData$.asObservable()
            .pipe(
                skipWhile(res => res !== this.valuesService.processServiceState.DONE)
            );
        }

        this.onListLiveReportData$.next(this.valuesService.processServiceState.INPROGRESS);
        return this.smtpAtoService.getLiveReport()
        .pipe(
            map(resp => {
                this.liveReportdata = resp;
                this.onListLiveReportData$.next(this.valuesService.processServiceState.DONE);
                this.markToUpdateListLiveReportData = false;
                return of(true);
            }),
            catchError(err => {
                this.checkSubscriptionExpired(err, false);
                this.markToUpdateListLiveReportData = true;
                this.onListLiveReportData$.next(this.valuesService.processServiceState.DONE);
                throw err;
            })
        );
    }

    /**
     * Method that updates list live report data flag
     * @returns {void}
     */
    public updateListLiveReportData(): void {
        if (this.onListLiveReportData$.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.markToUpdateListLiveReportData = true;
        }
    }

    /**
     * Method that returns live report data
     * @returns {LiveReportDataModel} the live report data
     */
    public getLiveReportData(): LiveReportDataModel {
        return this.liveReportdata;
    }

//#endregion

    /**
     * Method that verifies if response error code is NO_SUBSCRIPTIONS
     * @param {any} error the error to be checked
     * @param {boolean} callIsMadeFromModal if the call was made from a modal or not
     * @returns {boolean} if subscription is expired or not
     */
    public checkSubscriptionExpired(error: any, callIsMadeFromModal: boolean, changeRoute = false): boolean {
        const atoMainPage = this.valuesService.centralPaths.ato.path.concat(this.valuesService.centralPaths.ato.activity.path);

        if (error?.code === AtoErrorCodes.NO_SUBSCRIPTIONS || error.message?.internal_data?.code === AtoErrorCodes.NO_SUBSCRIPTIONS) {
            this.expiredSubsAfterCalls = true;
            if (changeRoute) {
                if (callIsMadeFromModal) {
                    this.modalRoutelessService.close(atoMainPage);
                } else {
                    this.router.navigate([atoMainPage], { replaceUrl: true });
                }
            }
            return true;
        }

        return false;
    }

    /**
     * Method that gets the expired subs status
     * @returns {boolean} the expired subs status
     */
    public checkIfSubsIsExpiredAfterCalls(): boolean {
        return this.expiredSubsAfterCalls;
    }

    /**
     * Method that sets the expired subs status
     * @param {boolean} status the status to be set
     * @returns {void}
     */
    public setExpiredSubsAfterCalls(status: boolean): void {
        this.expiredSubsAfterCalls = status;
    }

    /**
     * Creates the ATO group and switches the context for the given service id
     * @public
     * @memberof ATOService
     * @param {string} serviceId The service id of the subscription
     * @returns {Observable} Returns the request response
     */
    public createATOGroup(serviceId: string): Observable<any> {
        const groupParams: CreateGroupParams = {
            group_type: GroupTypes.ATO,
            group_label: DefaultGroupLabelForATO,
            service_id: serviceId
        };

        return this.connectGroupMgmtService.createGroup(groupParams)
        .pipe(
            mergeMap((createGroupResp: CreateGroupResponse) => {
                return this.contextSwitchService.switchContext(createGroupResp?.context_id)
            }),
            map((switchContextResp: SwitchContextResponse) => {
                if (switchContextResp.switched) {
                    localStorage.setItem(ContextBubbleLocalStorage, BrowserBooleanValues.TRUE);
                }
                return switchContextResp;
            }),
            catchError(() => {
                return of(false);
            })
        )
    }

}
