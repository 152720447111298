// External
import { Injectable } from '@angular/core';

// Internal
import { ValuesService } from './values.service';
import { AppConfigurationStatus } from '../models/Services.model';
import { FamilyMembersColors, GroupMemberLabel, GroupMemberProducts, GroupRoles } from '../models/subscriptions/Groups.model';

export const FAMILY_TEENAGER_MIN_AGE = 16;
export const FAMILY_TEENAGER_MAX_AGE = 18;

export interface FamilyMember {
    created: number,
    wrapper_id?: string,
    context_id?: string,
    email: string,
    member_id?: string,
    invite_accepted?: boolean,
    is_owner?: boolean,
    name: string,
    member_label?: GroupMemberLabel,
    role: GroupRoles,
    products?: GroupMemberProducts,
    profile_id?: string,
    profileHash?: number,
    type?: string,
    hideMember?: boolean,
    interface?: FamilyMemberInterface
};

export interface FamilyMemberInterface {
    label: string,
    color: FamilyMembersColors,
    hasNoDevicesProtected: boolean,
    devicesProtected?: number,
    actions: FamilyMemberActionsInterface[],
    availableProducts: FamilyProductInterface[]
};

export interface FamilyProductInterface {
    productName: string,
    appId: string,
    icon: {
        type: string,
        value: string
    },
    devicesNo?: number,
    isConfigured: boolean,
    configurationStatus?: AppConfigurationStatus,
    configurationLabel?: string,
    show: boolean
};

export interface FamilyMemberActionsInterface {
    buttonName: string,
    buttonAction: string
};

type FamilyProductsImages = {
    [key in string]: {
        type: string,
        value: string
    }
};

enum FamilyActionTypes {
    SET_SECONDARY_ADMIN = 'setSecondaryAdmin',
    REMOVE_MEMBER = 'removeMember'
};

type FamilyMemberActions = {
    [key in FamilyActionTypes]: FamilyMemberActionsInterface
};

type ConfigurationLabelBasedOnProductStatus = {
    [key in AppConfigurationStatus]?: string
};

type FamilyMembersColorsByRole = {
    [key in GroupRoles]?: FamilyMembersColors
};

type FamilyMembersLabels = {
    [key in GroupRoles]?: string
};

export enum FamilyEvents {
    GROUP_CREATED = 'group_created',
    GROUP_MEMBERS_INVITED = 'group_members_invited',
    GROUP_MEMBER_INVITED = 'group_member_invited',
    GROUP_MEMBER_REMOVED = 'group_member_removed',
    GROUP_MEMBER_UPDATED = 'group_member_updated',
    GROUP_MEMBER_JOINED = 'group_member_joined',
    GROUP_MEMBER_PROTECTED ='group_member_protected',
    GROUP_MEMBER_PASSWORDMANAGER_CONFIGURED = 'group_member_passwordmanager_configured',
    GROUP_MEMBER_PASSWORDMANAGER_REMOVED = 'group_member_passwordmanager_removed',
    GROUP_MEMBER_WEBMAIL_PROTECTED = 'group_member_webmail_protected',
    GROUP_MEMBER_WEBMAIL_UNPROTECTED = 'group_member_webmail_unprotected',
    GROUP_MEMBER_DATAPRIVACY_CONFIGURED = 'group_member_dataprivacy_configured',
    GROUP_MEMBER_DEVICE_PROTECTED = 'group_member_device_protected',
    GROUP_MEMBER_DEVICE_UNPROTECTED = 'group_member_device_unprotected',
    GROUP_MEMBER_DATAPRIVACY_REMOVED = 'group_member_dataprivacy_removed',
    GROUP_STARTING_POINT = 'group_starting_point',
    GROUP_INVITATION_EXPIRED = 'group_invitation_expired',
    GROUP_MEMBER_NCCPARENTAL_CONFIGURED = 'group_member_nccparental_configured',
    GROUP_MEMBER_NCCPARENTAL_REMOVED = 'group_member_nccparental_removed'
};

export enum FamilyEventsPlaceholder {
    MEMEBR_NAME = 'member_name',
    NO_INVITES = 'no_invites',
    MEMBER_ROLE = 'member_role',
    PRODUCT_NAME_PASSWORD_MANAGER = 'productNamePasswordManager'
};

export enum FamilyEventDeviceTypes {
    COMPUTER = 'computer',
    PHONE = 'phone',
    SERVER = 'server',
    MACOS = 'macos'
};

interface FamilyEventText {
    text: string;
    placeholders: FamilyEventsPlaceholder[];
};

type FamilyEventDeviceTypeText = {
    [key in FamilyEventDeviceTypes]: FamilyEventText;
};

type FamilyEventMemberRoleText = {
    [key in GroupRoles]?: FamilyEventText;
};

type FamilyEventsTexts = {
    [key in FamilyEvents]: FamilyEventText | FamilyEventDeviceTypeText | FamilyEventMemberRoleText;
};

@Injectable({
    providedIn: 'root'
})

export class FamilyValuesService {
    constructor(
        private readonly valuesService: ValuesService
    ) {}

    public readonly familyProductsImages: FamilyProductsImages = {
        [this.valuesService.productNameSecurity]: {
            type: 'products',
            value: 'security-free'
        },
        [this.valuesService.productNameDIP]: {
            type: 'products',
            value: 'dip-free'
        },
        [this.valuesService.productNamePasswordManager]: {
            type: 'products',
            value: 'passwordManager-free'
        },
        [this.valuesService.productNamePA]: {
            type: 'products',
            value: 'parental-free'
        }
    };

    public readonly clickEvents = {
        setSecondaryFamilyManager: 'setSecondaryFamilyManager',
        removeFamilyMember: 'removeFamilyMember',
        inviteFamilyMember: 'inviteFamilyMember'
    };

    public readonly configurationLabelBasedOnProductStatus: ConfigurationLabelBasedOnProductStatus = {
        [AppConfigurationStatus.INSTALLED]: 'subscriptions.services.ondevices',
        [AppConfigurationStatus.NOT_INSTALLED]: 'subscriptions.app.installed.not',
        [AppConfigurationStatus.CONFIGURED]: 'subscriptions.app.setup',
        [AppConfigurationStatus.NOT_CONFIGURED]: 'subscriptions.app.setup.not'
    };

    public readonly familyMemberButtonActions = {
        setFamilyManager: 'set.family.manager',
        removeFamilyMember: 'remove.family.member'
    };

    public readonly familyMemberActions: FamilyMemberActions = {
        [FamilyActionTypes.SET_SECONDARY_ADMIN]: {
            buttonName: 'family.activity.actions.set.secondary.manager',
            buttonAction: this.familyMemberButtonActions.setFamilyManager
        },
        [FamilyActionTypes.REMOVE_MEMBER]: {
            buttonName: 'family.activity.actions.remove.member',
            buttonAction: this.familyMemberButtonActions.removeFamilyMember
        }
    };

    public readonly familyMembersColorsByRole: FamilyMembersColorsByRole = {
        [GroupRoles.FAMILY_PRIMARY_ADMIN]: FamilyMembersColors.PRIMARY,
        [GroupRoles.FAMILY_SECONDARY_ADMIN]: FamilyMembersColors.SECONDARY,
        [GroupRoles.FAMILY_INDEPENDENT]: FamilyMembersColors.INDEPENDENT,
        [GroupRoles.FAMILY_TEENAGER]: FamilyMembersColors.TEENAGER,
        [GroupRoles.CHILD]: FamilyMembersColors.CHILD
    };

    public readonly familyMembersLabels: FamilyMembersLabels = {
        [GroupRoles.FAMILY_SECONDARY_ADMIN]: 'subscriptions.servicedetails.family.plan.category.secondaryAdmin.title'
    };

    public readonly familyEventsTexts: FamilyEventsTexts = {
        [FamilyEvents.GROUP_MEMBER_DEVICE_UNPROTECTED]: {
            [FamilyEventDeviceTypes.COMPUTER]: {
                text: 'family.security.activity.list.text.group_member_device_unprotected.computer',
                placeholders: [FamilyEventsPlaceholder.MEMEBR_NAME]
            },
            [FamilyEventDeviceTypes.PHONE]: {
                text: 'family.security.activity.list.text.group_member_device_unprotected.phone',
                placeholders: [FamilyEventsPlaceholder.MEMEBR_NAME]
            },
            [FamilyEventDeviceTypes.SERVER]: {
                text: 'family.security.activity.list.text.group_member_device_unprotected.server',
                placeholders: [FamilyEventsPlaceholder.MEMEBR_NAME]
            },
            [FamilyEventDeviceTypes.MACOS]: {
                text: 'family.security.activity.list.text.group_member_device_unprotected.macos',
                placeholders: [FamilyEventsPlaceholder.MEMEBR_NAME]
            }
        },
        [FamilyEvents.GROUP_STARTING_POINT]: {
            text: 'family.security.activity.list.text.group_event_starting_point',
            placeholders: []
        },
        [FamilyEvents.GROUP_MEMBER_DATAPRIVACY_REMOVED]: {
            text: 'family.security.activity.list.text.group_event_member_dataprivacy_removed',
            placeholders: [FamilyEventsPlaceholder.MEMEBR_NAME]
        },
        [FamilyEvents.GROUP_MEMBER_DEVICE_PROTECTED]: {
            [FamilyEventDeviceTypes.COMPUTER]: {
                text: 'family.security.activity.list.text.group_event_member_device_protected.computer',
                placeholders: [FamilyEventsPlaceholder.MEMEBR_NAME]
            },
            [FamilyEventDeviceTypes.PHONE]: {
                text: 'family.security.activity.list.text.group_event_member_device_protected.phone',
                placeholders: [FamilyEventsPlaceholder.MEMEBR_NAME]
            },
            [FamilyEventDeviceTypes.SERVER]: {
                text: 'family.security.activity.list.text.group_event_member_device_protected.server',
                placeholders: [FamilyEventsPlaceholder.MEMEBR_NAME]
            },
            [FamilyEventDeviceTypes.MACOS]: {
                text: 'family.security.activity.list.text.group_event_member_device_protected.macos',
                placeholders: [FamilyEventsPlaceholder.MEMEBR_NAME]
            }
        },
        [FamilyEvents.GROUP_CREATED]: {
            text: 'family.security.activity.list.text.group_event_created',
            placeholders: []
        },
        [FamilyEvents.GROUP_MEMBERS_INVITED]: {
            text: 'family.security.activity.list.text.group_event_members_invited',
            placeholders: [FamilyEventsPlaceholder.NO_INVITES]
        },
        [FamilyEvents.GROUP_MEMBER_INVITED]: {
            text: 'family.security.activity.list.text.group_event_member_invited',
            placeholders: []
        },
        [FamilyEvents.GROUP_MEMBER_REMOVED]: {
            text: 'family.security.activity.list.text.group_event_member_removed',
            placeholders: [FamilyEventsPlaceholder.MEMEBR_NAME]
        },
        [FamilyEvents.GROUP_MEMBER_UPDATED]: {
            text: 'family.security.activity.list.text.group_event_member_updated',
            placeholders: [FamilyEventsPlaceholder.MEMEBR_NAME, FamilyEventsPlaceholder.MEMBER_ROLE]
        },
        [FamilyEvents.GROUP_MEMBER_JOINED]: {
            text: 'family.security.activity.list.text.group_event_member_joined',
            placeholders: [FamilyEventsPlaceholder.MEMEBR_NAME]
        },
        [FamilyEvents.GROUP_MEMBER_PROTECTED]: {
            [FamilyEventDeviceTypes.COMPUTER]: {
                text: 'family.security.activity.list.text.group_event_member_protected.computer',
                placeholders: [FamilyEventsPlaceholder.MEMEBR_NAME]
            },
            [FamilyEventDeviceTypes.PHONE]: {
                text: 'family.security.activity.list.text.group_event_member_protected.phone',
                placeholders: [FamilyEventsPlaceholder.MEMEBR_NAME]
            },
            [FamilyEventDeviceTypes.SERVER]: {
                text: 'family.security.activity.list.text.group_event_member_protected.server',
                placeholders: [FamilyEventsPlaceholder.MEMEBR_NAME]
            },
            [FamilyEventDeviceTypes.MACOS]: {
                text: 'family.security.activity.list.text.group_event_member_protected.macos',
                placeholders: [FamilyEventsPlaceholder.MEMEBR_NAME]
            }
        },
        [FamilyEvents.GROUP_MEMBER_PASSWORDMANAGER_CONFIGURED]: {
            text: 'family.security.activity.list.text.group_event_member_passwordmanager',
            placeholders: [FamilyEventsPlaceholder.MEMEBR_NAME, FamilyEventsPlaceholder.PRODUCT_NAME_PASSWORD_MANAGER]
        },
        [FamilyEvents.GROUP_MEMBER_PASSWORDMANAGER_REMOVED]: {
            text: 'family.security.activity.list.text.group_event_member_passwordmanager_removed',
            placeholders: [FamilyEventsPlaceholder.MEMEBR_NAME, FamilyEventsPlaceholder.PRODUCT_NAME_PASSWORD_MANAGER]
        },
        [FamilyEvents.GROUP_MEMBER_WEBMAIL_PROTECTED]: {
            text: 'family.security.activity.list.text.group_event_member_webmailprotection',
            placeholders: [FamilyEventsPlaceholder.MEMEBR_NAME]
        },
        [FamilyEvents.GROUP_MEMBER_WEBMAIL_UNPROTECTED]: {
            text: 'family.security.activity.list.text.group_event_member_webmail_unprotected',
            placeholders: [FamilyEventsPlaceholder.MEMEBR_NAME]
        },
        [FamilyEvents.GROUP_MEMBER_DATAPRIVACY_CONFIGURED]: {
            text: 'family.security.activity.list.text.group_event_member_dataprivacy',
            placeholders: [FamilyEventsPlaceholder.MEMEBR_NAME]
        },
        [FamilyEvents.GROUP_INVITATION_EXPIRED]: {
            text: 'family.security.activity.list.text.group_invitation_expired',
            placeholders: []
        },
        [FamilyEvents.GROUP_MEMBER_NCCPARENTAL_CONFIGURED]: {
            text: 'family.security.activity.list.text.group_member_nccparental_configured',
            placeholders: []
        },
        [FamilyEvents.GROUP_MEMBER_NCCPARENTAL_REMOVED]: {
            text: 'family.security.activity.list.text.group_member_nccparental_removed',
            placeholders: []
        }
    };
}
