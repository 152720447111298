import { Injectable } from '@angular/core';
import { GroupManagementService } from '../../process/subscriptions/group-management.service';
import { DevicesService } from '../../process/devices/devices.service';
import { GroupDevices } from '../../../models/subscriptions/Groups.model';
import { ProfilesService } from '../../process/profiles/profiles.service';

@Injectable({
    providedIn: 'root'
})

export class IGroupsService {

    private groupDevices: GroupDevices = {};

    constructor(
        private readonly groupManagementService: GroupManagementService,
        private readonly devicesService: DevicesService,
        private readonly profileService: ProfilesService
    ) {}

    /**
     * Method creates object of devices grouped by user_id
     * @public
     * @memberof IGroupsService
     * @returns {void}
     */
    public createGroupDevices(): void {
        const devicesObj: GroupDevices = {};
        const devices = this.devicesService.getDevicesListArray();
        for (const device of devices) {
            if (!devicesObj[device.user_id]) {
                devicesObj[device.user_id] = [];
            }
            const memberProcessed = this.groupManagementService.getGroupMemberByContextId(device.user_id);
            if (memberProcessed) {
                const member = memberProcessed.member;
                const color = memberProcessed.color;
                device.owner_name = member.name;
                if (device.user_id !== this.profileService.getCurrentContextId()) {
                    device.color = color;
                }
            }
            devicesObj[device.user_id].push(device);
        }

        this.groupDevices = devicesObj;
    }

    /**
     * Method returns array of devices for a given context_id (equivalent to a device user_id)
     * @public
     * @memberof IGroupsService
     * @returns {Array}
     */
    public getUserDevices(context_id): Array<any> {
        return this.groupDevices[context_id] ?? [];
    }

    /**
     * Method returns array of devices of current user
     * @public
     * @memberof IGroupsService
     * @returns {Array}
     */
    public getCurrentUserDevices(): Array<any> {
        return this.groupDevices[this.profileService.getCurrentContextId()] ?? [];
    }

    /**
     * Method returns array of devices that are not owned by current user
     * @public
     * @memberof IGroupsService
     * @returns {Array}
     */
    public getOthersDevices(): Array<any> {
        let otherDevices = [];
        for (const contextId in this.groupDevices) {
            if (contextId !== this.profileService.getCurrentContextId()) {
                otherDevices = otherDevices.concat(this.groupDevices[contextId] ?? []);
            }
        }
        return otherDevices;
    }


}
