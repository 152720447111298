// External
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Internal
import { ModalName, ModalRoutelessService } from '../../components/ui/ui-modal-routeless/modal.routeless.service';
import { ModalContainerOptions, ModalContentOptions, ModalSize } from '../global/modal/Modal.model';
import { QueryParamsService } from '../core/query.params.service';
import { UsefulService } from '../global/useful/useful.service';
import { ValuesService } from '../../values/values.service';

@Injectable({
    providedIn: 'root'
})

export class HomeResolver {

    constructor(
        private readonly modalRoutelessService: ModalRoutelessService,
        private readonly queryParamsService: QueryParamsService,
        private readonly usefulService: UsefulService,
        private readonly valuesService: ValuesService
    ) { }

    /**
     * Computes the final url state for start trial modal
     * @private
     * @memberof HomeResolver
     * @param {object} queryParams The existing query params object
     * @returns {string} The final url state
     */
    private computeFinalUrlState(queryParams: any): string {
        const redirectPath = queryParams[this.valuesService.queryParams.redirectPath];
        if (queryParams[this.valuesService.queryParams.redirectPath]) {
            delete queryParams[this.valuesService.queryParams.redirectPath];
        }
        return `${redirectPath}?${this.usefulService.jsonToQueryString(queryParams)}`;
    }

    /**
     * Resolver for home page that decides if the start trial modal should be opened, or not
     * @returns {Observable<boolean>} It always returns true
     */
    resolve(): Observable<boolean> {
        return new Observable(subscriber => {
            const queryParamsRedirectUrl = this.queryParamsService.getAll();

            if (queryParamsRedirectUrl?.[this.valuesService.queryParams.days]
                && queryParamsRedirectUrl?.[this.valuesService.queryParams.product]
                && queryParamsRedirectUrl?.[this.valuesService.queryParams.codeType]
                && queryParamsRedirectUrl?.[this.valuesService.queryParams.redirectPath]) {
                const modalContainerOptions = {
                    size: ModalSize.LG
                } as ModalContainerOptions;

                const modalContentOptions = {
                    daysNo: queryParamsRedirectUrl[this.valuesService.queryParams.days],
                    product: queryParamsRedirectUrl[this.valuesService.queryParams.product],
                    codeType: queryParamsRedirectUrl[this.valuesService.queryParams.codeType],
                    urlState: this.computeFinalUrlState(queryParamsRedirectUrl)
                } as ModalContentOptions;

                this.modalRoutelessService.open(ModalName.startSubscriptionTrialModal, modalContainerOptions, modalContentOptions);
            }

            subscriber.next(true);
            subscriber.complete();
        });
    }
}
