// External
import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { catchError, map, skipWhile } from 'rxjs/operators';

// Internal
import { ReferralValuesService } from '../../../values/referral.values.service';
import { ValuesService } from '../../../../common/values/values.service';
import { ConnectReferralService } from '../../requests/connect-referral/connect-referral.service';
import { ConfigService } from '../../../../common/config/config.service';
import moment from 'moment';
import { ModalName, ModalRoutelessService } from '../../../../common/components/ui/ui-modal-routeless/modal.routeless.service';
import { QueryParamsService } from '../../core/query.params.service';
import { ModalSize } from '../../global/modal/Modal.model';

@Injectable({
    providedIn: 'root'
})

export class ReferralService {

    referralCampaigns: any = {
        [this.referralValuesService.nameSpaces.central]: {
            onlistCampaigns: new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING),
            markToUpdateListCampaigns: true,
            onlistReferrals: new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING),
            markToUpdateListReferrals: true,
            onGetReferral: new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING),
            markToUpdateGetReferral: true,
            listCampaigns: [],
            listReferrals: [],
            currentCampaign: {},
            referralLink: '',
            referralCode: ''
        }
    };

    constructor(
        private readonly valuesService: ValuesService,
        private readonly connectReferralService: ConnectReferralService,
        private readonly referralValuesService: ReferralValuesService,
        private readonly modalRoutelessService: ModalRoutelessService,
        private readonly configService: ConfigService
    ) { }

    noListing() {
        return !this.configService.getReferral();
    }

    //! const referralCode = this.queryParamsService.get(this.referralValuesService.referralCodeParam);
    //! const nameSpace = this.referralValuesService.nameSpaces.central;

    listCampaigns(referralCode?): Observable<any> {
        const nameSpace = this.referralValuesService.nameSpaces.central;
        if (!this.referralCampaigns[nameSpace].markToUpdateListCampaigns || this.noListing()) {
            return of(this.referralCampaigns[nameSpace].listCampaigns);
        }

        if (this.referralCampaigns[nameSpace].onlistCampaigns.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.referralCampaigns[nameSpace].onlistCampaigns.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        }

        this.referralCampaigns[nameSpace].onlistCampaigns.next(this.valuesService.processServiceState.INPROGRESS);
        return this.connectReferralService.listCampaigns(nameSpace)
            .pipe(
                map(
                    (resp) => {
                        if (resp) {
                            if (resp.reason === 'NO_CAMPAIGNS_COMPATIBLE') {
                                this.referralCampaigns[nameSpace].listCampaigns = [];
                                this.referralCampaigns[nameSpace].markToUpdateListCampaigns = false;
                                this.referralCampaigns[nameSpace].onlistCampaigns.next(this.valuesService.processServiceState.DONE);
                                return of(true)
                            }

                            this.referralCampaigns[nameSpace].listCampaigns = resp;
                            this.referralCampaigns[nameSpace].markToUpdateListCampaigns = false;
                            this.referralCampaigns[nameSpace].onlistCampaigns.next(this.valuesService.processServiceState.DONE);

                            this.processCampaings(nameSpace);

                            return of(this.referralCampaigns[nameSpace].listCampaigns);
                        } else {
                            this.referralCampaigns[nameSpace].markToUpdateListCampaigns = true;
                            this.referralCampaigns[nameSpace].onlistCampaigns.next(this.valuesService.processServiceState.DONE);
                        }
                        return true;
                    }
                ),
                catchError((err) => {
                    const isNoEligibleService = err?.code === 32005 && err?.data?.code === 39001;
                    if (isNoEligibleService) {
                        this.referralCampaigns[nameSpace].markToUpdateListCampaigns = false;
                    } else {
                        this.referralCampaigns[nameSpace].markToUpdateListCampaigns = true;
                    }
                    this.referralCampaigns[nameSpace].listCampaigns = [];
                    this.referralCampaigns[nameSpace].onlistCampaigns.next(this.valuesService.processServiceState.DONE);
                    // throw err;
                    return of(true);
                })
            );
    }

    listReferral(nameSpace, campaignId): Observable<any> {
        if (!this.referralCampaigns[nameSpace].markToUpdateListReferrals || this.noListing()) {
            return of(this.referralCampaigns[nameSpace].listReferrals);
        }

        if (this.referralCampaigns[nameSpace].onlistReferrals.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.referralCampaigns[nameSpace].onlistReferrals.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        }

        this.referralCampaigns[nameSpace].onlistReferrals.next(this.valuesService.processServiceState.INPROGRESS);
        return this.connectReferralService.listReferrals(campaignId)
            .pipe(
                map(
                    (resp) => {
                        if (resp) {
                            this.referralCampaigns[nameSpace].listReferrals = resp;
                            this.setReferralLink(nameSpace);
                            this.referralCampaigns[nameSpace].markToUpdateListReferrals = false;
                            this.referralCampaigns[nameSpace].onlistReferrals.next(this.valuesService.processServiceState.DONE);
                            return of(this.referralCampaigns[nameSpace].listReferrals);
                        } else {
                            this.referralCampaigns[nameSpace].markToUpdateListReferrals = true;
                            this.referralCampaigns[nameSpace].onlistReferrals.next(this.valuesService.processServiceState.DONE);
                        }
                    }
                ),
                catchError((err) => {
                    this.referralCampaigns[nameSpace].listReferrals = [];
                    this.referralCampaigns[nameSpace].markToUpdateListReferrals = true;
                    this.referralCampaigns[nameSpace].onlistReferrals.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );
    }

    getReferralV2(nameSpace, campaignId): Observable<any> {
        if (!this.referralCampaigns[nameSpace].markToUpdateGetReferral || this.noListing()) {
            return of(this.referralCampaigns[nameSpace].referralLink);
        }

        if (this.referralCampaigns[nameSpace].onGetReferral.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.referralCampaigns[nameSpace].onGetReferral.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        }

        this.referralCampaigns[nameSpace].onGetReferral.next(this.valuesService.processServiceState.INPROGRESS);
        return this.connectReferralService.getReferralV2({campaignId})
            .pipe(
                map(
                    (resp) => {
                        if (resp) {
                            this.referralCampaigns[nameSpace].referralLink = resp.referrals.length ? resp.referrals[0].url : '';
                            this.referralCampaigns[nameSpace].markToUpdateGetReferral = false;
                            this.referralCampaigns[nameSpace].onGetReferral.next(this.valuesService.processServiceState.DONE);
                            return of(this.referralCampaigns[nameSpace].referralLink);
                        } else {
                            this.referralCampaigns[nameSpace].markToUpdateGetReferral = true;
                            this.referralCampaigns[nameSpace].onGetReferral.next(this.valuesService.processServiceState.DONE);
                        }
                    }
                ),
                catchError((err) => {
                    this.referralCampaigns[nameSpace].referralLink = '';
                    this.referralCampaigns[nameSpace].markToUpdateGetReferral = true;
                    this.referralCampaigns[nameSpace].onGetReferral.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );
    }

    updateListCampaigns(nameSpace) {
        if (this.referralCampaigns[nameSpace].onlistCampaigns.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.referralCampaigns[nameSpace].markToUpdateListCampaigns = true;
        }
    }

    getListCampaigns(nameSpace) {
        return this.referralCampaigns[nameSpace].listCampaigns;
    }

    getListReferral(nameSpace) {
        return this.referralCampaigns[nameSpace].listReferrals;
    }

    getCurrentCampaign(nameSpace) {
        return this.referralCampaigns[nameSpace].currentCampaign;
    }

    getReferralLink(nameSpace) {
        return this.referralCampaigns[nameSpace].referralLink;
    }

    getCurrentReferralCode(nameSpace) {
        return this.referralCampaigns[nameSpace].referralCode;
    }

    setCurrentCampaign(nameSpace) {
        const campaigns = this.getListCampaigns(nameSpace);
        for (const campaign of campaigns) {
            if (campaign.campaign_id === this.referralValuesService.centralCampaignId) {
                this.referralCampaigns[nameSpace].currentCampaign = campaign;
                break;
            }
        }
    }

    setReferralLink(nameSpace) {
        const listReferrals = this.getListReferral(this.referralValuesService.nameSpaces.central);
        const campaignId    = this.getCurrentCampaign(this.referralValuesService.nameSpaces.central).campaign_id;
        if (listReferrals.length) {
            for (const referral of listReferrals) {
                if (referral.campaign_id === campaignId && referral.used_count < referral.usages) {
                    this.referralCampaigns[nameSpace].referralLink = referral.url;
                    this.referralCampaigns[nameSpace].referralCode = referral.code;
                    break;
                }
            }
        }
    }
    
    processCampaings(nameSpace) {
        for (const campaign of this.referralCampaigns[nameSpace].listCampaigns) {
            if (!campaign.precessed) {
                campaign.precessed = {};
            }

            if (!campaign.campaign_completed || this.showCompletedCampaign(campaign) ) {
                campaign.precessed.showCampaign = true;
            }
        }
    }

    openCongratsReferralModal(info) {
        const referralResponse = {
            isSource: true,
            info
        };
        const modalContent = {
            referralResponse,
            case: this.referralValuesService.referralCongratsCase.CONGRATS_ACTIVATE
        };
        this.modalRoutelessService.open(ModalName.referralCongratsModal, { size: ModalSize.SM}, modalContent);
    }

    showCompletedCampaign(campaign) {
        const now = moment();
        const completedOn = moment(campaign.campaign_completed_on, "X");

        const days = now.diff(completedOn, 'days');
        const showCompleted = days < 60;
        return showCompleted;
    }

    // - condition of appearence of Refferal module ----

    hasReferral(nameSpace) {
        const campaigns = this.getListCampaigns(nameSpace);
        if (Array.isArray(campaigns) && campaigns.length) {
            for (const campaign of campaigns) {
                if (campaign.campaign_id === this.referralValuesService.centralCampaignId
                    && campaign.expires_in > this.referralValuesService.expiresInLimit
                    && campaign.precessed.showCampaign) {
                    return true;
                }
            }
        }
        return false;
    }
    // ----
}