import { DeployType, Hosts } from '../config/config.service';

export enum LoadSdkScriptStatus {
    SUCCESS = 'success',
    ERROR = 'error'
}

export class CheckoutSdkProductPriceModel {
    product_id: string;
    devices_no: number;
    payment_period: string;
    billing_period: string;
}

export enum CheckoutSdkBuildSubjectTypes {
    AUTO_RENEWAL = 'autoRenewal',
    DEFAULT = 'default'
}

export enum CheckoutSdkFlows {
    centralPayment = 'centralPayment',
    updatePaymentMethod = 'update_payment_method'
}

export enum CheckoutProviders {
    twoCheckout = '2co'
}

interface CheckoutSDKDeploySettings {
    url?: string;
    public_key?: string;
}

interface CheckoutSDKHostSettings {
    [DeployType.BETA]: CheckoutSDKDeploySettings,
    [DeployType.PROD]: CheckoutSDKDeploySettings,
}

export type CheckoutSDKMapping = {
    [key in Hosts]?: CheckoutSDKHostSettings;
};

export interface CheckoutSdkConfig {
    key: string,
    country?: string,
    language: string,
    debug: boolean,
    request_timeout: number,
    element: string,
    default_scenario: string,
    update_analytics_enabled: boolean,
    disable_auto_generated_new_session: boolean,
    return_url: string
}

export enum WebHooks {
    ON_PAYMENT_FINALISE_SUCCESS = 'onPaymentFinalisedSuccess',
    ON_PAYMENT_FINALISE_ERROR = 'onPaymentFinalisedError'
}

export interface ProductDataNeededBySdk {
    product_id?: string;
    devices_no?: number;
    payment_period?: string;
    billing_period?: string;
    campaign?: string;
    redeemCode?: string;
};