// External
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

// Internal
import { DevicesService } from '../../process/devices/devices.service';
import { ProfilesService } from '../../process/profiles/profiles.service';
import { UsefulService } from '../../global/useful/useful.service';
import { UtilsCommonService } from '../../../../common/utils/utils-common.service';
import { ValuesService } from '../../../../common/values/values.service';

@Injectable({
    providedIn: 'root'
})
export class IThreatsChartService {

    constructor(
        private devicesService         : DevicesService,
        private profilesService        : ProfilesService,
        private translate              : TranslateService,
        private usefulService          : UsefulService,
        private utilsCommonService     : UtilsCommonService,
        private readonly valuesService : ValuesService
    ) {}

    translateLabelsChart(threatsDetails) {
        let days = this.usefulService.getNested(threatsDetails, [], 'chart', 'days');
        let chart = this.usefulService.getNested(threatsDetails, {}, 'chart');

        for (let i = 0; i < days.length; i++) {
            if (days[i] && Array.isArray(chart.labels)) {
                chart.labels[i] = this.translate.instant(chart.days[i].label);
            }
        }
    }

    computeLastSevenDays() {
        let days: any = {};

        for (let i = 0; i < 7; i++) {
            let date = new Date();
            date.setDate(date.getDate() - i);
            let formatedDate = this.usefulService.formatDate(date);

            days[formatedDate] = {};
            days[formatedDate]['threatsInfoPerDay'] = {};
            days[formatedDate]['totalThreats'] = 0;
            days[formatedDate]['date'] = formatedDate;

            if (i === 0) {
                days[formatedDate]['label'] = 'dashboard.threats.card.today';
            } else {
                let day = new Date(this.usefulService.formatDate(date)).getDay();
                days[formatedDate]['label'] = this.valuesService.daysWeek[day];
            }
        }

        return this.utilsCommonService.reverseObj(days);
    }

    computeThreatsInfoPerDay(report, day) {
        let totalThreats = 0;

        if (!day) {
            return;
        }

        for (let objectType in report) {
            if (objectType !== 'date' && objectType !== 'hour') {
                for (let threatType in report[objectType]) {

                    let keyThreat = this.valuesService.threatTypes[threatType];

                    if (day['threatsInfoPerDay'][keyThreat]) {
                        day['threatsInfoPerDay'][keyThreat] = day['threatsInfoPerDay'][keyThreat] + report[objectType][threatType];
                    } else {
                        day['threatsInfoPerDay'][keyThreat] = report[objectType][threatType];
                    }

                    totalThreats += report[objectType][threatType];
                }
            }
        }

        day['totalThreats'] = totalThreats;
    }

    createThreatsChartInterface(threats, reports, devicesIds) {
        let accountCreated = this.usefulService.formatDate(this.profilesService.getOwner().profile_created*1000);
        let days           = this.computeLastSevenDays();

        let totalThreats       = 0;
        let threatsPerDayArray = [];
        let labels             = [];
        let daysArray          = [];
        let firstActiveDay     = null;
        let maxThreats         = 0;
        let dayIndex           = 0;

        for (let i = 0; i < reports.length; i++) {
            let day = days[reports[i].date];
            this.computeThreatsInfoPerDay(reports[i], day);
        }

        for (let date in days) {
            dayIndex++;
            let totalThreatsPerDay = days[date]['totalThreats'];

            if (totalThreatsPerDay > maxThreats) {
                maxThreats = totalThreatsPerDay;
            }

            if (date === accountCreated) {
                firstActiveDay = dayIndex;
            }

            threatsPerDayArray.push(totalThreatsPerDay);
            labels.push(this.translate.instant(days[date].label));
            totalThreats = totalThreats + totalThreatsPerDay;
            daysArray.push(days[date]);
        }

        let devicesObject = this.devicesService.getDevicesObject();
        let devices = [];

        for (let i = 0; i < devicesIds.length; i++) {
            let device_id = devicesIds[i].device_id;
            let device = devicesObject[device_id];

            if (device) {
                devices.push(device);
            }
        }

        if (!threats['interface']) {
            threats['interface'] = {};
        }

        if (!threats['interface']['chart']) {
            threats['interface']['chart'] = {};
        }

        threats['interface']['numberOfThreats']         = totalThreats;
        threats['interface']['chart']['threatsPerDay']  = threatsPerDayArray;
        threats['interface']['chart']['days']           = daysArray;
        threats['interface']['chart']['labels']         = labels;
        threats['interface']['chart']['maxThreatsNum']  = maxThreats;
        threats['interface']['chart']['firstActiveDay'] = firstActiveDay;
        threats['interface']['activeDevices'] = devices;
    }

}
