// External
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

// Internal
import { WebmailProtectionValuesService } from '../../../values/webmailprotection.values.service';
import { WebmailProtectionService } from '../../process/security/webmailprotection.service';
import { DAYS_IN_WEEK, ONE_DAY_IN_MILISECONDS, ValuesService } from '../../../../common/values/values.service';
import { Security } from '../../../../common/models/security/Security.model';
import { ThreatInterface, ThreatsCounter, Threat } from '../../../../common/models/Threats.model';
import { UtilsCommonService } from '../../../../common/utils/utils-common.service';
import { DashboardValuesService } from '../../../../common/values/dashboard.values.service';
import { UsefulService } from '../../global/useful/useful.service';
import { DevicesService } from '../../process/devices/devices.service';
import { IDashboardService } from '../idashboard/idashboard.service';
import { BusinessService } from '../../process/business/business.service';
import {
    WebmailProtectionButton,
    WebmailProtectionChartDataset,
    IWebmailProtectionInboxes,
    IWebmailProtectionSummary,
    WebmailProtectionInboxProviders,
    WebmailProtectionTooltip,
    WebmailProtectionButtonTypes,
    WebmailProtectionInbox,
    WebmailProtectionInboxSummary,
    WebmailProtectionScanDescription,
    WebmailProtectionScanDescriptionStatus,
    WebmailProtectionSummary,
    WebmailProtectionThreat,
    WebmailProtectionInboxActivity,
    IWebmailProtectionInboxesActivity,
    WebmailProtectionInboxActions,
    WebmailProtectionActionDescription,
    WebmailProtectionProvider,
    IWebmailProtectionGroupedActivities
} from '../../../models/security/WebmailProtection.model';

export enum WebmailProtectionTypes {
    ATO = 'ato',
    SECURITY = 'security'
};

@Injectable({
    providedIn: 'root'
})

export class IWebmailProtectionService {

    private inboxesInterface: IWebmailProtectionInboxes = {};
    private summaryInterface: IWebmailProtectionSummary = {};
    private inboxesActivityInterface: IWebmailProtectionInboxesActivity = [];
    private totalScannedEmails = 0;

    private readonly threatsGroupedByTimeInterface: Security.Overview.ThreatsGroupedByTimeInterfaceLayer = {
        [Security.Overview.Filter.LAST_7D]: {},
        [Security.Overview.Filter.LAST_30D]: {}
    };

    private readonly threatsInterface: Security.Overview.ThreatsListInterfaceLayer = {
        [Security.Overview.Filter.LAST_7D]: [],
        [Security.Overview.Filter.LAST_30D]: []
    };

    constructor(
        private readonly webmailProtectionValuesService: WebmailProtectionValuesService,
        private readonly webmailProtectionService: WebmailProtectionService,
        private readonly valuesService: ValuesService,
        private readonly usefulService: UsefulService,
        private readonly translate: TranslateService,
        private readonly devicesService: DevicesService,
        private readonly dashboardValuesService: DashboardValuesService,
        private readonly iDashboardService: IDashboardService,
        private readonly utilsCommonService: UtilsCommonService,
        private readonly businessService: BusinessService
    ) {}

    /**
     * Function that computes available button actions for the given inbox.
     * @private
     * @memberof IWebmailProtectionService
     * @param {WebmailProtectionInbox} inbox The data of the given inbox.
     * @param {WebmailProtectionTypes} type The type of the webmail protection (ato/security).
     * @returns {WebmailProtectionButton[]} The available actions for each button.
     */
    private manageButtonActions(inbox: WebmailProtectionInbox, type: WebmailProtectionTypes): WebmailProtectionButton[] {
        let buttonType = '';
        const buttons = [
            this.webmailProtectionValuesService.webmailProtectionButtons.removeEmail
        ];

        if (type === WebmailProtectionTypes.ATO) {
            if (inbox.mailbox_not_available) {
                return buttons;
            }

            if (inbox.invalid_credentials) {
                buttonType = WebmailProtectionButtonTypes.RECONNECT_EMAIL;
                buttons.unshift(this.webmailProtectionValuesService.webmailProtectionButtons[buttonType]);
                return buttons;
            }
        }

        if (type === WebmailProtectionTypes.SECURITY && (inbox.mailbox_not_available || inbox.invalid_credentials)) {
            return buttons;
        }

        if (inbox.scan_active) {
            buttonType = WebmailProtectionButtonTypes.PAUSE_ACTIVITY;
        } else {
            buttonType = WebmailProtectionButtonTypes.RESUME_ACTIVITY;
        }
        buttons.push(this.webmailProtectionValuesService.webmailProtectionButtons[buttonType]);

        return buttons;
    }

    /**
     * Function that returns the provider identificator based on the value received from server.
     * @private
     * @memberof IWebmailProtectionService
     * @param {string} provider The email provider (gmail/outlook).
     * @returns {WebmailProtectionProvider} The provider identificator.
     */
    private getProviderImage(provider: string): WebmailProtectionProvider {
        const providerImg = {};

        switch (provider) {
            case WebmailProtectionInboxProviders.GMAIL:
                return this.valuesService.centralImages.providerGmail;
            case WebmailProtectionInboxProviders.OUTLOOK:
                return this.valuesService.centralImages.providerOutlook;
            default:
                break;
        }
        return providerImg;
    }

    /**
     * Function that gets the activity icon based on the value received from the server.
     * @private
     * @memberof IWebmailProtectionService
     * @param {WebmailProtectionInbox} inbox The inbox
     * @param {WebmailProtectionTypes} type The type of the webmail protection (ato/security).
     * @returns {WebmailProtectionScanDescription} The data for the inbox activity status.
     */
    private getActivityStatus(inbox: WebmailProtectionInbox, type: WebmailProtectionTypes): WebmailProtectionScanDescription {
        let scanStatus = '';
        if (inbox.mailbox_not_available) {
            scanStatus = WebmailProtectionScanDescriptionStatus.MAILBOX_NOT_AVAILABLE;
        } else if (inbox.invalid_credentials) {
            scanStatus = WebmailProtectionScanDescriptionStatus.INVALID_CREDENTIALS;
        } else if (inbox.scan_active) {
            scanStatus = WebmailProtectionScanDescriptionStatus.ACTIVE;
        } else {
            scanStatus = WebmailProtectionScanDescriptionStatus.PAUSED;
        }
        return this.webmailProtectionValuesService.webmailProtectionScanStatus[type][scanStatus];
    }

    /**
     * Function that gets the icon corresponding to the inbox action.
     * @private
     * @memberof IWebmailProtectionService
     * @param {string} actionName The action for the inbox: registered,removed,paused.
     * @returns {WebmailProtectionActionDescription} The icon data to be displayed.
     */
    private getActionStatus(actionName: string): WebmailProtectionActionDescription {
        let inboxAction;
        switch (actionName) {
            case WebmailProtectionInboxActions.REGISTERED:
                inboxAction = this.webmailProtectionValuesService.webmailProtectionInboxActions[WebmailProtectionInboxActions.REGISTERED];
                break;
            case WebmailProtectionInboxActions.PAUSED:
                inboxAction = this.webmailProtectionValuesService.webmailProtectionInboxActions [WebmailProtectionInboxActions.PAUSED];
                break;
            case WebmailProtectionInboxActions.REMOVED:
                inboxAction = this.webmailProtectionValuesService.webmailProtectionInboxActions[WebmailProtectionInboxActions.REMOVED];
                break;
            case WebmailProtectionInboxActions.RESUMED:
                inboxAction = this.webmailProtectionValuesService.webmailProtectionInboxActions[WebmailProtectionInboxActions.RESUMED];
                break;
            case WebmailProtectionInboxActions.SCAN_DISABLED:
                inboxAction = this.webmailProtectionValuesService.webmailProtectionInboxActions[WebmailProtectionInboxActions.SCAN_DISABLED];
                break;
            case WebmailProtectionInboxActions.CREDENTIALS_UPDATED:
                inboxAction = this.webmailProtectionValuesService.webmailProtectionInboxActions[WebmailProtectionInboxActions.CREDENTIALS_UPDATED];
                break;
            default:
                break;
        }
        return inboxAction;
    }

    /**
     * Compute tooltip value for webmail protection chart.
     * @private
     * @memberof IWebmailProtectionService
     * @param {WebmailProtectionSummary} day The summary of the given day.
     * @returns {WebmailProtectionTooltip} The necessary info used for displaying the tooltip for the given day.
     */
    private computeTooltipValue(day: WebmailProtectionSummary): WebmailProtectionTooltip {
        const tooltipValue = {
            summary: [],
            summaryValues: {
                safe: 0,
                dangerous: 0
            },
            threats: []
        };

        tooltipValue.summary = JSON.parse(JSON.stringify(this.webmailProtectionValuesService.tooltipSummaryTexts));
        tooltipValue.summaryValues.safe = day.clean ?? 0;
        tooltipValue.summaryValues.dangerous = this.getTotalEmailThreats(day) ?? 0;

        for (const threat of this.webmailProtectionValuesService.allWebmailProtectionThreatTypes) {
            if (threat in day) {
                const threatTooltip: WebmailProtectionThreat = {
                    text: '',
                    placeholder: {}
                };

                threatTooltip.text = this.webmailProtectionValuesService.tooltipThreatsTexts[threat];
                threatTooltip.placeholder = {
                    [threat]: day[threat]
                };
                tooltipValue.threats.push(threatTooltip);
            }
        }

        return tooltipValue;
    }

    /**
     * Function that gets the timestamp at midnight for the given timestamp.
     * @private
     * @memberof iWebmailProtectionService
     * @param {number} timestamp
     * @returns {number} The timestamp of midnight.
     */
    private resetTimestampToMidnight(timestamp: number): number {
        const date = new Date(timestamp);
        date.setHours(0, 0, 0, 0);
        return date.getTime();
    }

    /**
     * Function that groups all email activities per days.
     * @private
     * @memberof IWebmailProtectionService
     * @param {WebmailProtectionInboxActivity[]} activities The array of activities for each inbox.
     * @returns {IWebmailProtectionGroupedActivities} All email activities grouped by day.
     */
    private getGroupedInboxActivities(activities: WebmailProtectionInboxActivity[]): IWebmailProtectionGroupedActivities {
        const currentDay = this.resetTimestampToMidnight(new Date().getTime());
        const groupedActivities = {};

        for (const activity of activities) {
            let timestamp = this.resetTimestampToMidnight(activity.timestamp);
            if (currentDay === timestamp) {
                timestamp = 0;
            }

            if (!groupedActivities[timestamp]) {
                groupedActivities[timestamp] = [];
            }

            const activityItem = {
                ...activity,
                providerImg: this.getProviderImage(activity.inbox_provider),
                actionDescription: this.getActionStatus(activity.action)
            };
            groupedActivities[timestamp].push(activityItem);
        }
        return groupedActivities;
    }

    /**
     * Function that creates the inboxes object for each email address.
     * @public
     * @memberof IWebmailProtectionService
     * @param {WebmailProtectionTypes} type The type of the webmail protection (ato/security).
     */
    public createInboxesInterface(type: WebmailProtectionTypes): void {
        const inboxesInterface = {};

        const inboxes = this.webmailProtectionService.getInboxes();
        for (const inbox of inboxes) {
            if (!inboxesInterface[inbox.email]) {
                inboxesInterface[inbox.email] = {};
            }

            const inboxItem = {
                ...inbox,
                providerImg: this.getProviderImage(inbox.inbox_provider),
                scanDescription: this.getActivityStatus(inbox, type),
                buttonActions: this.manageButtonActions(inbox, type),
                protectionStatus: this.computeProtectionStatusesPerEmail(inbox.email),
                hasPermissions: !(inbox.mailbox_not_available || inbox.invalid_credentials),
                hasError: false
            };
            inboxesInterface[inbox.email] = inboxItem;
        }
        this.inboxesInterface = inboxesInterface;
    }

    /**
     * Function that updates the protection status for each existing inbox.
     * @private
     * @memberof IWebmailProtectionService
     * @param {string} email The email of the inbox that you compute the status to.
     */
    private computeProtectionStatusesPerEmail(email: string): WebmailProtectionInboxSummary {
        const inboxes = this.webmailProtectionService.getSummaryByInbox();
        for (const inbox of inboxes) {
            if (inbox.email === email) {
                return {
                    clean: inbox.clean,
                    dangerous: inbox.dangerous
                };
            }
        }
        return {};
    }

    /**
     * Function that creates the summary object for each email address.
     * @public
     * @memberof IWebmailProtectionService
     */
    public createSummaryInterface(): void {
        let auxTotalScannedEmail = 0;
        const auxSummaryInterface: IWebmailProtectionSummary = {};
        const summary = this.webmailProtectionService.getSummaryByDay();

        for (const day of summary) {
            const timestamp = (new Date(day.timestamp)).toISOString().split('T')[0];
            const totalThreats = this.getTotalEmailThreats(day);
            const totalClean = day.clean ?? 0;

            if (!auxSummaryInterface?.[timestamp]) {
                auxSummaryInterface[timestamp] = {};
            }

            auxSummaryInterface[timestamp] = day;
            auxSummaryInterface[timestamp].dangerous = totalThreats;

            auxTotalScannedEmail += totalClean + totalThreats;
        }
        this.summaryInterface = auxSummaryInterface;
        this.totalScannedEmails = auxTotalScannedEmail;
    }

    /**
     * Function that creates the inboxes activity interface.
     * @public
     * @memberof IWebmailProtectionService
     */
    public createInboxesActivityInteface(): void {
        const inboxesActivities = this.webmailProtectionService.getInboxesActivity();
        const groupedActivities = this.getGroupedInboxActivities(inboxesActivities);
        const activities = [];

        for (const activity in groupedActivities) {
            const inboxObj = {
                timestamp: Number(activity),
                activities: groupedActivities[activity]
            };
            activities.push(inboxObj);
        }
        this.inboxesActivityInterface = activities;
    }

    /**
     * Function that gets the total number of threats registered in one day.
     * @private
     * @memberof IWebmailProtectionService
     * @param {WebmailProtectionSummary} day The data for the given day.
     * @returns {number} The total threats number.
     */
    private getTotalEmailThreats(day: WebmailProtectionSummary): number {
        let totalThreats = 0;
        for (const threat of this.webmailProtectionValuesService.allWebmailProtectionThreatTypes) {
            if (threat in day) {
                totalThreats += day[threat];
            }
        }
        return totalThreats;
    }

    /**
     * Computes the datasets, labels and tooltips for webmail protection chart.
     * @public
     * @memberof IWebmailProtectionService
     * @param {Array<any>} chartData The all chart values.
     * @param {number} chartMaxValue The max value of the chart.
     * @returns {WebmailProtectionChartDataset} The data for datasets, labels and tooltips.
     */
    public createWebmailProtectionChartData(chartData: Array<any>, chartMaxValue: number): WebmailProtectionChartDataset {
        const chartDatasets = {
            labels: [],
            datasets: [],
            tooltips: []
        };

        this.updateEmptyArrayToMaxValues(chartData[1], chartMaxValue);

        chartDatasets.labels = chartData[0];
        chartDatasets.datasets.push({ data: chartData[3], backgroundColor: this.webmailProtectionValuesService.chartStyles.barColors.dangerous });
        chartDatasets.datasets.push({ data: chartData[2], backgroundColor: this.webmailProtectionValuesService.chartStyles.barColors.safe });
        chartDatasets.datasets.push({ data: chartData[1], backgroundColor: this.webmailProtectionValuesService.chartStyles.barColors.empty });
        chartDatasets.tooltips = chartData[4];
        return chartDatasets;
    }

    /**
     * Function that updates the empty array of values to the maximum chart value in order to have consistent design for all bars.
     * @private
     * @memberof IWebmailProtectionService
     * @param {Array<number>} chartValues The initial array of values that will be modified.
     * @param {number} chartMaxValue The max value of the chart.
     */
    private updateEmptyArrayToMaxValues(chartValues: Array<number>, chartMaxValue: number): void {
        for (const index in chartValues) {
            if (chartValues[index] === this.webmailProtectionValuesService.chartAxisMaxValues.yAxisValue) {
                chartValues[index] = chartMaxValue;
            }
        }
    }

    /**
     * Computes the yAxis and the tooltip values for the given datem if exists; otherwise, sets the values for the empty case.
     * @private
     * @memberof IWebmailProtectionService
     * @param {string} date The date for which it is desired to determine the values.
     * @returns {Array<number|object>} The array of the number values for the yAxis, and a number (if there are no records for the given date) or object for the tooltip value.
     */
    private getDailyValuesAndTooltipsForChart(date: string): Array<number|object> {
        let yValueEmpty = this.webmailProtectionValuesService.chartAxisMaxValues.yAxisValue;
        let yValueClean = 0;
        let yValueDangerous = 0;
        let tooltipValue = null;

        const daySummary = this.getWebmailSummaryPerDay(date);
        if (daySummary) {
            yValueEmpty = 0;
            yValueClean = daySummary.clean;
            yValueDangerous = daySummary.dangerous;
            tooltipValue = this.computeTooltipValue(daySummary);
        }
        return [yValueEmpty, yValueClean, yValueDangerous, tooltipValue];
    }

    /**
     * Computes the label values for the webmail protection chart.
     * Labels are set for the: xAxis, yAxis and tooltip;
     * The 'yAxis' is divided into 3 stacks, for the 3 possible cases: clean emails, dangerous emails, no data.
     * @public
     * @memberof IWebmailProtectionService
     * @returns {Array<any>} The aray of number values for xAxis and yAxis, and a number (if there are no records for the given date) or object for the tooltip value.
     */
    public computeChartValues(): Array<any> {
        const xValues = [];
        const yValueEmpty = new Array(this.webmailProtectionValuesService.chartMaxValue);
        const yValuesClean = new Array(this.webmailProtectionValuesService.chartMaxValue);
        const yValuesDangerous = new Array(this.webmailProtectionValuesService.chartMaxValue);
        const tooltipValues = new Array(this.webmailProtectionValuesService.chartMaxValue);
        const date = new Date();

        for (let i = this.webmailProtectionValuesService.chartAxisMaxValues.xAxisValue - 1; i >= 0; i--) {
            const monthDay = date.getDate();
            xValues.push(monthDay);

            const currentISODate = date.toISOString().split('T')[0];
            [yValueEmpty[i], yValuesClean[i], yValuesDangerous[i], tooltipValues[i]] = this.getDailyValuesAndTooltipsForChart(currentISODate);

            date.setTime(date.getTime() - ONE_DAY_IN_MILISECONDS);
        }

        return [xValues.reverse(), yValueEmpty, yValuesClean, yValuesDangerous, tooltipValues];
    }

    /**
     * Function that gets the inboxes interface.
     * @public
     * @memberof IWebmailProtectionService
     * @returns {WebmailProtectionInbox[]} The inboxes interface.
     */
    public getInterfaceInboxes(): WebmailProtectionInbox[] {
        return Object.values(this.inboxesInterface);
    }

    /**
     * Function that gets the total number of threats registered in one day.
     * @private
     * @memberof IWebmailProtectionService
     * @param {string} timestamp The date used for retrieving the summary data.
     * @returns {WebmailProtectionSummary|null} The data registered for the given date, if it exists.
     */
    private getWebmailSummaryPerDay(timestamp: string): WebmailProtectionSummary | null {
        return this.summaryInterface?.[timestamp] ?? null;
    }

    /**
     * Function that gets the inboxes activity interface.
     * @public
     * @memberof iWebmailProtectionService
     * @returns {IWebmailProtectionInboxesActivity} The inboxes activity
     */
    public getInboxesActivity(): IWebmailProtectionInboxesActivity {
        return this.inboxesActivityInterface;
    }

    /**
     * Function that gets the total scanned emails in all 30 days.
     * @public
     * @memberof IWebmailProtectionService
     * @returns {number} The total scanned emails.
     */
    public getTotalScannedEmails(): number {
        return this.totalScannedEmails ?? 0;
    }

     /**
     * Gets filtered threats grouped by time interface.
     * @param {Security.Overview.Filter} filter Threats overview filter.
     * @return {Security.Overview.ThreatsGroupedByTime} The filtered threats grouped by time.
     * @memberof ISecurityOverviewService
     */
     public getThreatsGroupedByTimeInterface(filter: Security.Overview.Filter): Security.Overview.ThreatsGroupedByTime {
        return this.threatsGroupedByTimeInterface[filter] ?? {};
    }

    /**
     * Gets filtered threats list interface.
     * @param {Security.Overview.Filter} filter Threats overview filter.
     * @return {ThreatInterface[]} The threats list.
     * @memberof ISecurityOverviewService
     */
    public getThreatsInterface(filter: Security.Overview.Filter): ThreatInterface[] {
        return this.threatsInterface[filter] ?? [];
    }

    /**
     * Genearate threats grouped by time interface
     * @param {Security.Overview.Filter} filter Threats overview filter
     * @param {boolean} isGroupType The flag to check if the threats are of type group in order to use corresponding threats info - optional parameter
     */
    public createThreatsGroupedByTimeInterface(filter: Security.Overview.Filter, isGroupType = false): void {
        const threatsByDays = isGroupType ? this.businessService.getGroupThreatReport(filter) : this.webmailProtectionService.getThreatsCount(filter);

        const auxFilteredThreatsGroupedByTimeInterface: Security.Overview.ThreatsGroupedByTime = {
            labels: [],
            threatsPerDay: Array(filter).fill(0),
            max: 0,
            total: 0,
            days: this.computeDaysForThreatsGroupedByTime(filter)
        };

        this.countThreatsGroupedByTimeInterface(threatsByDays, auxFilteredThreatsGroupedByTimeInterface);

        auxFilteredThreatsGroupedByTimeInterface.daysArray = Object.values<any>(auxFilteredThreatsGroupedByTimeInterface.days).reverse();
        if (filter === Security.Overview.Filter.LAST_7D) {
            auxFilteredThreatsGroupedByTimeInterface.labels = this.computeChartLabelsForThreatsGroupedByDays(Object.values(auxFilteredThreatsGroupedByTimeInterface.days));
            auxFilteredThreatsGroupedByTimeInterface.threatsPerDay.reverse();
        } else {
            auxFilteredThreatsGroupedByTimeInterface.labels = this.computeChartLabelsForThreatsGroupedByWeeks();
            auxFilteredThreatsGroupedByTimeInterface.weeks = this.computeWeeksForThreatsGroupedByTimeInterface(auxFilteredThreatsGroupedByTimeInterface.daysArray);
            auxFilteredThreatsGroupedByTimeInterface.threatsPerWeek = this.computeThreatsPerWeekForThreatsGroupedByTimeInterface(auxFilteredThreatsGroupedByTimeInterface.weeks);
        }

        this.threatsGroupedByTimeInterface[filter] = auxFilteredThreatsGroupedByTimeInterface;
    }

    /**
     * Generate threats list interface
     * @param {Security.Overview.Filter} filter Threats overview filter
     * @param {boolean} isGroupType The flag to check if the threats are of type group in order to use corresponding threats info - optional parameter
     */
    public createThreatsInterface(filter: Security.Overview.Filter, isGroupType = false): void {
        const threats = isGroupType ? this.businessService.getGroupThreats(filter) : this.webmailProtectionService.getThreatsDetails(filter);
        const newThreats = [];
        for (const threat of threats) {
            newThreats.push(this.createThreatInterface(threat));
        }
        this.threatsInterface[filter] = newThreats;
    }

    /**
     * Computes the days interface for grouped threats by time interface.
     * @private
     * @param {Security.Overview.Filter} filter Threats overview filter.
     * @returns {Security.Overview.DaysThreats} The computed days interface.
     * @memberof ISecurityOverviewService
     */
    private computeDaysForThreatsGroupedByTime(filter: Security.Overview.Filter): Security.Overview.DaysThreats {
        const days: Security.Overview.DaysThreats = {};
        for (let i = 0; i < filter; i++) {
            const date = new Date();
            date.setDate(date.getDate() - i);
            const formatedDate = this.usefulService.formatDate(date);
            days[formatedDate] = {
                threatsInfoPerDay: {},
                totalThreats: 0,
                date: formatedDate
            } as Security.Overview.DayThreats;

            if (i === 0) {
                days[formatedDate].label = 'dashboard.threats.card.today';
            } else {
                const day = new Date(this.usefulService.formatDate(date)).getDay();
                days[formatedDate].label = this.valuesService.daysWeek[day];
            }
        }
        return days;
    }

    /**
     * Translates the labesl for the overview 7days chart.
     * @private
     * @param {Security.Overview.DayThreats[]} days The days object that contain the labels.
     * @return {string[]} The translated labels.
     * @memberof ISecurityOverviewService
     */
    private computeChartLabelsForThreatsGroupedByDays(days: Security.Overview.DayThreats[]): string[] {
        const labels = [];
        for (const day of days) {
            labels.push(this.translate.instant(day.label));
        }
        return labels.reverse();
    }

    /**
     * Translates the labels for the overview 30days chart.
     * @private
     * @return {number[]} The translated labels.
     * @memberof ISecurityOverviewService
     */
    private computeChartLabelsForThreatsGroupedByWeeks(): number[] {
        const labels = [];
        const numberOfWeeks = Math.ceil(Security.Overview.Filter.LAST_30D / DAYS_IN_WEEK);

        for (let i = 0; i < numberOfWeeks; i++) {
            labels.push(i+1);
        }
        return labels;
    }



    /**
     * Counts the threats for the grouped by time interface and populates the interface structures.
     * @private
     * @param {ThreatsCounter[]} threatsByDays Threats grouped by time list.
     * @param {Security.Overview.ThreatsGroupedByTime} auxFilteredThreatsGroupedByTimeInterface The auxiliary interface to be populated.
     * @memberof ISecurityOverviewService
     */
    private countThreatsGroupedByTimeInterface(threatsByDays: ThreatsCounter[], auxFilteredThreatsGroupedByTimeInterface: Security.Overview.ThreatsGroupedByTime): void {
        const days = Object.keys(auxFilteredThreatsGroupedByTimeInterface.days);
        for (const threatsByDay of threatsByDays) {
            for (const objectType in threatsByDay) {
                if (!threatsByDay[objectType] || typeof threatsByDay[objectType] !== 'object') {
                    continue;
                }

                for (const threatType in threatsByDay[objectType]) {
                    const threatCounter = threatsByDay[objectType][threatType];
                    const threatTypeValue = this.valuesService.threatTypes[threatType];
                    if (auxFilteredThreatsGroupedByTimeInterface.days[threatsByDay.date]) {
                        auxFilteredThreatsGroupedByTimeInterface.days[threatsByDay.date].totalThreats += threatCounter;
                        if (!auxFilteredThreatsGroupedByTimeInterface.days[threatsByDay.date].threatsInfoPerDay[threatTypeValue]) {
                            auxFilteredThreatsGroupedByTimeInterface.days[threatsByDay.date].threatsInfoPerDay[threatTypeValue] = 0;
                        }
                        auxFilteredThreatsGroupedByTimeInterface.days[threatsByDay.date].threatsInfoPerDay[threatTypeValue] += threatCounter;
                    }
                }
            }
            if (auxFilteredThreatsGroupedByTimeInterface.days[threatsByDay.date]?.totalThreats > auxFilteredThreatsGroupedByTimeInterface.max) {
                auxFilteredThreatsGroupedByTimeInterface.max = auxFilteredThreatsGroupedByTimeInterface.days[threatsByDay.date].totalThreats;
            }
            const dayIndex = days.indexOf(threatsByDay.date);
            if (dayIndex >= 0) {
                auxFilteredThreatsGroupedByTimeInterface.threatsPerDay[dayIndex] = auxFilteredThreatsGroupedByTimeInterface.days[threatsByDay.date].totalThreats;
                auxFilteredThreatsGroupedByTimeInterface.total += auxFilteredThreatsGroupedByTimeInterface.days[threatsByDay.date].totalThreats;
            }
        }
    }

    /**
     * Computes the weeks from grouped threats by time interface.
     * @private
     * @param {Security.Overview.DayThreats[]} daysArray The array containing the days information.
     * @return {Security.Overview.WeekThreats[]} The weeks array for the grouped interface.
     * @memberof ISecurityOverviewService
     */
    private computeWeeksForThreatsGroupedByTimeInterface(daysArray: Security.Overview.DayThreats[]): Security.Overview.WeekThreats[] {
        const numberOfWeeks = Math.ceil(Security.Overview.Filter.LAST_30D / DAYS_IN_WEEK);
        const threatsPerWeek = [];
        const weeks: Security.Overview.WeekThreats[] = [];

        for (let i = 0; i < numberOfWeeks; i++) {
            const stratIndex = DAYS_IN_WEEK * i;
            const finalIndex = stratIndex + DAYS_IN_WEEK;
            const days = daysArray.slice(stratIndex, finalIndex);
            let weekTotal = 0;
            for (const day of days) {
                weekTotal += day.totalThreats;
            }
            threatsPerWeek.push(weekTotal);
            const week: Security.Overview.WeekThreats = {
                label: this.webmailProtectionValuesService.weekTooltip.title,
                totalThreats: weekTotal,
                days: days
            };
            weeks[i] = week;
        }
        return weeks;
    }

    /**
     * Computes the array with the number of threats for every week.
     * @private
     * @param {Security.Overview.WeekThreats[]} weeks The array with the threat information for every week.
     * @return {number[]} The threats per week array.
     * @memberof ISecurityOverviewService
     */
    private computeThreatsPerWeekForThreatsGroupedByTimeInterface(weeks: Security.Overview.WeekThreats[]): number[] {
        const threatsPerWeek = [];
        for (const week of weeks) {
            threatsPerWeek.push(week.totalThreats);
        }
        return threatsPerWeek;
    }

    /**
     * Computes the threat info interface.
     * @private
     * @memberof ISecurityOverviewService
     * @param {Threat} threat The threat object that the funciton computes the interface for.
     * @return {ThreatInterface} The object containing the threat interface.
     */
    private createThreatInterface(threat: Threat): ThreatInterface {
        const devices      = this.devicesService.getDevicesObject();
        const device       = devices[threat.device_id];
        const appId        = threat?.app_id;

        const info: ThreatInterface = {
            text: {},

            threatType   : this.iDashboardService.computeThreatType(threat),
            threatSubtype: this.iDashboardService.computeThreatSubtype(threat),
            objectType   : threat?.object_type,
            object       : threat.object,

            ip           : threat?.object?.ip,
            lanInitiated : threat?.object?.lan_initiated,
            srcIp        : threat?.object?.src_ip,
            protocol     : threat?.object?.protocol,
            port         : threat?.object?.port,
            extPort      : threat?.object?.extPort,
            malware      : threat?.object?.malware,
            fileName     : threat?.fileName,
            url          : threat?.object?.url ?? threat?.object?.blocked_url,

            device: {
                boxName: device && device.box_device_id && devices[device.box_device_id] ? devices[device.box_device_id].display_name : "",
                name: device?.display_name ?? '',
                protectionStatus: device?.processed.protectionStatus,
                icon: device?.device_icon ?? '',
                onBox: this.valuesService.boxApps.has(appId),
            },
            date: {
                timestamp: new Date(threat.date).getTime()
            }
        };

        if (info.fileName) {
            info.appOrFile = info.fileName;
        }

        if (info.objectType === this.valuesService.threatObjectType.app
            || info.objectType === this.valuesService.threatObjectType.file
            || info.objectType === this.valuesService.threatObjectType.url
            || info.objectType === this.valuesService.threatObjectType.behaviour
            || info.objectType === this.valuesService.threatObjectType.process) {
            info.appOrFile = this.utilsCommonService.checkString(info.object) ? info.object : '';
        }

        const defaultValue                  = this.dashboardValuesService.threatsDetailsModule.default;
        const defaultDetails                = this.dashboardValuesService.threatsDetailsModule?.[info.threatType]?.default;
        const objectTypeypeDetails          = this.dashboardValuesService.threatsDetailsModule?.[info.threatType]?.[info.objectType];
        const subtypeDetails                = this.dashboardValuesService.threatsDetailsModule?.[info.threatType]?.[info.threatSubtype];
        const boxDetails                    = this.dashboardValuesService.threatsDetailsModule?.[info.threatType]?.box;
        const boxDefaultDetails             = this.dashboardValuesService.threatsDetailsModule?.[info.threatType]?.box?.default;
        const boxSubtypeDetails             = this.dashboardValuesService.threatsDetailsModule?.[info.threatType]?.box?.[info.threatSubtype];
        const boxSubtypeLanInitiatedDetails = this.dashboardValuesService.threatsDetailsModule?.[info.threatType]?.box?.[info.threatSubtype]?.[info.lanInitiated];

        if (info.device.onBox && boxDetails) {
            info.text = boxSubtypeLanInitiatedDetails || boxSubtypeDetails || boxDefaultDetails || defaultValue;
        } else {
            info.text = subtypeDetails || objectTypeypeDetails || defaultDetails || defaultValue;
        }
        return info;
    }

    /**
     * Function that sets the loading description depending on the action took by the user
     * @private
     * @memberof WebmailProtectionAccountsComponent
     * @param {WebmailProtectionInbox} inboxObj The inbox object
     * @param {WebmailProtectionButtonTypes} actionType The action realised by the user.
     */
    public setInboxLoading(inboxObj: WebmailProtectionInbox, actionType: WebmailProtectionButtonTypes): void {
        inboxObj.loadingDescription = this.webmailProtectionValuesService.webmailProtectionLoadingDescription?.[actionType] ?? '';
        inboxObj.isLoading = true;
    }

    /**
     * Function that resets the loading description of the inbox
     * @param {WebmailProtectionInbox} inboxObj The inbox object
     */
    public resetInboxLoading(inboxObj: WebmailProtectionInbox): void {
        inboxObj.loadingDescription = '';
        inboxObj.isLoading = false;
    }

    /**
     * Function that sets the error flag of the inbox
     * @param {WebmailProtectionInbox} inboxObj The inbox object
     */
    public setInboxError(inboxObj: WebmailProtectionInbox): void {
        inboxObj.hasError = true;
    }

    /**
     * Function that resets the error flag of the inbox
     * @param {WebmailProtectionInbox} inboxObj The inbox object
     */
    public resetInboxError(inboxObj: WebmailProtectionInbox): void {
        inboxObj.hasError = false;
    }

}
