// External
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, catchError, map, skipWhile } from 'rxjs';

// Internal
import { ValuesService } from '../../../values/values.service';
import { ConnectGroupMgmtService, RequestStatus } from '../../requests/connect-group-mgmt/connect-group-mgmg.service';
import { BusinessEvents, GroupRoleUI } from '../../../../common/values/business.values.service';
import { ProfilesService } from '../profiles/profiles.service';
import { ConnectMgmtService } from '../../requests/connect-mgmt-service/connect-mgmt.service';
import { MessageService } from '../../core/message.service';
import {
    GroupMember,
    GroupMembersObject,
    GroupMgmtErrorCodes,
    GroupRoles,
    MembersColors,
    GroupProductsReport,
    GroupMemberProcessed,
    GroupMemberLabel,
    CreateGroupParams,
    CreateGroupResponse
} from '../../../models/subscriptions/Groups.model';
import { FamilyMember } from 'src/app/common/values/family.values.service';

const PAGE_SIZE = 15;

interface SecurityActivity {
    list: Array<any>;
    pageNumber: number;
    endOfList?: boolean;
}

export interface SecurityActivityEventsRequestParams {
    group_id: string;
    size: number;
    page_number: number;
}

@Injectable({
    providedIn: 'root'
})

export class GroupManagementService {
    private readonly onlistSecurityActivity$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private createFamilyGroupLoading = false;
    private markToUpdateGroupMembers = true;
    private markToUpdateSecurityActivity = true;
    private markToUpdateGroupStatus = true;
    private readonly securityActivity: SecurityActivity = {
        list: [],
        pageNumber: 0,
        endOfList: false
    };
    private groupStatus: GroupProductsReport[] = [];
    private readonly onlistGroupStatus$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private readonly onlistGroupMembers$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private members = [] as Array<GroupMember|FamilyMember>;
    private membersObject: GroupMembersObject = {};

    constructor(
        private readonly valuesService: ValuesService,
        private readonly connectGroupMgmtService: ConnectGroupMgmtService,
        private readonly connectMgmtService: ConnectMgmtService,
        private readonly profileService: ProfilesService,
        private readonly messageService: MessageService
    ) {}

    /**
     * Makes requests needed for listing groups and group's members
     * For each of the user's assigned group an entry will be inserted containing data about the group
     * @public
     * @memberof GroupManagementService
     * @param {string} groupId The group id
     * @returns {Observable} The server response
     */
    public listGroupMembers(groupId: string): Observable<any> {
        if (!this.markToUpdateGroupMembers
            || !groupId
            || !(this.profileService.isOwnerATOAdmin()
                || this.profileService.isOwnerVSBAdmin()
                || this.profileService.isFamilyPlanAdmin())) {
            return of(this.members);
        }

        if (this.onlistGroupMembers$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onlistGroupMembers$.asObservable()
            .pipe(
                skipWhile(res => res !== this.valuesService.processServiceState.DONE)
            );
        } else {
            this.onlistGroupMembers$.next(this.valuesService.processServiceState.INPROGRESS);

            return this.connectGroupMgmtService.listMembers(groupId)
            .pipe(
                map(resp => {
                    this.members = resp;
                    this.computeGroupMembersObject();
                    this.onlistGroupMembers$.next(this.valuesService.processServiceState.DONE);
                    this.markToUpdateGroupMembers = false;
                    return true;
                }),
                catchError(err => {
                    this.onlistGroupMembers$.next(this.valuesService.processServiceState.DONE);
                    return of(err);
                })
            );
        }
    }

    /**
     * Compares 2 timestamps to determine if they belong to the same day
     * @param {number} timestamp1 First timestamp
     * @param {number} timestamp2 Second timestamp
     * @returns {boolean} True if they belong to the same day
     */
    private sameDay(timestamp1: number, timestamp2: number): boolean {
        return new Date(timestamp1).setHours(0, 0, 0, 0) === new Date(timestamp2).setHours(0, 0, 0, 0);
    }

    /**
     * Function to group group_member_invited events into group_members_invited
     * @param events
     * @returns {any[]} List of events with group_member_invited grouped
     */
    private groupInvitationEvents(events): any[] {
        const finalEvents = [];
        let newInviteEvent = null;
        for (let i = 0; i < events.length; i++) {
            if (events[i].event_type === BusinessEvents.GROUP_MEMBER_INVITED) {
                if (!newInviteEvent) {
                    newInviteEvent = events[i];
                    newInviteEvent.data.members = [newInviteEvent.data.member_id];
                } else {
                    if (this.sameDay(events[i].timestamp, newInviteEvent.timestamp)) {
                        newInviteEvent.data.members.push(events[i].data.member_id);
                        newInviteEvent.event_type = BusinessEvents.GROUP_MEMBERS_INVITED;
                    } else {
                        finalEvents.push(newInviteEvent);
                        newInviteEvent = events[i];
                        newInviteEvent.data.members = [newInviteEvent.data.member_id];
                    }
                }
            } else {
                if (newInviteEvent
                    && (!this.sameDay(events[i].timestamp, newInviteEvent.timestamp)
                        || (i === events.length - 1
                            && events[i].event_type === BusinessEvents.GROUP_CREATED))) {
                    finalEvents.push(newInviteEvent);
                    newInviteEvent = null;
                }
                finalEvents.push(events[i]);
            }
        }

        if (newInviteEvent) {
            finalEvents.push(newInviteEvent);
        }

        return finalEvents;
    }

    /**
     * Laod security activity events
     * @public
     * @memberof GroupManagementService
     * @param {boolean} loadMore if true, load more events
     * @returns {Observable<any>} server response
     */
    public listSecurityActivity(loadMore?: boolean): Observable<any> {
        if ((!this.markToUpdateSecurityActivity && !loadMore) || this.securityActivity.endOfList) {
            return of(this.securityActivity);
        }
        if (this.onlistSecurityActivity$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onlistSecurityActivity$.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        } else {
            this.onlistSecurityActivity$.next(this.valuesService.processServiceState.INPROGRESS);
            const info: SecurityActivityEventsRequestParams = {
                group_id: this.profileService.getCurrentGroupId(),
                size: PAGE_SIZE,
                page_number: loadMore ? this.securityActivity.pageNumber + 1 : 1
            };
            return this.connectGroupMgmtService.listSecurityActivity(info)
            .pipe(
                map((resp) => {
                    this.securityActivity.list = this.securityActivity.list.concat(this.groupInvitationEvents(resp));
                    if (resp.length < PAGE_SIZE) {
                        this.securityActivity.list.push({
                            event_type: BusinessEvents.GROUP_STARTING_POINT
                        });
                        this.securityActivity.endOfList = true;
                    }
                    this.securityActivity.pageNumber = info.page_number;
                    this.onlistSecurityActivity$.next(this.valuesService.processServiceState.DONE);
                    this.markToUpdateSecurityActivity = false;
                    return resp;
                }),
                catchError(err => {
                    this.onlistSecurityActivity$.next(this.valuesService.processServiceState.DONE);
                    this.markToUpdateSecurityActivity = true;
                    throw err;
                })
            );
        }
    }

    /**
     * Removes group members
     * @public
     * @memberof GroupManagementService
     * @param {string} groupId the id of the group on which the removal is requested
     * @param {Array<string>} memberIds array of member memberIds to be removed from group
     * @returns {Observable} server response
     */
    public removeGroupMembers(groupId: string, memberIds: Array<string>): Observable<any> {
        return this.connectGroupMgmtService.removeMembers(groupId, memberIds)
        .pipe(
            map(results => {
                const auxMembers = [];
                const failed = [];
                const members = this.members ?? [];
                for (const member of members) {
                    let found = false;
                    for (const memberId in results) {
                        if (memberId === member.member_id) {
                            found  = true;
                            if (results[memberId] === RequestStatus.FAILED) {
                                failed.push(memberId);
                                auxMembers.push(member);
                            }
                            break;
                        }
                    }
                    if (!found) {
                        auxMembers.push(member);
                    }
                }
                this.members = auxMembers;
                if (failed.length) {
                    throw failed;
                }
                return of(true);
            }),
            catchError(err => {
                if (Array.isArray(err)) {
                    return of(err);
                } else {
                    throw err;
                }
            })
        );
    }

    /**
     * Updates the role of a group memmber
     * @public
     * @memberof GroupManagementService
     * @param {string} groupId The group id on which the update is requested
     * @param {string} memberId The member id for which the role should be updated
     * @param {GroupRoles} role The new role of the member
     * @returns {Observable} The request response
     */
    public updateGroupMemberRole(groupId: string, memberId: string, role: GroupRoles): Observable<any> {
        return this.connectGroupMgmtService.updateMember(groupId, memberId, role)
        .pipe(
            map(() => {
                const members = this.members ?? [];
                for (const member of members) {
                    if (member.member_id === memberId) {
                        member.role = role;
                        break;
                    }
                }
                return true;
            }),
            catchError(err => {
                if (Array.isArray(err)) {
                    return of(err);
                } else {
                    throw err;
                }
            })
        );
    }

    /**
     * Invites more memebrs to a group
     * @param {string} groupId The group id
     * @param {Array<string>} emails The emails of the members to be invited
     * @param {GroupRoles} role The role of the owner of the account
     * @returns {Observable<any>} server response
     */
    public inviteGroupMembers(groupId: string, emails: Array<string>, role: GroupRoles, teenagerBirthday?: number): Observable<any> {
        return this.connectGroupMgmtService.inviteMembers(groupId, emails, role, teenagerBirthday)
        .pipe(
            map(results => {
                const failed = [];
                for (const email in results) {
                    if (results[email].status === RequestStatus.FAILED) {
                        failed.push(email);
                    } else {
                        const member: GroupMember = {
                            email: email,
                            name: '',
                            role: role,
                            created: new Date().getTime(),
                            invite_accepted: false,
                            member_id: results[email].memberId,
                        }

                        if (teenagerBirthday) {
                            member.metadata = {
                                birthdate: teenagerBirthday
                            };
                        }
                        this.members.push(member);
                    }
                }

                if (failed.length) {
                    throw failed;
                }
                return true;
            }),
            catchError(err => {
                // need to throw if error has code and return if it's just the array of failed results
                if (Array.isArray(err)) {
                    return of(err);
                } else {
                    throw err;
                }
            })
        );
    }

    /**
     * Creates a family group
     * @param {CreateGroupParams} familyGroupParams The parameters for the family group creation
     * @returns {Observable<CreateGroupResponse>} server response
     */
    public createFamilyGroupWrapper(familyGroupParams: CreateGroupParams): Observable<CreateGroupResponse> {
        if (this.createFamilyGroupLoading || this.profileService.hasFamilyGroup()) {
            return of(this.profileService.getCurrentGroup());
        }

        this.createFamilyGroupLoading = true;
        return this.connectGroupMgmtService.createGroup(familyGroupParams)
        .pipe(
            map((response: CreateGroupResponse) => {
                this.profileService.addFamilyGroup(response);
                this.addFamilyMember(response);
                this.messageService.sendDynamicSubjectMessage(this.valuesService.events.dataResolverDone, {});
                this.createFamilyGroupLoading = false;
                return response;
            }),
            catchError(error => {
                this.createFamilyGroupLoading = false;
                throw error;
            })
        );
    }

    /**
     * Adds a family member to the group
     * @param {CreateGroupResponse} createGroupResponse The response from the server after creating the group
     */
    private addFamilyMember(createGroupResponse: CreateGroupResponse): void {
        const familyMember: FamilyMember = {
            context_id: createGroupResponse.context_id,
            created: Date.now(),
            email: this.profileService.getOwner().email,
            invite_accepted: true,
            member_id: createGroupResponse.member_id,
            name: createGroupResponse.name,
            role: GroupRoles.FAMILY_PRIMARY_ADMIN,
        };
        this.members.push(familyMember);
        this.computeGroupMembersObject();
    }

    /**
     * Updates the group label (Group name)
     * @param {string} groupId The group id for which the update should happen
     * @param {string} groupLabel The new label of the group
     * @returns {Observable<any>} server response
     */
    public updateGroup(groupId: string, groupLabel: string): Observable<any> {
        return this.connectGroupMgmtService.updateGroup(groupId, groupLabel)
        .pipe(
            map(resp => {
                if (resp.status === 0) {
                    const group = this.profileService.getCurrentGroup();
                    group.group_label = groupLabel;
                    this.messageService.sendDynamicSubjectMessage(this.valuesService.events.group.updateGroup, {});
                    return of(true);
                }

                throw resp;
            }),
            catchError(err => {
                throw err;
            })
        );
    }

    /**
     * Retrieves the group status for certain apps
     * @public
     * @memberof GroupManagementService
     * @returns {Observable<any>} server response
     */
    public listGroupStatus(): Observable<any> {
        if (!this.markToUpdateGroupStatus) {
            return of(this.groupStatus);
        }

        if (this.onlistGroupStatus$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onlistGroupStatus$.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        } else {
            this.onlistGroupStatus$.next(this.valuesService.processServiceState.INPROGRESS);
            const groupId = this.profileService.getCurrentGroupId();
            return this.connectMgmtService.listGroupStatus(groupId)
            .pipe(
                map(resp => {
                    this.groupStatus = resp;
                    this.onlistGroupStatus$.next(this.valuesService.processServiceState.DONE);
                    this.markToUpdateGroupStatus = false;
                    return resp;
                }),
                catchError(err => {
                    this.onlistGroupStatus$.next(this.valuesService.processServiceState.DONE);
                    this.markToUpdateGroupStatus = true;
                    return of(err);
                })
            );
        }
    }

    /**
     * Returns all crrent group members
     * @returns {Array<GroupMember>} the group members
     */
    public getGroupMembers(): Array<GroupMember|FamilyMember> {
        return this.members ?? [];
    }

    /**
     * Returns object containing group members by context_id
     * @returns {GroupModel} the group members by their context_id
     */
    public getGroupMemberByContextId(contextId): GroupMemberProcessed {
        return this.membersObject[contextId] ?? null;
    }

    /**
     * Computes the group members object for easier access to color and member
     */
    public computeGroupMembersObject(): void {
        const membersObj: GroupMembersObject = {};
        let colorIndex = 0;
        for (const member of this.members) {
            if (!member.context_id) {
                continue;
            }

            member.wrapper_id = member.context_id;
            let color = null;
            if (member.role === GroupRoles.VSB_PRIMARY_ADMIN) {
                color = MembersColors.PRIMARY;
            } else {
                color = this.valuesService.membersColorsArray[colorIndex % this.valuesService.membersColorsArray.length];
                colorIndex++;
            }
            membersObj[member.context_id]  = {
                member,
                color
            };
        }

        this.membersObject = membersObj;
    }

    public getMemberColor(contextId: string): MembersColors {
        return this.membersObject[contextId]?.color ?? '' as MembersColors;
    }

    /**
     * Returns the group status
     * @returns {GroupProductsReport[]} the group status
     */
    public getGroupStatus(): GroupProductsReport[] {
        return this.groupStatus ?? [];
    }

    /**
     * Mark groups to update on next list members
     * @public
     * @memberof GroupManagementService
     * @returns {void} nothing
     */
    public updateGroupMembers(): void {
        if (this.onlistGroupMembers$.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.markToUpdateGroupMembers = true;
        }
    }

    /**
     * Used to compute admin invites
     * @returns {boolean} true if sec admin was invited
     */
    public secondaryAdminAlreadyInvited(): boolean {
        for (const member of this.members) {
            if (member.role === GroupRoles.VSB_SECONDARY_ADMIN) {
                return true;
            }
        }
        return false;
    }

    /**
     * Return the events for the security activity card.
     * @returns {Array<any>} the security activity events
     */
    public getSecurityActivity(): Array<any> {
        return this.securityActivity?.list ?? [];
    }

    /**
     * Returns the end of list status for the security activity events
     * @returns {boolean} true if the end of list has been reached
     */
    public getSecurityActivityEndOfList(): boolean {
        return this.securityActivity?.endOfList ?? false;
    }

    /**
     * Handles error message from connect/group_mgmt service and decides what error to be displayed
     * @public
     * @memberof GroupManagementService
     * @param {object} errorObject The error object
     * @returns {string} The error message to be displayed
     */
    public handleGroupMgmtErrorResponse(errorObject?): string {
        let errorMessage = this.valuesService.groupManagementErrorTexts[GroupMgmtErrorCodes.DEFAULT];

        if (errorObject) {
            const internalCode =  errorObject.message?.internal_code ?? errorObject.internal_code;
            const dataCode = errorObject.message?.internal_data?.code ?? errorObject.code;
            const code = errorObject.message?.code;

            if (this.valuesService.groupManagementErrorTexts[internalCode]?.[dataCode]) {
                errorMessage = this.valuesService.groupManagementErrorTexts[internalCode][dataCode];
            } else if (this.valuesService.groupManagementErrorTexts[code]) {
                errorMessage = this.valuesService.groupManagementErrorTexts[code];
            }
        }
        return errorMessage as string;
    }

    /**
     * Returns the role label for the group member
     * @param {GroupRoles} role  member role
     * @param {GroupMemberLabel} memberLabel
     * @returns {string} group role label
     */
    public getGroupRoleLabel(role: GroupRoles, memberLabel?: GroupMemberLabel): string {
        const label = GroupRoleUI?.[role]?.label;
        return label?.[memberLabel] ?? label?.default;
    }

    /**
     * Gets member by id
     * @param {string} memberId  member id
     * @returns {GroupMember} group member
     */
    public getMemberById(memberId: string): GroupMember|FamilyMember|null {
        for (const member of this.members) {
            if (member.member_id === memberId) {
                return member;
            }
        }
        return null;
    }

    /**
     * Checks if the group has an active secondary admin
     * @public
     * @memberof GroupManagementService
     * @returns {boolean} Returns true if the group has an active secondary admin, false otherwise
     */
    public hasActiveSecondaryAdmin(): boolean {
        for (const member of this.members) {
            if (member?.role === GroupRoles.FAMILY_SECONDARY_ADMIN && member?.invite_accepted) {
                return true;
            }
        }
        return false;
    }
}