// External
import { NgModule } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';

//Internal
import {
    DateFormatPipe,
    LocalizedDatePipe,
    IdentitiesCounterPipe,
    SafePipe,
    FirstKeyPipe,
    SafeHtmlPipe,
    TruncateText,
    CallbackItemPipe,
    ToHours,
    Alphabetically,
    SortedLimits,
    OrderValuesByNamePipe,
    NewsDate,
    GroupByType,
    TimeAgoDatePipe,
    Capitalize,
    SortPipe,
    SortAlphabeticallyPipe,
    CustomDevicesFilterPipe,
    GroupExposedData,
    TranslateListPipe,
    FilterByPropertyPipe,
    CountPipe,
    TimeFromMilisecondsPipe,
    ParentalMorePipe,
    TimeFromSecondsPipe,
    HourMinuteInterval,
    TopicsMorePipe,
    HoursFromSeconds,
    OrderByPropertyPipe,
    DaysAgoPipe,
    ThreeDigitsFormat,
    WrappingPipe,
    WrappingPipesArray,
    BoldStringSectionPipe,
    BigNumbersCounterPipe,
    JoinArrayPipe,
    DisplayImagePipe
} from './pipes';

import { LanguageService } from '../services/core/language.service';
@NgModule({
    declarations: [
        DateFormatPipe,
        LocalizedDatePipe,
        IdentitiesCounterPipe,
        SafePipe,
        SafeHtmlPipe,
        FirstKeyPipe,
        TimeAgoDatePipe,
        TruncateText,
        OrderValuesByNamePipe,
        GroupByType,
        CallbackItemPipe,
        ToHours,
        Alphabetically,
        SortedLimits,
        NewsDate,
        Capitalize,
        SortPipe,
        SortAlphabeticallyPipe,
        CustomDevicesFilterPipe,
        TranslateListPipe,
        GroupExposedData,
        FilterByPropertyPipe,
        OrderByPropertyPipe,
        CountPipe,
        CustomDevicesFilterPipe,
        TimeFromMilisecondsPipe,
        TimeFromSecondsPipe,
        ParentalMorePipe,
        TopicsMorePipe,
        HoursFromSeconds,
        HourMinuteInterval,
        DaysAgoPipe,
        ThreeDigitsFormat,
        WrappingPipe,
        WrappingPipesArray,
        BoldStringSectionPipe,
        BigNumbersCounterPipe,
        JoinArrayPipe,
        DisplayImagePipe
    ],
    imports: [
    ],
    providers: [DatePipe, DaysAgoPipe],
    exports: [
        DateFormatPipe,
        LocalizedDatePipe,
        IdentitiesCounterPipe,
        // DatePipe,
        SafePipe,
        SafeHtmlPipe,
        FirstKeyPipe,
        TimeAgoDatePipe,
        TranslateListPipe,
        TruncateText,
        OrderValuesByNamePipe,
        GroupByType,
        CallbackItemPipe,
        ToHours,
        Alphabetically,
        SortedLimits,
        NewsDate,
        Capitalize,
        SortPipe,
        SortAlphabeticallyPipe,
        CustomDevicesFilterPipe,
        GroupExposedData,
        FilterByPropertyPipe,
        OrderByPropertyPipe,
        CountPipe,
        CustomDevicesFilterPipe,
        TimeFromMilisecondsPipe,
        TimeFromSecondsPipe,
        ParentalMorePipe,
        TopicsMorePipe,
        HoursFromSeconds,
        HourMinuteInterval,
        DaysAgoPipe,
        ThreeDigitsFormat,
        WrappingPipe,
        WrappingPipesArray,
        BoldStringSectionPipe,
        BigNumbersCounterPipe,
        JoinArrayPipe,
        DisplayImagePipe
    ]
})

export class PipesModule {
    onDestroy$: Subject<void> = new Subject<void>();

    constructor(
        readonly translateService: TranslateService,
        readonly languageService: LanguageService
    ) {}

}
