import { Injectable } from '@angular/core';
import { GroupManagementService } from '../../process/subscriptions/group-management.service';
import { GroupMember, GroupMemberProcessed } from '../../../models/subscriptions/Groups.model';
import { ProfilesService } from '../../process/profiles/profiles.service';
import { FamilyMemberProcessed } from '../../interfaces/ifamily/ifamily.service';
import { IGroupsService } from '../igroups/igroups.service';
import { IFamilyService } from '../ifamily/ifamily.service';
import { FamilyMember } from '../../../../common/values/family.values.service';

@Injectable({
    providedIn: 'root'
})

export class IMultipleUsersWrapperService {

    constructor(
        private readonly groupManagementService: GroupManagementService,
        private readonly iGroupsService: IGroupsService,
        private readonly iFamilyService: IFamilyService,
        private readonly profilesService: ProfilesService,
    ) {}

    /**
     * Method to get the user devices
     * @param {string} wrapperId
     * @returns {Array<any>} user devices
     */
    public getUserDevices(wrapperId: string): Array<any> {
        if (this.profilesService.isOwnerVSBAdmin()) {
            return this.iGroupsService.getUserDevices(wrapperId);
        }

        if (this.profilesService.isFamilyPlanAdmin()) {
            return this.iFamilyService.getUserDevices(wrapperId);
        }
        return [];
    }

    /**
     * Method to get the group members
     * @returns {Array<GroupMember|FamilyMember>} group members
     */
    public getGroupMembers(): Array<GroupMember|FamilyMember> {
        if (this.profilesService.isOwnerVSBAdmin()) {
            return this.groupManagementService.getGroupMembers();
        }

        if (this.profilesService.isFamilyPlanAdmin()) {
            return this.iFamilyService.getGroupMembers();
        }
        return [];
    }

    /**
     * Method to get the devices of the current user
     * @returns {Array<any>} devices
     */
    public getCurrentUserDevices(): Array<any> {
        if (this.profilesService.isOwnerVSBAdmin()) {
            return this.iGroupsService.getUserDevices(this.profilesService.getCurrentContextId());
        }

        if (this.profilesService.isFamilyPlanAdmin()) {
            return this.iFamilyService.getUserDevices(this.iFamilyService.computeOwnerWrapperId());
        }
        return [];
    }

    /**
     * Method to get the devices of other users
     * @returns {Array<any>} devices
     */
    public getOthersDevices(): Array<any> {
        if (this.profilesService.isOwnerVSBAdmin()) {
            return this.iGroupsService.getOthersDevices();
        }

        if (this.profilesService.isFamilyPlanAdmin()) {
            return this.iFamilyService.getOthersDevices();
        }
        return [];
    }

    /**
     * Method to get the group member by wrapper id
     * @param {string} wrapperId
     * @returns {GroupMemberProcessed|FamilyMemberProcessed} group member
     */
    public getGroupMemberById(wrapperId: string): GroupMemberProcessed|FamilyMemberProcessed {
        if (this.profilesService.isOwnerVSBAdmin()) {
            return this.groupManagementService.getGroupMemberByContextId(wrapperId);
        }

        if (this.profilesService.isFamilyPlanAdmin()) {
            return this.iFamilyService.getGroupMemberById(wrapperId);
        }
        return null;
    }

    /**
     * Method to get the current user wrapper id
     * @returns {string} current user wrapper id
     */
    public getCurrentUserWrapperId(): string {
        if (this.profilesService.isOwnerVSBAdmin()) {
            return this.profilesService.getCurrentContextId();
        }

        if (this.profilesService.isFamilyPlanAdmin()) {
            return this.iFamilyService.computeOwnerWrapperId();
        }
        return null;
    }

    /**
     * Method to check if the user is owner of VSB or family plan
     * @returns {boolean} true if the user is owner of VSB or family plan
     */
    public showGroupedDevices(): boolean {
        return this.profilesService.isOwnerVSBAdmin() || this.profilesService.isFamilyPlanAdmin();
    }

    /**
     * Method to compute the title for the grouped devices filter
     * @returns {string} title
     */
    public computeGroupedDevicesFilterTitle(): string {
        if (this.profilesService.isOwnerVSBAdmin()) {
            return 'devices.filters.groupMembers';
        }

        if (this.profilesService.isFamilyPlanAdmin()) {
            return 'devices.filters.familyMembers';
        }
        return '';
    }

    /**
     * Method to create group devices for vsb and family plan
     */
    public createGroupDevices(): void {
        if (this.profilesService.isOwnerVSBAdmin()) {
            this.iGroupsService.createGroupDevices();
        }

        if (this.profilesService.isFamilyPlanAdmin()) {
            this.iFamilyService.createGroupDevices();
        }
    }

}
