// External
import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { tv4 } from 'tv4';

// Internal
import { Errors, POST, RequestsService } from '../../global/request-service/requests.service';
import { schemas } from '../../../../common/models/schemas';
import { UtilsCommonService } from '../../../../common/utils/utils-common.service';
import { ValuesService } from '../../../../common/values/values.service';
import { ConfigService } from '../../../../common/config/config.service';
import { PasswordStatus } from '../../../../common/models/devices/Enums';
import { DeviceDetails } from '../../../../common/models/devices/DeviceDetails.model';
import { ExceptionModel } from '../../../../common/models/devices/Exception.model';
import { ProductsForGroup } from '../../../../common/values/business.values.service';

export interface ListDevicesV2ExtraParams {
    page: number;
    cursor?: string;
}

interface DefaultErrorObj {
    code: number;
    message: string;
};

@Injectable({
    providedIn: "root"
})

export class ConnectMgmtService {

    private readonly defaultErrorObj: DefaultErrorObj = {
        code: 104,
        message: Errors.INVALID_PARAMS
    };

    constructor(
        private cookieService: CookieService,
        private requestService: RequestsService,
        private utilsCommon: UtilsCommonService,
        private valuesService: ValuesService,
        private configService: ConfigService
    ) { }

    //#region helper function
    getJSON(method: string, extraParams?: any, counter?): object {
        if (!counter && counter !== 0) {
            counter  = parseInt((Math.random() * 1000).toString(), 10);
        }

        const _json = {
            id: counter + 1,
            jsonrpc: this.valuesService.jsonrpc,
            method: method,
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                }
            }
        };

        if (extraParams) {
            Object.assign(_json.params, extraParams);
        }

        return _json;
    }

    makeRequest(method: string, extraParams?: any, page?): Observable<any> {
        let _json = this.getJSON(method, extraParams, page);

        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.connectMgmtService,
            _json,
            "POST"
        ).pipe(
            map((resp) => {
                if (resp.error || resp.status === "error") {
                    throw resp.internal_data || resp;
                } else {
                    return resp.result;
                }
            }),
            catchError((error) => {
                return throwError(error);
            })
        );
    }
    //#endregion

    //#region devices

    /**
    * The method will list all devices known for the user
    * @returns Response from server
    */
    listDevices(listDevicesExtraParams: ListDevicesV2ExtraParams): Observable<DeviceDetails[]> {
        //! https://confluence.bitdefender.biz/display/CONNECT/Device+fields+details
        const extraParams: any = {
            icon_version: 2,
            limit: this.valuesService.devicesPerRequest,
            page: listDevicesExtraParams.page,
            check_history: 1,
            fields: [ // PRIORITATI NAME-uri box_detected_name, agent_detected_name, device_model, avahi_name, friendly_name, display_name — asta e ca sa iti dea display_name corect
                "ip", //nu era in v1
                "ip_list", //era in v1, nu era in v2
                "traffic",
                "mac_vendor",
                "protection_status",
                "device_icon",
                "onbox_timestamp",
                "box_app_id",
                "onbox",
                "box_device_id",
                "agent_detected_name",
                "box_managed",
                "profile_id",
                "agent_status",
                "friendly_name",
                "display_name",
                "apps",
                "device_os",
                "device_os_version", //nu era in v2
                "os_version",
                "box_detected_name",
                "device_model",
                "avahi_name",
                "device_accounts",
                "created",
                "device_account_sid",
                "box_online_ts",
                "cloned",
                "clones",
                "device_os_arch",
                "rsn"
            ]
        }

        if (listDevicesExtraParams.cursor) {
            extraParams.cursor = listDevicesExtraParams.cursor;
        }

        return this.makeRequest("list_devices_v2", extraParams, listDevicesExtraParams.page);
    }

    /**
     * The method will return device info
     * @returns Response from server
     */
    getDeviceInfo(device_id, include_removed): Observable<DeviceDetails[]> {
        //! https://confluence.bitdefender.biz/pages/viewpage.action?pageId=33510844#ConnectManagement(connect/connect_mgmt)-get_device_info
        const extraParams = {
            connect_destination: {
                app_id: "com.bitdefender.connect_mgm",
                device_id
            },
            include_removed
        }

        return this.makeRequest("get_device_info", extraParams);
    }

    /**
     * This method is used to merge device clones
     * @param deviceId the id of the current device with clones
     * @param clones array that contains the device ids of identified clones
     * @returns
     */
    mergeClones(deviceId: string, clones: string[]): Observable<any> {
        if (!tv4.validate(deviceId, schemas.schemaUuid)) {
            return throwError(this.defaultErrorObj);
        }
        if (!this.utilsCommon.checkArray(clones) || this.utilsCommon.isEmptyArray(clones)) {
            return throwError(this.defaultErrorObj);
        }

        const extraParams = {
            connect_destination: {
                device_id: deviceId,
                app_id: this.valuesService.connectAppId
            },
            clones: clones
        };

        return this.makeRequest("merge_clones", extraParams);
    }

    whitelistDevice(deviceId: string, cloneId: string): Observable<any> {
        if (!tv4.validate(deviceId, schemas.schemaUuid)) {
            return throwError(this.defaultErrorObj);
        }
        if (!tv4.validate(cloneId, schemas.schemaUuid)) {
            return throwError(this.defaultErrorObj);
        }

        const extraParams = {
            connect_destination: {
                device_id: cloneId,
                app_id: this.valuesService.connectAppId
            },
            device_id: deviceId
        };

        return this.makeRequest("whitelist_device", extraParams);
    }

    /**
    * This method retrieve traffic report submited by a BOX
    * @param deviceId the id of the current device
    * @returns Response from server
    */
    getTrafficReport(deviceId: string, counter): Observable<any> {
        if (!tv4.validate(deviceId, schemas.schemaUuid)) {
            return throwError(this.defaultErrorObj);
        }

        const extraParams = {
            connect_destination: {
                device_id: deviceId,
                app_id: this.valuesService.appBOX2
            }
        }

        return this.makeRequest("get_traffic_report", extraParams, counter);
    };

    /**
    * This call is used to remove all data associated with a BOX. Device with protection apps installed are not removed
    * @param deviceId
    * @param appId
    * @returns Response from server
    */
    unregisterBox(deviceId: string, appId: string): Observable<boolean> {
        if (!tv4.validate(deviceId, schemas.schemaUuid) ||
            !tv4.validate(appId, schemas.schemaSERVICE)
        ) {
            return throwError(this.defaultErrorObj);
        }

        const extraParams = {
            connect_destination: {
                device_id: deviceId,
                app_id: appId
            }
        }

        return this.makeRequest("unregister_box", extraParams)
            .pipe(
                map((resp) => {
                    return resp.status === 0;
                })
            );
    }

    /**
    * Remove the device from user account
    * @param deviceId the id of the current device
    * @returns Response from server
    */
    removeDevice(deviceId: string): Observable<boolean> {
        if (!tv4.validate(deviceId, schemas.schemaUuid)) {
            return throwError(this.defaultErrorObj);
        }

        const extraParams = {
            connect_destination: {
                device_id: deviceId
            }
        }

        return this.makeRequest("remove_device", extraParams)
            .pipe(
                map((resp) => {
                    return resp.status === 'ok';
                })
            );
    }

    /**
    * This method will set the traffic status given as a parameter for the device from connect_destination
    * @param deviceId the id of the current device
    * @returns Response from server
    */
   blockDevice(deviceId: string, blocked): Observable<boolean> {
    if (
        !tv4.validate(deviceId, schemas.schemaUuid)
    ) {
        return throwError(this.defaultErrorObj);
    }

    const extraParams = {
        connect_destination: {
            device_id: deviceId
        },
        blocked: blocked
    }

    return this.makeRequest("set_traffic_status", extraParams)
        .pipe(
            map((resp) => {
                return resp.status === 0;
            })
        );
}

    /**
     * This method is used to list the GHOSTR exceptions for a BOX
     * @param boxIds an array of box ids
     * @returns Response from server
     */
    listExceptions(boxIds: string[]): Observable<ExceptionModel[]> {
        if (!this.utilsCommon.checkArray(boxIds)
            || this.utilsCommon.isEmptyArray(boxIds)
        ) {
            return throwError(this.defaultErrorObj);
        }

        let arr = [];
        let id = parseInt((Math.random() * 100).toString(), 10);
        for (let i = 0; i < boxIds.length; i++) {
            const extraParams = {
                connect_destination: {
                    device_id: boxIds[i],
                    app_id: this.valuesService.appBOX2
                }
            }
            arr.push(this.getJSON("list_ghostr_exceptions", extraParams, i));
        }

        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.connectMgmtService,
            arr,
            "POST"
        ).pipe(
            map((resp) => {
                let resultsArray = [];
                for (let i in resp) {
                    if (resp[i].result) {
                        resultsArray.push(resp[i].result);
                    }
                }
                return resultsArray;
            }),
            catchError((err) => {
                return throwError(err);
            })
        );

    }

    setException(data): Observable<any> {
        const json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: 'set_ghostr_exception',
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                connect_destination: {
                    device_id: data.box_id,
                    app_id: this.valuesService.appBOX2
                },
                threat_id: data.threat_id,
                device_id: data.device_id,
                allow: 1
            }
        };

        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.connectMgmtService,
            json,
            'POST'
        ).pipe(
            map(resp => {
                if (resp?.result?.status === 0) {
                    return resp.result;
                }
                throw resp;
            }),
            catchError(err => {
                throw err;
            })
        );
    }

    /**
    * This method is used to remove a GHOSTR exception by ID
    * @param exceptionId the id of the current exception
    * @returns Response from server
    */
    removeException(exceptionId: string): Observable<boolean> {
        if (!tv4.validate(exceptionId, schemas.schemaUuid)) {
            return throwError(this.defaultErrorObj);
        }

        const extraParams = {
            exception_id: exceptionId
        }

        return this.makeRequest("rm_ghostr_exception", extraParams)
            .pipe(
                map((resp) => {
                    return resp.status === 0;
                })
            );
    }

    /**
    * This method get settings password status
    * @returns Response from server
    */
    getPasswordStatus(): Observable<boolean> {
        return this.makeRequest("get_settings_password_status")
            .pipe(
                map((resp) => {
                    return resp.password_set === 1;
                })
            );
    }

    /**
    * This method set a password which protects all device settings
    * @param data the requested object with fields: status, pass
    * @returns Response from server
    */
    setPasswordStatus(data): Observable<boolean> {
        if (!tv4.validate(data, schemas.schemsSettingsPassword) ||
            (data.status === PasswordStatus.ENABLE && (data.pass === undefined || data.pass === ''))) {
            return throwError(this.defaultErrorObj);
        }

        const extraParams = {
            status: data.status,
            password: data.pass
        }

        return this.makeRequest("set_settings_password", extraParams)
            .pipe(
                map((resp) => {
                    return resp.status === 0;
                })
            );
    }

    //#endregion



    /**
    * Set friendly_name for device
    * @param inputName Contains mandatory `inputName` and `device`
    * @param device The device_id from connect_destination will be used
    * @returns Response from server
    */
    setDeviceFriendlyName(inputName, deviceId): Observable<any> {
        if (!inputName || !tv4.validate(deviceId, schemas.schemaUuid)) {
            console.error("Invalid params", tv4.error);
            return throwError(this.defaultErrorObj);
        }

        const extraParams = {
            friendly_name: inputName,
            connect_destination: {
                app_id: this.valuesService.connectAppId,
                device_id: deviceId
            }
        };

        return this.makeRequest("set_device_friendly_name", extraParams)
            .pipe(
                map((resp) => {
                    return resp.status === "ok";
                }),
                catchError(
                    (err) => {
                        throw err;
                    }
                )
            );
    }

    /**
    * This method will add or edit DHCP (manual IP allocation) settings on BOX devices
    * @param {object} Contains mandatory `ip_suffix` and `device`
    * @returns Response from server
    */
    setIp(device, ip_suffix): Observable<any> {
        if (!tv4.validate(device.device_id, schemas.schemaUuid) || !device.macs || !ip_suffix) {
            console.error("Invalid params", tv4.error);
            return of("Invalid params");
        }
        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "set_dhcp_settings",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                connect_destination: {
                    device_id: device.box_device_id,
                    app_id: this.valuesService.appBOX2
                },
                setting_name: "ip_allocation",
                device_id: device.device_id,
                mac: device.macs[0],
                ip_suffix: ip_suffix.toString()
            }
        };

        return this.requestService.make(this.configService.config.nimbusServer, this.valuesService.connectMgmtService, json, "POST");
    }

    /**
    * This method will get DHCP (manual IP allocation) settings for a device
    * @param {object} Contains mandatory `device`
    * @returns Response from server
    */
    listIp(device): Observable<any> {
        if (!tv4.validate(device.device_id, schemas.schemaUuid)) {
            console.error("Invalid params", tv4.error);
            return throwError("Invalid params");
        }

        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "get_dhcp_settings",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                connect_destination: {
                    device_id: device.box_device_id,
                    app_id: this.valuesService.appBOX2
                },
                setting_name: "ip_allocation",
                device_id: device.device_id
            }
        };

        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.connectMgmtService,
            json,
            "POST"
        ).pipe(
            map((resp: any) => {
                if (resp.result) {
                    return resp.result;
                } else {
                    throw of("Malformed request");
                }
            }),
            catchError((error) => {
                throw error;
            })
        );
    }

    /**
    * This method will add or edit DHCP (port forwarding) settings on BOX devices
    * @param {object} Contains mandatory `device`, `protocol`, `wan_port`, `lan_port`
    * @returns Response from server
    */
    setPortForward(device, portForward): Observable<any> {
        if (
            !tv4.validate(device.device_id, schemas.schemaUuid) ||
            !device.macs.length ||
            !portForward.proto ||
            !portForward.wan_port ||
            !portForward.lan_port
        ) {
            console.error("Invalid params", tv4.error);
            return throwError("Invalid params");
        }
        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "set_dhcp_settings",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                connect_destination: {
                    device_id: device.box_device_id,
                    app_id: this.valuesService.appBOX2
                },
                setting_name: "port_fwd",
                proto: portForward.proto.toString(),
                wan_port: portForward.wan_port,
                lan_device_id: device.device_id,
                // lan_mac: device.macs[0],
                lan_port: portForward.lan_port
            }
        };

        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.connectMgmtService,
            json,
            "POST"
        ).pipe(
            map((resp: any) => {
                if (resp.result) {
                    return resp.result;
                } else {
                    throw of("Malformed request");
                }
            }),
            catchError((error) => {
                throw error;
            })
        );
    }

    /**
    * This method will get DHCP (manual IP allocation and port forwarding) settings for a device
    * @param {object} Contains mandatory `device`
    * @returns Response from server
    */
    listPortForward(device): Observable<any> {
        if (!tv4.validate(device.device_id, schemas.schemaUuid) || !device.macs) {
            console.error("Invalid params", tv4.error);
            return throwError("Invalid params");
        }
        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "get_dhcp_settings",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                connect_destination: {
                    device_id: device.box_device_id,
                    app_id: this.valuesService.appBOX2
                },
                setting_name: "port_fwd",
                lan_device_id: device.device_id,
                // lan_mac: device.macs[0]
            }
        };

        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.connectMgmtService,
            json,
            "POST"
        ).pipe(
            map((resp: any) => {
                if (resp.result) {
                    return resp.result;
                } else {
                    throw of("Malformed request");
                }
            }),
            catchError((error) => {
                throw error;
            })
        );
    }

    /**
    * This method will remove DHCP (port forwarding) settings on BOX devices
    * @param {object} Contains mandatory `device`, `protocol`, `wan_port`
    * @returns Response from server
    */
    removePortForward(device, port): Observable<any> {
        if (
            !tv4.validate(device.device_id, schemas.schemaUuid) ||
            !port.proto ||
            !port.wan_port
        ) {
            console.error("Invalid params", tv4.error);
            return throwError("Invalid params");
        }
        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "rm_dhcp_settings",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                connect_destination: {
                    device_id: device.box_device_id,
                    app_id: this.valuesService.appBOX2
                },
                setting_name: "port_fwd",
                proto: port.proto.toString(),
                wan_port: port.wan_port
            }
        };

        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.connectMgmtService,
            json,
            "POST"
        ).pipe(
            map((resp: any) => {
                if (resp.result) {
                    return resp.result;
                } else {
                    throw of("Malformed request");
                }
            }),
            catchError((error) => {
                throw error;
            })
        );
    }

    /**
    * This method will remove DHCP (manual IP allocation) settings on BOX devices
    * @param {object} Contains mandatory `device`
    * @returns Response from server
    */
    removeIp(device): Observable<any> {
        if (!tv4.validate(device.device_id, schemas.schemaUuid) || !device.macs) {
            console.error("Invalid params", tv4.error);
            return throwError("Invalid params");
        }

        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "rm_dhcp_settings",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                connect_destination: {
                    device_id: device.box_device_id,
                    app_id: this.valuesService.appBOX2
                },
                setting_name: "ip_allocation",
                device_id: device.device_id,
                mac: device.macs[0]
            }
        };

        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.connectMgmtService,
            json,
            "POST"
        ).pipe(
            map((resp: any) => {
                if (resp.result) {
                    return resp.result;
                } else {
                    throw of("Malformed request");
                }
            }),
            catchError((error) => {
                throw error;
            })
        );
    }

    /**
    * This method is used to list the sessions associated with an account
    * @returns Response from server
    */
    getListOfSerssions(): Observable<any> {
        let id = parseInt((Math.random() * 100).toString(), 10);
        const json = [
            {
                id: id++,
                jsonrpc: "2.0",
                method: "list_sessions",
                params: {
                    connect_source: {
                        user_token: this.cookieService.get(this.valuesService.cookieToken),
                        app_id: this.valuesService.connectAppId,
                        device_id: this.valuesService.connectDeviceId
                    }
                }
            },
            {
                id: id++,
                jsonrpc: "2.0",
                method: "list_closed_sessions",
                params: {
                    connect_source: {
                        user_token: this.cookieService.get(this.valuesService.cookieToken),
                        app_id: this.valuesService.connectAppId,
                        device_id: this.valuesService.connectDeviceId
                    }
                }
            }
        ];

        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.connectMgmtService,
            json,
            "POST"
        ).pipe(
            map(
                (resp: any) => {
                    const sessions: any = {};
                    sessions.sessions = resp[0].result; // active sessions
                    sessions.closedSessions = resp[1].result; // closed sessions
                    return sessions;
                }
            ),
            catchError((error) => {
                throw error;
            })
        );
    }

    /**
    * This method is used to close a certain session associated with an account
    * @param {object} Contains mandatory `array`
    * @returns Response from server
    */
    closeSession(array): Observable<any> {
        if (!this.utilsCommon.checkArray(array)) {
            console.error("Invalid params", tv4.error);
            return of("Invalid params");
        }

        const arr = [];
        let id = parseInt((Math.random() * 100).toString(), 10);
        for (let i = 0; i < array.length; i++) {
            if (array[i]) {
                const json = {
                    id: id++,
                    jsonrpc: "2.0",
                    method: "close_session",
                    params: {
                        connect_source: {
                            user_token: this.cookieService.get(
                                this.valuesService.cookieToken
                            ),
                            app_id: this.valuesService.connectAppId,
                            device_id: this.valuesService.connectDeviceId
                        },
                        session_id: array[i]
                    }
                };
                arr.push(json);
            }
        }

        if (arr.length !== 0) {
            return this.requestService.make(
                this.configService.config.nimbusServer,
                this.valuesService.connectMgmtService,
                arr,
                "POST"
            ).pipe(
                map(
                    (resp: any) => {
                        // vine un array pe resp
                        return resp;
                    }
                ),
                catchError((error) => {
                    throw error;
                })
            );
        }
    }

    /**
     * This method is used to get Saferpass status
     * @returns {Observable<any>} Response from server
     */
    public listSaferpassStatus(): Observable<any> {
        return this.makeRequest('get_saferpass_status')
        .pipe(
            map(resp => {
                if (resp?.status === 0) {
                    return resp;
                }
                throw resp;
            }),
            catchError(err => {
                throw err;
            })
        );
    }

    /**
     * This method is used to get loki master password status
     * @returns {Observable<any>} Response from server
     */
    //  ?Should I make a type ?
    public listLokiMasterPasswordStatus(): Observable<any> {
        const params = {
            external_auth_token: this.cookieService.get(this.valuesService.cookieToken),
        };
        return this.requestService.make(
            this.configService.getSecurePassServer(),
            this.valuesService.securePassService,
            params,
            POST
        ).pipe(
            map((resp: any) => {
                return resp;
            }),
            catchError((error) => {
                throw error;
            }),
        );
    }

    /**
     * This method is used to list group status on specific apps
     * @param {string} groupId Group id of current group
     * @returns {Observable<any>} Response from server
     */
    public listGroupStatus(groupId: string): Observable<any> {
        if (!groupId) {
            return throwError(() => new Error(Errors.INVALID_PARAMS));
        }

        const params = {
            id: parseInt((Math.random() * 1000).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: 'get_group_status',
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                group_id: groupId,
                products: [ProductsForGroup.DATA_PRIVACY, ProductsForGroup.PASSWORD_MANAGER]
            }
        };
        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.connectMgmtService,
            params,
            POST
        ).pipe(
            map((resp: any) => {
                if (resp?.result) {
                    return resp.result;
                }
                throw resp;
            }),
            catchError((error) => {
                throw error;
            }),
        );
    }
}