// External
import { Injectable } from '@angular/core';

// Internal
import { DashboardService } from '../../process/dashboard/dashboard.service';
import { DashboardValuesService } from '../../../values/dashboard.values.service';
import { DevicesService } from '../../process/devices/devices.service';
import { LinksService } from '../../../links/links.service';
import { ProfilesService } from '../../process/profiles/profiles.service';
import { SubscriptionsService } from '../../process/subscriptions/subscriptions.service';
import { UsefulService } from '../../global/useful/useful.service';
import { UtilsCommonService } from '../../../utils/utils-common.service';
import { ValuesService } from '../../../values/values.service';
import { IThreatsChartService } from '../ithreats-chart/ithreats-chart.service';
import { NotificationsTypes, NotificationsSubtypes } from '../../global/events/events.model';

@Injectable({
    providedIn: 'root'
})
export class IDashboardService {

    constructor(
        readonly dashboardService       : DashboardService,
        readonly dashboardValuesService : DashboardValuesService,
        readonly devicesService         : DevicesService,
        readonly linksService           : LinksService,
        readonly profilesService        : ProfilesService,
        readonly subscriptionsService   : SubscriptionsService,
        readonly usefulService          : UsefulService,
        readonly utilsCommonService     : UtilsCommonService,
        readonly valuesService          : ValuesService,
        readonly iThreatsChartService   : IThreatsChartService

    ) {}

    createThreatsChartInterface() {
        const dashboard      = this.dashboardService.get();
        const threats        = this.usefulService.getNested(dashboard, {}, 'threats');
        const reports        = this.usefulService.getNested(threats, [], 'countThreats', 'seven', 'all');
        const devicesIds     = this.usefulService.getNested(threats, [], 'activeDevices', 'listIds');
        this.iThreatsChartService.createThreatsChartInterface(threats, reports, devicesIds);
    }

    createDevicesInterface() {
        const dashboard = this.dashboardService.get();
        const dashboardDeviceInterface = { // interfata
            showDissonnected: false,
            bundles: this.createSortedBundles(),
            [this.valuesService.protectionStatus.AT_RISK]: 0,
            [this.valuesService.protectionStatus.PROTECTED]: 0,
            [this.valuesService.protectionStatus.DISCONNECTED]: 0,
            empty: false,
            noDevices: false,
            noSubscriptions: false
        };

        this.addDevicesStatus(dashboardDeviceInterface);
        this.addEmptyCardStatus(dashboardDeviceInterface);

        if (!dashboard['devices']) {
            dashboard['devices'] = {};
        }

        if (!dashboard['devices']['interface']) {
            dashboard['devices']['interface'] = {};
        }

        dashboard['devices']['interface'] = dashboardDeviceInterface;
    }

    addEmptyCardStatus(dashboardDeviceInterface) {
        const devices = this.devicesService.getDevicesObject();

        // nu contine nicio subscriptie
        if (dashboardDeviceInterface.bundles.length === 0) {
            dashboardDeviceInterface.noSubscriptions = true;
        }

        // nu contine niciun device
        if (this.utilsCommonService.isEmptyObject(devices)) {
            dashboardDeviceInterface.noDevices = true;
        }

        // cardul este empty daca nu am subscriptii sau devices asignate
        if (dashboardDeviceInterface.noSubscriptions || dashboardDeviceInterface.noDevices) {
            dashboardDeviceInterface.empty = true;
        }
    }

    addDevicesStatus(dashboardDeviceInterface) {
        const devicesArray = this.devicesService.getDevicesListArray();

        dashboardDeviceInterface.showDissonnected = this.devicesService.showDisconnectedFilter();

        for (const device of devicesArray) {
            if (this.devicesService.checkMethods.isBox(device)) {
                continue;
            }
            const protectionStatus = this.usefulService.getNested(device, null, 'processed', 'protectionStatus');
            dashboardDeviceInterface[protectionStatus]++;
        }
    }

    isIgnoredBundle(bundle) {
        return this.subscriptionsService.isDpy(bundle) 
                || this.subscriptionsService.isIdTheftProtection(bundle)
                || this.subscriptionsService.isPremiumService(bundle) 
                || this.subscriptionsService.isVPNSubscription(bundle) 
                || this.subscriptionsService.isPasswordManager(bundle)
                || this.subscriptionsService.isSecureIdentity(bundle);
    }

    pushInterfaceItemsInOrder(target, bundle, devicesDashboardItem) {
        if (target.length === 0 || target[target.length - 1].slots > bundle.slots) {
            target.push(devicesDashboardItem);
        } else {
            target.unshift(devicesDashboardItem);
        }
    }

    createSortedBundles() {
        const bundles = this.subscriptionsService.getFilteredActiveBundles();
        // sortare afisare device-uri
        const boxSubscriptions = [];
        const psSubscriptions = []; // cred ca poti sa ai doar o subscriptie de premium security, dar fac array pt a pastra forma
        const fpSubscriptions = [];
        const tsSubscriptions = [];
        const isSubscirptions = [];
        const avSubscriptions = [];
        const otherSubscriptions = [];
        let target = [];

        for (const bundle of bundles) {
            // ignoram bundle-urile astea
            if (this.isIgnoredBundle(bundle)) {
                continue;
            }

            const devicesDashboardItem = { // item-ul interfetei
                slots: bundle.slots,
                activeSlots: bundle?.interface?.devices?.length ?? 0,
                name: bundle.bundle_friendly_name,
                availableSlots: (bundle?.slots ?? 0) - (bundle?.interface?.devices?.length ?? 0),
                isBoxBundle: this.subscriptionsService.isBox(bundle),
                serviceId: bundle.service_id,
                bundleId: bundle.bundle_id,
                interface: bundle.interface
            };

            // sortez afisarea in functie de categoria de subscriptie
            if (this.subscriptionsService.isBox(bundle)) {
                target = boxSubscriptions;
            } else if (this.subscriptionsService.isPSSubscription(bundle)) {
                target = psSubscriptions;
            } else if (this.subscriptionsService.isFPSubscription(bundle)) {
                target = fpSubscriptions;
            } else if (this.subscriptionsService.isTSSubscription(bundle)) {
                target = tsSubscriptions;
            } else if (this.subscriptionsService.isISSubscription(bundle)) {
                target = isSubscirptions;
            } else if (this.subscriptionsService.isAVSubscription(bundle)) {
                target = avSubscriptions;
            } else {
                target = otherSubscriptions;
            }

            // sortez afisarea in fucntie de importanta subscriptie (nunar posibil de device-uri asignabile)
            this.pushInterfaceItemsInOrder(target, bundle, devicesDashboardItem);
        }

        // putting it all together
        return boxSubscriptions.concat(psSubscriptions)
                                .concat(fpSubscriptions)
                                .concat(tsSubscriptions)
                                .concat(isSubscirptions)
                                .concat(avSubscriptions)
                                .concat(otherSubscriptions);
    }

    getProfileName(profileId) {
        const profiles = this.profilesService.getAllProfiles();

        for (const item in profiles) {
            if (profiles[item].profile_id === profileId) {
                return profiles[item].first_name;
            }
        }
        return '';
    }


    setValuesFromDetails(info) {
        let threatValues    = {};
        const threatType    = info.threatType;
        const threatSubtype = info.threatSubtype;
        const objectType    = info.type;
        const lanInitiated  = info.lan_initiated;
        const boxThreat     = info.onBox;

        const defaultValue                  = this.usefulService.getNested(this.dashboardValuesService.threatsDetailsModule, {}, 'default');
        const defaultDetails                = this.usefulService.getNested(this.dashboardValuesService.threatsDetailsModule, null, threatType, 'default');
        const objectTypeypeDetails          = this.usefulService.getNested(this.dashboardValuesService.threatsDetailsModule, null, threatType, objectType);
        const subtypeDetails                = this.usefulService.getNested(this.dashboardValuesService.threatsDetailsModule, null, threatType, threatSubtype);
        const boxDetails                    = this.usefulService.getNested(this.dashboardValuesService.threatsDetailsModule, null, threatType, 'box');
        const boxDefaultDetails             = this.usefulService.getNested(this.dashboardValuesService.threatsDetailsModule, null, threatType, 'box', 'default');
        const boxSubtypeDetails             = this.usefulService.getNested(this.dashboardValuesService.threatsDetailsModule, null, threatType, 'box', threatSubtype);
        const boxSubtypeLanInitiatedDetails = this.usefulService.getNested(this.dashboardValuesService.threatsDetailsModule, null, threatType, 'box', threatSubtype, lanInitiated);

        if (boxThreat && boxDetails) {
            threatValues = boxSubtypeLanInitiatedDetails || boxSubtypeDetails || boxDefaultDetails || defaultValue;
        } else {
            threatValues = subtypeDetails || objectTypeypeDetails || defaultDetails || defaultValue;
        }

        info = {...info, ...threatValues};
        return info;
    }

    setValuesFromDevice(threat, info) {
        const devices      = this.devicesService.getDevicesObject();
        const device       = devices[threat.device_id];
        const appId        = this.usefulService.getNested(threat, '', 'app_id');

        const threatValues = {
            boxName: device && device.box_device_id && devices[device.box_device_id] ? devices[device.box_device_id].display_name : "",
            profileName: device ? this.getProfileName(device.profile_id) : '',
            onBox: this.valuesService.boxApps.has(appId),
            deviceName: device?.display_name ??  '',
            deviceIcon: device?.device_icon ?? '',
        };
        info = {...info, ...threatValues};
        return info;
    }

    getStatus(threat) {
        const status = this.usefulService.getNested(threat, [], 'object', 'status');
        if (Array.isArray(status) && status.length > 0) {
            return status[0];
        } else {
            return '';
        }
    }

    computeThreatType(threat) {
        const threatType        = this.usefulService.getNested(threat, '', 'threat_type');
        const appID             = this.usefulService.getNested(threat, '', 'app_id');
        const status            = this.getStatus(threat);

        if (appID !== this.valuesService.appBOX2
            && threatType === NotificationsTypes.MALICIOUS_HOST
            && status) {
            return status;
        } else {
            return threatType;
        }
    }

    computeThreatSubtype(threat) {
        const threatType        = this.usefulService.getNested(threat, '', 'threat_type');
        const appID             = this.usefulService.getNested(threat, '', 'app_id');
        const threatSubtype     = this.usefulService.getNested(threat, '', 'threat_subtype');
        const status            = this.getStatus(threat);

        if (appID !== this.valuesService.appBOX2
            && threatType === NotificationsTypes.MALICIOUS_HOST
            && status) {
            return NotificationsSubtypes.BMS;
        } else {
            return threatSubtype;
        }
    }

    computeThreatUrl(threat) {
        const object            = this.usefulService.getNested(threat, null, 'object');
        const objectUrl         = this.usefulService.getNested(threat, null, 'object', 'url');
        const objectDomain      = this.usefulService.getNested(threat, "", 'object', 'domain');
        const objectBlockedUrl  = this.usefulService.getNested(threat, null, 'object', 'blocked_url');
        const threatType        = this.usefulService.getNested(threat, '', 'threat_type');
        return objectBlockedUrl
                || objectUrl
                || (this.utilsCommonService.checkString(object) ? object : "")
                || (threatType === NotificationsTypes.MALICIOUS_HOST ? objectDomain : "");
    }

    setDetailsFromThreat(threat, info) {
        const threatValues  = {};
        const fileName          = this.usefulService.getNested(threat, null, 'fileName');
        const objectType        = this.usefulService.getNested(threat, null, 'object_type');
        const object            = this.usefulService.getNested(threat, null, 'object');
        const lanInitiated      = this.usefulService.getNested(threat, null, 'object', 'lan_initiated');
        const ip                = this.usefulService.getNested(threat, null, 'object', 'ip');
        const srcIp             = this.usefulService.getNested(threat, null, 'object', 'src_ip');

        const protocol          = this.usefulService.getNested(threat, null, 'object', 'protocol');
        const port              = this.usefulService.getNested(threat, null, 'object', 'port');
        const extPort           = this.usefulService.getNested(threat, null, 'object', 'extPort');
        const malware           = this.usefulService.getNested(threat, null, 'object', 'malware');

        const timestamp         = this.usefulService.getNested(threat, '', 'timestamp');
        const threatType        = this.computeThreatType(threat);

        threatValues['threatType']     = threatType;
        threatValues['threatSubtype']  = this.computeThreatSubtype(threat);
        threatValues['class']          = this.dashboardValuesService.threatsColor[threatType];
        threatValues['url']            = this.computeThreatUrl(threat);
        threatValues['protocol']       = protocol;
        threatValues['port']           = port;
        threatValues['extPort']        = extPort;
        threatValues['malware']        = malware;
        threatValues['ip']             = ip ?? srcIp;
        threatValues['date']           = {
            'timestamp': timestamp,
            month: new Date(timestamp).getMonth()+1,
        };

        if (lanInitiated !== null) {
            threatValues['lan_initiated'] = lanInitiated;
        }

        if (fileName) {
            threatValues['appOrFile'] = fileName;
        }

        if (objectType === this.valuesService.threatObjectType.app || objectType === this.valuesService.threatObjectType.file) {
            threatValues['type']        = objectType;
            threatValues['appOrFile']   = this.utilsCommonService.checkString(object) ? object : '';
        }

        if (objectType === this.valuesService.threatObjectType.url || objectType === this.valuesService.threatObjectType.behaviour) {
            threatValues['type']        = objectType;
            threatValues['appOrFile']   = this.utilsCommonService.checkString(object) ? object : '';
        }

        info = {...info, ...threatValues};
        return info;
    }

    createThreatDetailsInterface(noDays) {
        const dashboard      = this.dashboardService.get();
        const threats        = this.usefulService.getNested(dashboard, {}, 'threats');
        const threatsDetails = this.usefulService.getNested(threats, {}, 'details');
        const listThreats    = this.usefulService.getNested(threatsDetails , {}, noDays);
        const totalThreats   = this.usefulService.getNested(threats, 0, 'interface', 'details', noDays, 'totalThreats');
        const list           = [];
        let noMoreThreats    = false;

        if (listThreats.list.length > totalThreats) {
            listThreats.list = listThreats.list.slice(0, totalThreats);
        }

        if (listThreats.final || listThreats.list.length === totalThreats) {
            listThreats.final = true;
            noMoreThreats = true;
        }

        for (const t in listThreats.list) {
            let info: any = {};
            const threat    = listThreats.list[t];

            info = this.setValuesFromDevice(threat, info);

            info = this.setDetailsFromThreat(threat, info);

            info = this.setValuesFromDetails(info);
            list.push(info);
        }

        if (!threats['interface']) {
            threats['interface'] = {};
        }

        if (!threats['interface']['details']) {
            threats['interface']['details'] = {};
        }

        if (!threats['interface']['details'][noDays]) {
            threats['interface']['details'][noDays] = {};
        }

        threats['interface']['details'][noDays]['list'] = list;
        threats['interface']['details'][noDays]['noMoreThreats'] = noMoreThreats;
    }

    countAllThreats(days) {
        const dashboard      = this.dashboardService.get();
        const threats        = this.usefulService.getNested(dashboard, {}, 'threats');
        const reports        = threats['countThreats'][days.text]['all'];
        let totalThreats     = 0;
        const daysArr        = new Set();

        // formam intervalul de 7 / 30 zile deoarece count-ul aduce pe mai multe zile decat 7/30
        // si verificam daca data threat-ului este cuprinsa in interval
        for (let i = 0; i < days.value; i++) {
            const date = new Date();
            date.setDate(date.getDate() - i);
            const formatedDate = this.usefulService.formatDate(date);
            daysArr.add(formatedDate);
        }

        for (const report of reports) {
            for (const objectType in report) {
                if (objectType !== 'date' && objectType !== 'hour' && daysArr.has(report['date'])) {
                    for (const threatType in report[objectType]) {
                        totalThreats += report[objectType][threatType];
                    }
                }
            }
        }

        if (!threats['interface']) {
            threats['interface'] = {};
        }

        if (!threats['interface']['details']) {
            threats['interface']['details'] = {};
        }

        if (!threats['interface']['details'][days.text]) {
            threats['interface']['details'][days.text] = {};
        }

        threats['interface']['details'][days.text]['totalThreats'] = totalThreats;
    }

    hasSingleProtection(protectionApps) {

        const listDevices = this.devicesService.getDevicesList().all;
        if (listDevices.length > 0) {
            for (const device of listDevices) {

                const protectionStatus = this.usefulService.getNested(device, null, 'protection_status');
                if (protectionStatus) {
                    if (!protectionApps.has(protectionStatus.protection_app)) {
                        return false;
                    }
                }
            }
        }

        return true;
    }

    hasMobileAndDesktopProtection(protectionAppsMobile, protectionAppsDesktop) {
        let mobileProtection = false;
        let desktopProtection = false;

        const listDevices = this.devicesService.getDevicesList().all;

        if (listDevices.length < 0) {
            return false;
        }

        for (const device of listDevices) {
            const protectionStatus = this.usefulService.getNested(device, null, 'protection_status');
            if (protectionStatus) {
                if (protectionAppsMobile.has(protectionStatus.protection_app)) {
                        mobileProtection = true;
                }

                if (protectionAppsDesktop.has(protectionStatus.protection_app)) {
                        desktopProtection = true;
                }
            }
        }

        return mobileProtection && desktopProtection;
    }

    hasMobileVpnProtection() {
        const listDevices = this.devicesService.getMobileDevices();

        if (listDevices.length > 0) {
            for (const device of listDevices) {
                if (device.processed.hasVPN) {
                    return true;
                }
            }
        }
        return false;
    }

    getBundleWithMaxSlots(bundles) {
        let maxSlots = 0;
        let bundleToDisplay = null;

        for (const bundle of bundles) {
            if (this.devicesService.serviceHasFreeSlots(bundle)
                && maxSlots < (bundle.slots - bundle.interface.devices.length)) {
                maxSlots = bundle.slots - bundle.interface.devices.length;
                bundleToDisplay = bundle;
            }
        }

        if (maxSlots === 0) {
            return;
        }

        return bundleToDisplay;
    }

    getHighestPriorityTrial(securityBundles) {
        const maxPriority = -1;
        const maxFreeSlots = -1;
        let maxBundle = null;

        for (const bundle of securityBundles) {
            const priority = this.valuesService.orderedServicesSecondRecommendationCard.indexOf(bundle.bundle_id);
            const freeSlots = bundle.slots - bundle.interface.devices.length;

            if (priority === maxPriority && freeSlots > maxFreeSlots) {
                maxBundle = bundle;
            }
            if (priority > maxPriority) {
                maxBundle = bundle;
            }
        }
        return maxBundle;
    } 

    /**
     * Computes data for the first recommendation card
     * @public
     * @memberof IDashboardService
     */
    public checkForSecurityBundles(): void {
        const multiPlatformServices = this.subscriptionsService.getSecurityMultiPlatformServices();
        const singlePlatformServices = this.subscriptionsService.getSecuritySinglePlatformServices();
        const dashboard = this.dashboardService.get();

        let protectionCardBundle;
        let securityCard: any = {};

        // Check for paid subs
        if (!this.subscriptionsService.hasPaidSecurityBundles()) {
            // Just trials, check platform type only if there are no other trials than security ones
            if (this.subscriptionsService.hasOnlyTrialSecurityBundles()) {
                if (multiPlatformServices.length > 0) {
                    protectionCardBundle = this.getBundleWithMaxSlots(multiPlatformServices);
                    if (protectionCardBundle) {
                        if (protectionCardBundle.interface.daysRemaining > 1) {
                            securityCard = this.dashboardValuesService.recommendationScenarios.protection.trial.multiPlatformForDays;
                        } else {
                            securityCard = this.dashboardValuesService.recommendationScenarios.protection.trial.multiPlatformForToday;
                        }

                        securityCard.daysRemaining = protectionCardBundle.interface.daysRemaining;
                        securityCard.bundleId = protectionCardBundle.bundle_id;
                        securityCard.bundleType = protectionCardBundle.type;
                        securityCard.scenario = this.dashboardValuesService.recommendationCardsEvents.protection.s0;
                    }
                } else if (singlePlatformServices.length > 0) {
                    protectionCardBundle = this.getBundleWithMaxSlots(singlePlatformServices);
                    if (protectionCardBundle) {
                        if (protectionCardBundle.interface.daysRemaining > 1) {
                            securityCard = this.dashboardValuesService.recommendationScenarios.protection.trial.singlePlatformForDays;
                        } else {
                            securityCard = this.dashboardValuesService.recommendationScenarios.protection.trial.singlePlatformForToday;
                        }

                        securityCard.daysRemaining = protectionCardBundle.interface.daysRemaining;
                        securityCard.operatingSistem = this.valuesService.singlePlatformOs[protectionCardBundle.bundle_id];
                        securityCard.bundleId = protectionCardBundle.bundle_id;
                        securityCard.bundleType = protectionCardBundle.type;
                        securityCard.scenario = this.dashboardValuesService.recommendationCardsEvents.protection.s0;
                    }
                }
            }
        } else {
            // Paid subs
            // Check if user is in "Buy more protection" case
            if (this.subscriptionsService.checkForLimitReachedBannerAppearance()) {
                securityCard = this.dashboardValuesService.recommendationScenarios.protection.end.buyProtection;
                securityCard.scenario = this.dashboardValuesService.recommendationCardsEvents.protection.s1;

                const service = this.subscriptionsService.getHighestLimitReachedService();
                if (service) {
                    securityCard.bundle = service;
                    securityCard.bundleId = service.bundle_id;
                    securityCard.bundleType = service.type;
                }

            } else {
                // Check if user is using only single platform subscriptions and free slots
                if (multiPlatformServices.length === 0 && singlePlatformServices.length > 0) {
                    securityCard = this.dashboardValuesService.recommendationScenarios.protection.end.startTrial;
                    securityCard.scenario = this.dashboardValuesService.recommendationCardsEvents.protection.s2;

                    const service = this.getBundleWithMaxSlots(singlePlatformServices);
                    if (service) {
                        securityCard.bundleId = service['bundle_id'];
                        securityCard.bundleType = service['type'];
                    }
                } else {
                    const service = this.getBundleWithMaxSlots(multiPlatformServices);
                    // Check protection type
                    if (service && this.hasSingleProtection(this.valuesService.mobileApps)) {
                        securityCard = this.dashboardValuesService.recommendationScenarios.protection.end.protectDesktopDevices;
                        securityCard.scenario = this.dashboardValuesService.recommendationCardsEvents.protection.s3;
                    } else if (service && this.hasSingleProtection(this.valuesService.desktopApps)) {
                        securityCard = this.dashboardValuesService.recommendationScenarios.protection.end.protectMobileDevices;
                        securityCard.scenario = this.dashboardValuesService.recommendationCardsEvents.protection.s4;
                    } else if (service && this.hasMobileAndDesktopProtection(this.valuesService.mobileApps, this.valuesService.desktopApps)) {
                        securityCard = this.dashboardValuesService.recommendationScenarios.protection.end.protectAllDevices;
                        securityCard.scenario = this.dashboardValuesService.recommendationCardsEvents.protection.s5;
                    }
                }
            }
        }

        if (!dashboard['recommendations']) {
            dashboard['recommendations'] = {};
        }

        if (!dashboard['recommendations']['interface']) {
            dashboard['recommendations']['interface'] = {};
        }

        if (!dashboard['recommendations']['interface']['firstCard']) {
            dashboard['recommendations']['interface']['firstCard'] = {};
        }

        dashboard['recommendations']['interface']['firstCard'] = securityCard;
    }

    /**
     * Computes data for the second recommendation card
     * @public
     * @memberof IDashboardService
     */
    public upgradeToNextPlan(): void {
        const dashboard = this.dashboardService.get();
        let upgradeCard: any = {};
        let upgradeCardBundle;

        // Check for paid subs - just trials
        if (!this.subscriptionsService.hasPaidSecurityBundles()) {
            const securityBundles = this.subscriptionsService.getSecurityBundles();

            if (securityBundles.length > 1) {
                const multiPlatformServices = this.subscriptionsService.getSecurityMultiPlatformServices();
                const singlePlatformServices = this.subscriptionsService.getSecuritySinglePlatformServices();

                if (multiPlatformServices.length) {
                    upgradeCardBundle = this.getBundleWithMaxSlots(multiPlatformServices);
                } else if (singlePlatformServices.length) {
                    upgradeCardBundle = this.getBundleWithMaxSlots(singlePlatformServices);
                }

                if (upgradeCardBundle) {
                    if (upgradeCardBundle.interface.daysRemaining > 1) {
                        upgradeCard = this.dashboardValuesService.recommendationScenarios.upgrade.trial.trialForDays;
                    } else {
                        upgradeCard = this.dashboardValuesService.recommendationScenarios.upgrade.trial.trialForToday;
                    }

                    upgradeCard.bundle = upgradeCardBundle;
                    upgradeCard.daysRemaining = upgradeCardBundle.interface.daysRemaining;
                    upgradeCard.productName = upgradeCardBundle.interface.name;
                    upgradeCard.bundleId = upgradeCardBundle.bundle_id;
                    upgradeCard.bundleType = upgradeCardBundle.type;
                    upgradeCard.scenario = this.dashboardValuesService.recommendationCardsEvents.upgrade.s6;
                }
            } else {
                upgradeCardBundle = this.getHighestPriorityTrial(securityBundles);

                if (upgradeCardBundle) {
                    if (upgradeCardBundle.interface.daysRemaining > 1) {
                        upgradeCard = this.dashboardValuesService.recommendationScenarios.upgrade.trial.trialForDays;
                    } else {
                        upgradeCard = this.dashboardValuesService.recommendationScenarios.upgrade.trial.trialForToday;
                    }

                    upgradeCard.bundle = upgradeCardBundle;
                    upgradeCard.daysRemaining = upgradeCardBundle.interface.daysRemaining;
                    upgradeCard.productName = upgradeCardBundle.interface.name;
                    upgradeCard.bundleId = upgradeCardBundle.bundle_id;
                    upgradeCard.bundleType = upgradeCardBundle.type;
                    upgradeCard.scenario = this.dashboardValuesService.recommendationCardsEvents.upgrade.s6;
                }
            }
        } else {
            // Paid subs - check for bundles with AR on
            if (!this.subscriptionsService.hasAutoRenewalOn()) {
                const paidSubs = this.subscriptionsService.getPaidServices();

                if (paidSubs.length === 1) {
                    const bundleId = paidSubs[0].bundle_id;

                    if (this.valuesService.upgradeToTotalSecurityBundles.has(bundleId)) {
                        upgradeCard = this.dashboardValuesService.recommendationScenarios.upgrade.end.totalSecurity;
                    } else if (this.valuesService.upgradeToTotalSecurityNewBundles.has(bundleId)) {
                        upgradeCard = this.dashboardValuesService.recommendationScenarios.upgrade.end.totalSecurityNew;
                    } else if (this.valuesService.upgradeToPremiumSecurityBundles.has(bundleId) && !this.subscriptionsService.hasPremiumVpnRecomCard()) {
                        upgradeCard = this.dashboardValuesService.recommendationScenarios.upgrade.end.premiumSecurity;
                    } else if (this.valuesService.upgradeToPremiumSecurityNewBundles.has(bundleId)) {
                        upgradeCard = this.dashboardValuesService.recommendationScenarios.upgrade.end.premiumSecurityNew;
                    } else if (this.valuesService.upgradeToUltimateSecurityNewBundles.has(bundleId)) {
                        upgradeCard = this.dashboardValuesService.recommendationScenarios.upgrade.end.ultimateSecurityNew;
                    }

                    if (Object.keys(upgradeCard).length) {
                        upgradeCard.bundleId = bundleId;
                        upgradeCard.bundleType = paidSubs[0].type;
                        upgradeCard.scenario = this.dashboardValuesService.recommendationCardsEvents.upgrade.s7;
                    }
                }
            }
        }

        if (!dashboard['recommendations']) {
            dashboard['recommendations'] = {};
        }

        if (!dashboard['recommendations']['interface']) {
            dashboard['recommendations']['interface'] = {};
        }

        if (!dashboard['recommendations']['interface']['secondCard']) {
            dashboard['recommendations']['interface']['secondCard'] = {};
        }

        dashboard['recommendations']['interface']['secondCard'] = upgradeCard;
    }

    /**
     * Computes data for the third recommendation card
     * @public
     * @memberof IDashboardService
     */
    purchaseVpn() {
        const dashboard = this.dashboardService.get();
        let vpnCard: any = {};

        // Check for paid subs only
        if (this.subscriptionsService.hasPaidSecurityBundles()) {
            const paidSubs = this.subscriptionsService.getPaidServices();

            if (paidSubs.length > 0 && this.subscriptionsService.hasVpnRecomCard()) {
                if (!this.subscriptionsService.hasPremiumVpnRecomCard()) {
                    vpnCard = this.dashboardValuesService.recommendationScenarios.vpn.end.buyPremiumVpn;
                    vpnCard.scenario = this.dashboardValuesService.recommendationCardsEvents.vpn.s8;
                } else if (!this.hasMobileVpnProtection()) {
                    vpnCard = this.dashboardValuesService.recommendationScenarios.vpn.end.installMobileVpn;
                    vpnCard.scenario = this.dashboardValuesService.recommendationCardsEvents.vpn.s9;
                }
            }
        }

        if (!dashboard['recommendations']) {
            dashboard['recommendations'] = {};
        }

        if (!dashboard['recommendations']['interface']) {
            dashboard['recommendations']['interface'] = {};
        }

        if (!dashboard['recommendations']['interface']['thirdCard']) {
            dashboard['recommendations']['interface']['thirdCard'] = {};
        }

        dashboard['recommendations']['interface']['thirdCard'] = vpnCard;
    }

}
