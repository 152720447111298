import { SubscriptionTrialResponse } from '../../common/services/process/subscriptions/subscriptionTrial.service';


export enum SuperiorTrialKeys {
    TRIAL = 'trial',
    BETTER_TRIAL = 'betterTrial'
};

export type SuperiorTrialResponse = {
    [key in SuperiorTrialKeys]: SubscriptionTrialResponse
};