// Internal
import { BundleModel, RedeemDataModel, SharedSubscriptionInvite } from '../../../../common/models/Services.model';
import { ModalName } from '../../../components/ui/ui-modal-routeless/modal.routeless.service';

export class ModalProperties {
    /**
     * Is modal activated
     * @type {boolean}
     */
    activated: boolean;
    /**
     * Which modal is activated
     * @type {string}
     */
    modalActivated: ModalName;
    /**
     * Is modal in the process of opening
     * @type {boolean}
     */
    modalOpening: boolean;
    activeStepInActiveModal: any;

    /**
     * Is modal in the process of animating
     * @type {boolean}
     */
    isAnimating: boolean;
}

export class ModalContainerOptions {
    /**
     * Modal dialog size
     * @type {ModalSize}
     * @defaultValue ModalSize.MD
    */
    size?: ModalSize = ModalSize.MD;
    /**
     * Modal dissmisable by backdrop-click
     * @type {boolean}
     * @defaultValue true
    */
    backdropDismissable?: boolean = true;
    /**
     * Modal's default close button shown
     * @type {boolean}
     * @defaultValue true
    */
    buttonDismissable?: boolean = true;
    /**
     * Extra Class to be added on modal dialog, usualy when custom styling is needed
     * @type {string}
    */
    exceptionClass?: string;
    /**
     * Flag to mark modal as par of a flow. If true - all other defined containerOptions except for reverseAnimation are ignored, they get
     * inherited from the previous modal. Dialog animation becomes boxed as part of the same modal.
     * @type {boolean}
     * @defaultValue false
    */
    isFlow?: boolean = false;
    /**
     * Flag to change direction of animation (dialog's movement). usualy needed in case of flows, or back buttons
     * @type {boolean}
     * @defaultValue false
    */
    reverseAnimation?: boolean = false;
}

export interface ModalContentOptions extends ModalContentOptionsError {
    redeemResponse?: RedeemDataModel,
    redeemCode?: string,
    scenario?: string,
    alreadyActive?: boolean,
    trial?: boolean,
    profile_id?: string,
    errorObject?: any,
    invite?: boolean,
    currentStep?: string,
    bundleId?: string,
    contextId?: string,
    cidCode?: string,
    paid?: boolean,
    protection?: boolean,
    referralResponse?: any,
    case?: string,
    bundle_id?: string,
    service_id?: string,
    discountPercentage?: number,
    bundleName?: string,
    flow?: string;
    isOnboardingFlow?: boolean,
    newBundle?: BundleModel,
    skipRedirectAtClose?: boolean,
    campaign?: string,
    serviceId?: string,
    bundle?: BundleModel,
    subScenario?: string,
    memberId?: string,
    role?: string,
    inviteInfo?: SharedSubscriptionInvite
    type?: string,
    availableInvites?: number,
    availableProfiles?: number,
    paymentType?: string
}

export interface ModalContentOptionsError {
    inviteId?: string,
    groupId?: string,
    trialId?: string
}

export enum ModalSize {
    /** width: 440px */
    SM = 'sm',
    /** width: 600px */
    MD = 'md',
    /** width: 790px */
    LG = 'lg',
    /** width: 880px */
    XL = 'xl',
    /** width: 960px */
    XXL = 'xxl',
    /** width: auto.
     * In this case the modal adjusts to it's content's size, width and responsivity needs to be controlled from the loaded component's css.
    */
    AUTO = 'auto'
}

export interface AllModalInformation {
    name: ModalName,
    containerOptions: ModalContainerOptions,
    contentOptions: ModalContentOptions
}
