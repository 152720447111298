import { ImageDescription } from '../../values/values.service';

export enum AtoErrorCodes {
    VALIDATION_ERROR1 = 32001,
    VALIDATION_ERROR2 = 32002,
    DATABASE_ERROR = 32701,
    COMMUNICATION_ERROR = 32801,
    PROCESSING_ERROR = 39001,
    ALREADY_HAS_IDENTITY = 40011,
    INVALID_SERVICE_PROVIDER = 40012,
    IDENTITY_NOT_FOUND = 40013,
    SERVICE_NOT_FOUND = 40014,
    SERVICE_ALREADY_LINKED = 40015,
    SERVICE_EXPIRED = 40016,
    SERVICE_AUTH_NOT_STARTED = 40401,
    INVALID_SERVICE_PROVIDER_ACCOUNT = 40402,
    INVALID_SERVICE_SCOPES = 40403,
    NO_SUBSCRIPTIONS = 40017,
    TOKEN_NOT_FOUND = 40111,
    EVENT_NOT_FOUND = 40201,
    INVALID_EVENT_GROUP = 40202,
    PRECONDITION_FAILED = 40290,
    NO_SERVICE_PROVIDER_ACCOUNT_FOUND = 40404,
    DEFAULT = 104
};

export interface ErrorInterface {
    title: string;
    desc: string;
}

export type ErrorCodes2TextsMapping = {
    [key in AtoErrorCodes]?: ErrorInterface
};

export enum AtoProviders {
    YOUTUBE = 'youtube'
}

export enum AtoServiceStatus {
    REVOKED = 'revoked',
    INIT = 'init'
}

export interface AtoServiceInfoModel {
    avatar_binary_data: string;
    avatar_url: string;
    avatar_display_url: string;
    banner_url: string;
    banner_binary_data: string;
    banner_display_url: string;
    channel_creation_date: Date;
    channel_description: string;
    channel_handle: string;
    channel_id: string
    channel_name: string;
    last_three_videos_data?: ServiceVideoDataModel[];
    posting_frequency: number;
    provider: AtoProviders
    subscriber_count: string;
    video_count: string;
    playlist_count?: number;
    view_count: string;
    initial_scan_date: Date;
    last_scan_date: Date;
}

export interface AtoServiceModel {
    service_id: string,
    provider: AtoProviders,
    service_info: AtoServiceInfoModel,
    linked: boolean;
    status: AtoServiceStatus;
}

export interface ServiceVideoDataModel {
    description: string;
    maxres_url: string;
    publishedAt: Date;
    standard_quality_binary: string;
    title: string;
    video_id: string;
    default_url: string;
    high_url: string;
    medium_url: string;
    standard_quality_binary_url: string;
    standard_url: string;
    display_url: string;
}